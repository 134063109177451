import { isNil, toNumber } from 'lodash-es';
import { DateTime } from 'luxon';
import { Transform } from 'class-transformer';
import { ShareClass } from '@startuptools/common/transactions';
import type { McWithIncludes } from './meeting-concern.model';
import type { SnrCase } from './snr-case.model';
import { Identity } from './identity.model';
import { SibmWithIncludes } from './share-issue-board-member.model';
import { SipWithIncludes } from './share-issue-participant.model';
import { GqlCurrency, GqlDecisionKind, GqlShareIssue, GqlShareIssuePurpose } from '../graphql/operations';
import { GqlBoardsStatementOption } from '../graphql/base-types.graphql';

export interface SiWithIncludes {
  model: ShareIssue;
  participants?: SipWithIncludes[];
  boardMembers?: SibmWithIncludes[];
  signatory?: Identity;
  contact?: Identity;
  mc?: McWithIncludes;
  snrCase?: SnrCase;
}

export type ShareIssueId = string;

export class ShareIssue
  implements
    Omit<
      GqlShareIssue,
      'shareIssueRegistrations' | 'boardMembers' | 'company' | 'shareIssueDocuments' | 'paymentCount'
    >
{
  id: ShareIssueId;
  description: string;
  pricePerShare?: string;
  currency: GqlCurrency;
  purpose: GqlShareIssuePurpose;
  minNumberOfShares?: string;
  maxNumberOfShares?: string;
  numberOfSharesBefore?: string;
  quotaValue?: string;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  subscriptionStartDate?: DateTime;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  subscriptionEndDate?: DateTime;
  published?: boolean;
  restrictedToParticipants?: boolean;
  paymentKindCash: boolean;
  paymentKindOffset: boolean;
  paymentKindNonCash: boolean;
  primaryLang: boolean;
  secondaryLang: boolean;
  approvedByMeeting?: boolean;
  preferentialRights: boolean;
  boardsStatementOption?: GqlBoardsStatementOption;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  boardsStatementDate?: DateTime;
  boardsStatementSv?: string;
  boardsStatementEn?: string;
  nonCashStatementPl?: string;
  nonCashStatementSl?: string;
  companyId: string;
  contactId?: string;
  signatoryId?: string;
  snrCaseId?: string;
  meetingId?: string;
  shareCapitalChangeId?: string;
  snrAuthorizationId?: string;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  paidForAt?: DateTime;
  stripeInvoiceId?: string;
  shareClasses: ShareClass[];
  decisionKind: GqlDecisionKind;

  get valuationPreMoney() {
    return toNumber(this.pricePerShare || 0) * toNumber(this.numberOfSharesBefore || 0);
  }

  get valuationPostMoney() {
    return (
      toNumber(this.pricePerShare || 0) *
      (toNumber(this.numberOfSharesBefore || 0) + toNumber(this.maxNumberOfShares || 0))
    );
  }

  @Transform(({ value }: { value: string }) => DateTime.fromISO(value), {
    toClassOnly: true,
  })
  readonly createdAt: DateTime;

  @Transform(({ value }: { value: string }) => DateTime.fromISO(value), {
    toClassOnly: true,
  })
  readonly updatedAt: DateTime;

  constructor(seed?: Partial<ShareIssue>) {
    Object.assign(this, seed);
  }
}
