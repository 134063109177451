<app-react-wrapper
  [component]="SideNav"
  [i18n]="i18n.i18n"
  [companyId]="(company$ | async)?.id"
  [props]="{
    navigate,
    angularPathname: angularPathname$ | async,
    isAntlerCompany: isAntlerCompany$ | async,
    shareRegisterNotUpdated: shareRegisterNotUpdated$ | async,
    user: (user$ | async),
    showDeleteCompanyDialog,
  }"
/>
