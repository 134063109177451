import React from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { Box, Button, Typography, Stack } from '@mui/joy';
import { Trans } from '@lingui/react';

export const FileUploadInput = ({
  onChange,
  invalid,
  accept,
}: {
  onChange: (file: File) => void;
  invalid: boolean;
  accept?: Accept;
}) => {
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      const [file] = acceptedFiles;
      if (file) {
        onChange?.(file);
      }
    },
    [onChange],
  );

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections } = useDropzone({
    onDrop,
    accept,
  });

  const error = invalid ?? fileRejections.length > 0;

  const uploadedFile = acceptedFiles[0];

  return (
    <Box
      {...getRootProps()}
      p={2}
      border={2}
      borderRadius={1}
      borderColor={isDragActive ? 'primary.400' : error ? 'warning.400' : 'neutral.outlinedBorder'}
      bgcolor="background.level1"
      minHeight={100}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        borderStyle: 'dashed',
        cursor: 'pointer',
        transition: 'border .3s ease-in-out',
      }}
    >
      <input {...getInputProps()} />
      {uploadedFile ? (
        <Box>
          <Typography level="body-md">
            {uploadedFile.name} - {Math.floor(uploadedFile.size / 1024)} kb
          </Typography>
        </Box>
      ) : (
        <Stack spacing={2} alignItems="center">
          <Typography level="body-md">
            {isDragActive ? <Trans id="Right here!" /> : <Trans id="Drag and drop a file here." />}
          </Typography>
          <Box>
            <Button>
              <Trans id="Or Click Here" />
            </Button>
          </Box>
          {accept && (
            <Typography
              level="body-sm"
              fontWeight={fileRejections.length > 0 ? 'bold' : 'normal'}
              color={fileRejections.length > 0 ? 'warning' : 'neutral'}
            >
              <Trans
                id="Allowed file types: {extensions}"
                values={{
                  extensions: Object.values(accept)
                    .flat()
                    .map(ext => `*${ext}`)
                    .join(', '),
                }}
              />
            </Typography>
          )}
        </Stack>
      )}
    </Box>
  );
};
