import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';
import { isNil } from 'lodash-es';
import { ShareClass } from '@startuptools/common/transactions';
import { AutoFields } from '.';
import { jsonParse, jsonStringify } from './utils';
import { ShWithIncludes } from './shareholder.model';
import { GqlShareTransaction } from '../graphql/operations';
import { GqlStRounded } from '../graphql/react-operations';

export interface StWithIncludes {
  model: ShareTransaction;
  buyer: ShWithIncludes | undefined;
  seller: ShWithIncludes | undefined;
}

export class ShareTransaction
  implements Omit<GqlShareTransaction, 'shareTransactionDocuments' | 'shareTransactionGroup'>
{
  id: string;

  name?: string;
  blockStart: string;
  blockEnd: string;
  pricePerShare?: string;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  shareCertificateDate?: DateTime;
  buyerId?: string;
  sellerId?: string;
  comment?: string;
  rounded: GqlStRounded;

  shareTransactionGroupId: string;
  @Transform(({ value }: { value: string }) => jsonParse(value), {
    toClassOnly: true,
  })
  @Transform(({ value }) => jsonStringify(value), { toPlainOnly: true })
  shareClassFrom?: ShareClass;

  @Transform(({ value }: { value: string }) => jsonParse(value), {
    toClassOnly: true,
  })
  @Transform(({ value }) => jsonStringify(value), { toPlainOnly: true })
  shareClassTo?: ShareClass;

  snrShareCapitalChangeId?: string;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  readonly createdAt: DateTime;
  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  readonly updatedAt: DateTime;

  constructor(seed: Omit<ShareTransaction, AutoFields>) {
    Object.assign(this, seed);
  }
}
