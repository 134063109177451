import { GqlDocSystemTagKind, GqlDocumentSignatureMethod } from '../graphql/base-types.graphql';

export const FOOTER_SV_STARTUPTOOLS = 'partials/footer_sv_startuptools.html';
export const FOOTER_ENG_STARTUPTOOLS = 'partials/footer_eng_startuptools.html';
export const FOOTER_BILINGUAL_STARTUPTOOLS = 'partials/footer_bilingual_startuptools.html';

// NEW

export interface DocGenInput {
  signatures?: DocContextSignature[];
  primary_lang?: boolean;
  secondary_lang?: boolean;
}

export interface DocGenDetails<T extends DocGenInput = DocGenInput> {
  templateId: string;
  headerPath?: string;
  footerPath?: string;
  filename?: string;
  signatureParties?: DocGenSignatureParty2[];
  inputData: T;
}

export interface DocGen3<T extends DocGenInput = DocGenInput> {
  signatureParties?: DocGenSignatureParty2[];
  documentId?: string;
  docGenDetails?: DocGenDetails<T>;
}

export interface DocGenReq<T extends DocGenInput = DocGenInput> {
  filename: string;
  companyId: string;
  lockdown: boolean;
  docGens: DocGen3<T>[];
}

export interface DocGenSchedReq<T extends DocGenInput = DocGenInput> extends DocGenReq<T> {
  filename: string;
  companyId: string;
  kind: GqlDocSystemTagKind;
  userTags?: string[];
  relation?: DocGenRelation2;
  signature: DocGenSignature2;
}

export enum DocGenRelationName {
  MeetingId = 'meeting_id',
  ShareIssueId = 'share_issue_id',
  ArticlesOfAssocId = 'articles_of_assoc_id',
  WarrantProgramId = 'warrant_program_id',
  warrantParticipantId = 'warrant_participant_id',
  AuthorizationGroupId = 'authorization_group_id',
  ShareTransactionId = 'share_transaction_id',
}

// Relation is used for /gen/generate
export interface DocGenRelation2 {
  relation_id: string;
  relation_name: DocGenRelationName;
}

export interface DocGenSignatureParty2 {
  signatory_id: string;
  proxy_id: string | null;
  header?: { primary_lang: string; secondary_lang: string };
  auth_method_to_view: string;
  auth_method_to_sign: string;
  delivery_method: string;
  email: string;
}

export interface DocGenSignature2 {
  method: GqlDocumentSignatureMethod;
  parties: DocGenSignatureParty2[];
}

export interface DocGenDetails2<T> {
  template_id: string;
  header_path?: string;
  footer_path?: string;
  signature_parties?: DocGenSignatureParty2[];
  input_data: T;
}

export interface DocContextSignature {
  header_primary_lang?: string;
  header_secondary_lang?: string;
  name: string;
  show_date_field?: boolean;
}
