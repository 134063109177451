import React from 'react';
import { useLingui, Trans } from '@lingui/react';
import {
  Card,
  Stack,
  Typography,
  Sheet,
  DownloadIcon,
  Button,
  Link,
  LabeledText,
  FingerprintIcon,
  AlarmIcon,
  DateFormat,
  Box,
  IconButton,
} from '@startuptools/ui';
import { DateTime } from 'luxon';
import { GqlDocument } from '../../graphql/base-types.graphql';
import { ArrowBack, Preview } from '@mui/icons-material';
import { PDFModal } from './PDFModal';

export const DocumentDialog = ({
  moreDetails,
  children,
  document,
}: {
  moreDetails?: React.ReactNode;
  children: React.ReactNode;
  document: Pick<GqlDocument, 'id' | 'filename' | 'createdAt'>;
}) => {
  const { i18n } = useLingui();
  const [showPreview, setShowPreview] = React.useState(false);

  if (showPreview) {
    return (
      <Stack sx={{ overflowY: 'scroll' }} gap={2}>
        <Box>
          <Button
            variant="outlined"
            startDecorator={<ArrowBack />}
            onClick={() => {
              setShowPreview(false);
            }}
          >
            <Trans id="Back" />
          </Button>
        </Box>
        <PDFModal
          src={`/documents/file/${document.id}?method=open`}
          fileName={document.id}
          minHeight="80vh"
          minWidth={1000}
          showDownloadButton={false}
        />
      </Stack>
    );
  }

  return (
    <Stack sx={{ width: '100%' }}>
      <Typography margin={2} level="h3">
        {document.filename}
      </Typography>
      <Stack gap={3} margin={2}>
        <Stack direction="row" gap={4}>
          <Sheet variant="outlined">
            <IconButton
              onClick={() => {
                setShowPreview(true);
              }}
            >
              <img width="172px" height="243px" src={`/documents/thumbnail/${document.id}`} alt="thumbnail" />
            </IconButton>
          </Sheet>
          <Card sx={{ width: '100%' }}>
            <Stack gap={2}>
              <Stack direction="row" gap={2}>
                <Button startDecorator={<Preview />} onClick={() => setShowPreview(true)}>
                  <Trans id="View" />
                </Button>
                <Link underline="none" href={`/documents/file/${document.id}`}>
                  <Button startDecorator={<DownloadIcon />} variant="outlined">
                    <Trans id="Download" />
                  </Button>
                </Link>
              </Stack>
              <Stack direction="row" gap={2} alignItems="center">
                <AlarmIcon />
                <LabeledText label={<Trans id="Created At" />}>
                  <DateFormat locale={i18n.locale} format={DateTime.DATETIME_SHORT_WITH_SECONDS}>
                    {document.createdAt}
                  </DateFormat>
                </LabeledText>
              </Stack>
              <Stack direction="row" gap={2} alignItems="center">
                <FingerprintIcon />
                <LabeledText label={<Trans id="File ID" />}>{document.id}</LabeledText>
              </Stack>
              {moreDetails}
            </Stack>
          </Card>
        </Stack>
        {children}
      </Stack>
    </Stack>
  );
};
