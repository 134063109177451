import { createAction, props, union } from '@ngrx/store';

import { ShareTransactionGroup } from '../../models/share-transaction-group.model';

const prefix = '[ShareTransactionGroup]' as const;

export const shareTransactionGroupsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: ShareTransactionGroup[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
} as const;

const all = union(shareTransactionGroupsActions);

export type ActionsUnion = typeof all;
