/* eslint-disable */
import {
  Component,
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewEncapsulation,
  SimpleChanges,
  ChangeDetectorRef,
  Inject,
  Attribute,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  CanDisable,
  CanUpdateErrorState,
  ErrorStateMatcher,
  HasTabIndex,
  mixinDisabled,
  mixinDisableRipple,
  mixinErrorState,
  mixinTabIndex,
} from '@angular/material/core';
import { BooleanInput, coerceBooleanProperty, NumberInput } from '@angular/cdk/coercion';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { isNil } from 'lodash-es';

import { ShWithIncludes } from '../../models/shareholder.model';
import { Identity, isSwedish, JuridicalKind } from '../../models/identity.model';
import { formatIdNumber } from '../../pipes/id-number.pipe';
import { Gravatar } from '@startuptools/ui';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/reducers';

export interface ObjectInputForm {
  readonly name: string;
  readonly sub: string;
  readonly gravatarEmail: string | null;
  readonly gravatarHash: string | null;
}

export class ShareholderObjectInputAdapter implements ObjectInputForm {
  constructor(public sh: ShWithIncludes) {}

  get name() {
    return this.sh.identity.name;
  }

  // @ts-expect-error TS2416
  get sub() {
    if (isSwedish(this.sh.identity)) {
      return formatIdNumber(this.sh.identity.identityNumber);
    }
    return this.sh.identity.email;
  }

  get gravatarEmail() {
    return this.sh.identity.juridicalKind === JuridicalKind.Person ? this.sh.identity.email || '' : null;
  }

  get gravatarHash() {
    return this.sh.identity.juridicalKind === JuridicalKind.Person ? this.sh.identity.emailMd5 || '' : null;
  }

  get companyId() {
    return this.sh.identity.companyId;
  }

  get uuid() {
    return this.sh.identity.id;
  }

  get firstLetter() {
    return this.sh.identity.name?.at(0) || ' ';
  }
}

export class IdentityObjectInputAdapter implements ObjectInputForm {
  constructor(public identity: Identity) {}

  get name() {
    return this.identity.name;
  }

  // @ts-expect-error TS2416
  get sub() {
    if (isSwedish(this.identity)) {
      return formatIdNumber(this.identity.identityNumber);
    }
    return this.identity.email;
  }

  get gravatarEmail() {
    return this.identity.juridicalKind === JuridicalKind.Person ? this.identity.email || '' : null;
  }

  get gravatarHash() {
    return this.identity.juridicalKind === JuridicalKind.Person ? this.identity.emailMd5 || '' : null;
  }
}

export const _AppObjectMixinBase = mixinDisableRipple(
  mixinTabIndex(
    mixinDisabled(
      mixinErrorState(
        class {
          readonly stateChanges = new Subject<void>();
          constructor(
            public _elementRef: ElementRef,
            public _defaultErrorStateMatcher: ErrorStateMatcher,
            public _parentForm: NgForm,
            public _parentFormGroup: FormGroupDirective,
            public ngControl: NgControl,
          ) {}
        },
      ),
    ),
  ),
);

let nextUniqueId = 0;

@Directive()
export abstract class _AppObjectInputBase
  extends _AppObjectMixinBase
  implements
    OnDestroy,
    OnInit,
    DoCheck,
    ControlValueAccessor,
    MatFormFieldControl<any>,
    CanDisable,
    CanUpdateErrorState,
    CanUpdateErrorState,
    HasTabIndex
{
  private _uid = `app-object-input-${nextUniqueId++}`;

  protected readonly _destroy = new Subject<void>();

  _ariaDescribedby: string;

  _onChange: (value: any) => void = () => null;
  _onTouched = (): void => {};
  _valueId = `app-object-input-value-${nextUniqueId++}`;

  get focused(): boolean {
    return this._focused;
  }
  private _focused = false;

  controlType = 'app-object-input';

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  /** Whether the component is required. */
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    if (newValue !== this._value) {
      this._value = newValue;
      this._changeDetectorRef.markForCheck();
      this.stateChanges.next();
    }
  }
  private _value: any;

  @Input() errorStateMatcher: ErrorStateMatcher;

  @Output() readonly valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value || this._uid;
    this.stateChanges.next();
  }
  private _id: string;

  constructor(
    protected _changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef,
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective,
    @Optional()
    @Inject(MAT_FORM_FIELD)
    protected _parentFormField: MatFormField,
    @Self() @Optional() public ngControl: NgControl,
    @Attribute('tabindex') tabIndex: string,
  ) {
    super(elementRef, _defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    this.tabIndex = parseInt(tabIndex) || 0;

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.id = this.id;
  }

  ngOnInit() {
    this.stateChanges.next();
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      this.stateChanges.next();
    }
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this.stateChanges.complete();
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._changeDetectorRef.markForCheck();
    this.stateChanges.next();
  }

  focus(options?: FocusOptions): void {
    this._elementRef.nativeElement.focus(options);
  }

  _onFocus() {
    if (!this.disabled) {
      this._focused = true;
      this.stateChanges.next();
    }
  }

  _onBlur() {
    this._focused = false;

    if (!this.disabled) {
      this._onTouched();
      this._changeDetectorRef.markForCheck();
      this.stateChanges.next();
    }
  }

  get empty(): boolean {
    return !this.value;
  }

  setDescribedByIds(ids: string[]) {
    this._ariaDescribedby = ids.join(' ');
  }

  onContainerClick() {
    this.focus();
  }

  get shouldLabelFloat(): boolean {
    return !this.empty;
  }

  static ngAcceptInputType_required: BooleanInput;
  static ngAcceptInputType_disabled: BooleanInput;
  static ngAcceptInputType_tabIndex: NumberInput;
}

@Component({
  selector: 'app-object-input',
  exportAs: 'appObjectInput',
  templateUrl: './object-input.component.html',
  styleUrls: ['./object-input.component.scss'],
  inputs: ['disabled', 'tabIndex'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // { provide: NG_VALUE_ACCESSOR, useExisting: ObjectInputComponent },
    { provide: MatFormFieldControl, useExisting: ObjectInputComponent },
  ],
  host: {
    '[id]': 'id',
    '[attr.tabindex]': 'tabIndex',
    '[attr.aria-required]': 'required.toString()',
    '[attr.aria-disabled]': 'disabled.toString()',
    '[attr.aria-invalid]': 'errorState',
    '[attr.aria-describedby]': 'describedBy',
    '[class.app-object-input-disabled]': 'disabled',
    '[class.app-object-input-invalid]': 'errorState',
    '[class.app-object-input-required]': 'required',
    '(focus)': '_onFocus()',
    '(blur)': '_onBlur()',
  },
})
export class ObjectInputComponent extends _AppObjectInputBase {
  Gravatar = Gravatar;
  isNil = isNil;
}
