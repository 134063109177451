import { createAction, props, union } from '@ngrx/store';

import { ShareTransaction } from '../../models/share-transaction.model';

const prefix = '[ShareTransaction]' as const;

export const shareTransactionsActions = {
  manyLoaded: createAction(prefix + ' manyLoaded', props<{ payload: ShareTransaction[] }>()),
  manyDeleted: createAction(prefix + ' manyDeleted', props<{ payload: string[] }>()),
};

const all = union(shareTransactionsActions);

export type ActionsUnion = typeof all;
