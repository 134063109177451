export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format.
   *   Its input on the server can be a Date or a Luxon DateTime.
   *
   */
  Date: { input: string; output: string; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /** Represents NULL values */
  Void: { input: void; output: void; }
}

export interface GqlASearchCompaniesInput {
  filter: Scalars['String']['input'];
  pageLimit: Scalars['Int']['input'];
  pageOffset: Scalars['Int']['input'];
}

export interface GqlASearchScriveDocumentsInput {
  filter: Scalars['String']['input'];
  pageLimit: Scalars['Int']['input'];
  pageOffset: Scalars['Int']['input'];
}

export interface GqlASearchShareholdersInput {
  filter: Scalars['String']['input'];
  pageLimit: Scalars['Int']['input'];
  pageOffset: Scalars['Int']['input'];
}

export interface GqlASearchUsersInput {
  filter: Scalars['String']['input'];
  pageLimit: Scalars['Int']['input'];
  pageOffset: Scalars['Int']['input'];
}

/** AuthorizationGroup Connect meeting */
export interface GqlAgConnectMeetingInput {
  id: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}

export interface GqlAgConnectMeetingResponse {
  aiSetDoneResponse: GqlAiSetDoneResponse;
  authorizationGroup: GqlAuthorizationGroup;
}

/** AuthorizationGroup Create and connect meeting */
export interface GqlAgCreateMeetingInput {
  id: Scalars['ID']['input'];
  meetingCreate: GqlMeetingCreateInput;
}

export interface GqlAgDeletedResponse {
  aiDeletedResponses?: Maybe<Array<GqlAiDeletedResponse>>;
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  id: Scalars['ID']['output'];
  mcDeletedResponse?: Maybe<GqlMcDeletedResponse>;
}

/** AuthorizationGroup Disconnect meeting */
export interface GqlAgDisconnectMeetingInput {
  id: Scalars['ID']['input'];
}

export interface GqlAgDisconnectMeetingResponse {
  aiDeletedResponses?: Maybe<Array<GqlAiDeletedResponse>>;
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  authorizationGroup: GqlAuthorizationGroup;
  mcDeletedResponse?: Maybe<GqlMcDeletedResponse>;
}

export interface GqlAgSetNoBoardStatementInput {
  id: Scalars['ID']['input'];
  noBoardStatement: Scalars['Boolean']['input'];
}

export interface GqlAgSetSnrAuthIdInput {
  id: Scalars['ID']['input'];
  snrAuthorizationId?: InputMaybe<Scalars['ID']['input']>;
}

export interface GqlAgSetSnrCaseIdInput {
  id: Scalars['ID']['input'];
  snrCaseId?: InputMaybe<Scalars['ID']['input']>;
}

/** AuthorizationGroup Set validThru */
export interface GqlAgSetValidThruInput {
  id: Scalars['ID']['input'];
  validThruAgmYear?: InputMaybe<Scalars['String']['input']>;
  validThruDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface GqlAgUpdateInput {
  contact?: InputMaybe<GqlCreateIdentityInput>;
  id: Scalars['ID']['input'];
  primaryLang?: InputMaybe<Scalars['Boolean']['input']>;
  regFormPaymentThroughBolago?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryLang?: InputMaybe<Scalars['Boolean']['input']>;
  signatory?: InputMaybe<GqlCreateIdentityInput>;
}

export interface GqlAgValidatorErrors {
  auths: GqlValidatorStatus;
  availability: GqlValidatorStatus;
  boardStatement: GqlValidatorStatus;
  decision: GqlValidatorStatus;
  language: GqlValidatorStatus;
  signsRegForm: GqlValidatorStatus;
}

export interface GqlAgendaItem {
  agendaItemDecisions: Array<GqlAgendaItemDecision>;
  commentPrimaryLang?: Maybe<Scalars['String']['output']>;
  commentSecondaryLang?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  decisionSuggestions?: Maybe<Scalars['JSON']['output']>;
  done: Scalars['Boolean']['output'];
  firstGroup: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['Int']['output'];
  lastGroup: Scalars['Boolean']['output'];
  mandatory: Scalars['Boolean']['output'];
  meeting: GqlMeeting;
  meetingId: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  subjectPrimaryLang: Scalars['String']['output'];
  subjectSecondaryLang?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
}

export interface GqlAgendaItemDecision {
  agendaItem: GqlAgendaItem;
  agendaItemId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  decisionPrimaryLang?: Maybe<Scalars['String']['output']>;
  decisionSecondaryLang?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
}

export enum GqlAgreementStatus {
  Failed = 'FAILED',
  NotSent = 'NOT_SENT',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export interface GqlAiCreateInput {
  agendaItemsDecisionsCreate?: InputMaybe<Array<GqlAidCreateWithoutAiInput>>;
  commentPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  commentSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  decisionSuggestions?: InputMaybe<Scalars['JSON']['input']>;
  kind: Scalars['Int']['input'];
  mandatory?: Scalars['Boolean']['input'];
  meetingId: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  subjectPrimaryLang: Scalars['String']['input'];
  subjectSecondaryLang?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlAiDeletedResponse {
  id: Scalars['ID']['output'];
  updatedAis: Array<GqlAgendaItem>;
}

/** Sets done and comments */
export interface GqlAiSetDoneInput {
  commentPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  commentSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  summonsDirty: Scalars['Boolean']['input'];
}

export interface GqlAiSetDoneResponse {
  agendaItem: GqlAgendaItem;
  deletedAidIds?: Maybe<Array<GqlAidDeletedResponse>>;
}

/** Sets done, sets comments and replaces all decisions on an agenda item */
export interface GqlAiSetDoneWithApprovableMeetingsInput {
  commentPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  commentSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  meetingsApprovedInput: Array<GqlMeetingSetApprovedInput>;
  summonsDirty: Scalars['Boolean']['input'];
}

/** Sets done, sets comments and replaces all decisions on an agenda item */
export interface GqlAiSetDoneWithMcsInput {
  commentPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  commentSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  mcsApprovedInput: Array<GqlMcsSetApprovedInput>;
  summonsDirty: Scalars['Boolean']['input'];
}

/** Sets done, sets comments and replaces all decisions on an agenda item */
export interface GqlAiSetDoneWithNextMeetingsInput {
  commentPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  commentSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  scheduledMeetingIds: Array<Scalars['String']['input']>;
  summonsDirty: Scalars['Boolean']['input'];
}

export interface GqlAiSetOrderInput {
  id: Scalars['ID']['input'];
  order: Scalars['Int']['input'];
}

export interface GqlAiWithMcsResponse {
  aiSetDoneResponse: GqlAiSetDoneResponse;
  meetingConcerns: Array<GqlMeetingConcern>;
}

export interface GqlAiWithMeetingsResponse {
  aiSetDoneResponse: GqlAiSetDoneResponse;
  meetings: Array<GqlMeeting>;
}

export interface GqlAidCreateInput {
  agendaItemId: Scalars['ID']['input'];
  decisionPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  decisionSecondaryLang?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlAidCreateWithoutAiInput {
  decisionPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  decisionSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
}

export interface GqlAidDeletedResponse {
  agendaItem?: Maybe<GqlAgendaItem>;
  id: Scalars['ID']['output'];
}

export interface GqlAidUpdateInput {
  decisionPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  decisionSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Connect meeting */
export interface GqlAoaConnectMeetingInput {
  id: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}

export interface GqlAoaCreateInput {
  copyFromAoaId?: InputMaybe<Scalars['ID']['input']>;
  meetingId?: InputMaybe<Scalars['ID']['input']>;
}

/** Create and connect meeting */
export interface GqlAoaCreateMeetingInput {
  id: Scalars['ID']['input'];
  meetingCreate: GqlMeetingCreateInput;
}

export interface GqlAoaDeletedResponse {
  agendaItems?: Maybe<Array<GqlAgendaItem>>;
  aiDeletedResponses?: Maybe<Array<GqlAiDeletedResponse>>;
  id: Scalars['ID']['output'];
  mcDeletedResponse?: Maybe<GqlMcDeletedResponse>;
}

/** Disconnect meeting */
export interface GqlAoaDisconnectMeetingInput {
  id: Scalars['ID']['input'];
}

export interface GqlAoaDisconnectMeetingResponse {
  agendaItems?: Maybe<Array<GqlAgendaItem>>;
  aiDeletedResponses?: Maybe<Array<GqlAiDeletedResponse>>;
  articlesOfAssoc: GqlArticlesOfAssoc;
  mcDeletedResponse?: Maybe<GqlMcDeletedResponse>;
}

/** gets affected concerns connected to the same meeting */
export interface GqlAoaGetAffectedResponse {
  authorizationGroups: Array<GqlAuthorizationGroup>;
  shareIssues: Array<GqlShareIssue>;
  warrantPrograms: Array<GqlWarrantProgram>;
}

export interface GqlAoaSetArbitratorInput {
  arbitratorPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  haveArbitrator: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlAoaSetAuditorsLimitInput {
  id: Scalars['ID']['input'];
  maximumNumberOfAuditors: Scalars['String']['input'];
  maximumNumberOfSubstituteAuditors: Scalars['String']['input'];
  minimumNumberOfAuditors: Scalars['String']['input'];
  minimumNumberOfSubstituteAuditors: Scalars['String']['input'];
}

export interface GqlAoaSetBusinessInput {
  id: Scalars['ID']['input'];
  kindOfBusiness: Scalars['String']['input'];
}

export interface GqlAoaSetConsentInput {
  consentPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  haveConsent: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlAoaSetMajorityInput {
  haveMajority: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  majorityPrimaryLang?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlAoaSetPostTransferPurchaseRightInput {
  havePostTransferPurchaseRight: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  postTransferPurchaseRightPrimaryLang?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlAoaSetRightOfFirstRefusalInput {
  haveRightOfFirstRefusal: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  rightOfFirstRefusalPrimaryLang?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlAoaSetShareChangeInput {
  id: Scalars['ID']['input'];
  numberOfSharesAfter: Scalars['String']['input'];
}

export interface GqlAoaSetShareLimitsInput {
  changeShareLimits: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  maximumNumberOfShares: Scalars['String']['input'];
  maximumShareCapital: Scalars['String']['input'];
  minimumNumberOfShares: Scalars['String']['input'];
  minimumShareCapital: Scalars['String']['input'];
}

export interface GqlAoaSetSummonsInput {
  digitalAgm: GqlDigitalAgm;
  id: Scalars['ID']['input'];
  summoningMethodEmail: Scalars['Boolean']['input'];
  summoningMethodFax: Scalars['Boolean']['input'];
  summoningMethodMail: Scalars['Boolean']['input'];
  summoningMethodNewspaper?: InputMaybe<Scalars['String']['input']>;
  summoningMethodOtherPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  summoningMethodPoit: Scalars['Boolean']['input'];
  summoningTimeMax: Scalars['String']['input'];
  summoningTimeMin: Scalars['String']['input'];
}

export interface GqlAoaUpdateInput {
  contact?: InputMaybe<GqlCreateIdentityInput>;
  id: Scalars['ID']['input'];
  regFormPaymentThroughBolago?: InputMaybe<Scalars['Boolean']['input']>;
  signatory?: InputMaybe<GqlCreateIdentityInput>;
}

export interface GqlAoaValidatorErrors {
  auditor: GqlValidatorStatus;
  board: GqlValidatorStatus;
  business: GqlValidatorStatus;
  companyName: GqlValidatorStatus;
  decision: GqlValidatorStatus;
  fiscalYear: GqlValidatorStatus;
  reservations: GqlValidatorStatus;
  seat: GqlValidatorStatus;
  shareChange: GqlValidatorStatus;
  shareLimit: GqlValidatorStatus;
  signsRegForm: GqlValidatorStatus;
  summons: GqlValidatorStatus;
}

export interface GqlArticlesOfAssoc {
  agendaItemsAgmHtml?: Maybe<Scalars['JSON']['output']>;
  arbitratorPrimaryLang?: Maybe<Scalars['String']['output']>;
  articlesOfAssocDocuments: Array<GqlArticlesOfAssocDocument>;
  changeAgendaItems?: Maybe<Scalars['Boolean']['output']>;
  changeArbitrator: Scalars['Boolean']['output'];
  changeBoardLimits: Scalars['Boolean']['output'];
  changeCompanyName: Scalars['Boolean']['output'];
  changeConsent: Scalars['Boolean']['output'];
  changeDigitalAgm: Scalars['Boolean']['output'];
  changeFiscalYear: Scalars['Boolean']['output'];
  changeKindOfBusiness: Scalars['Boolean']['output'];
  changeMajority: Scalars['Boolean']['output'];
  changeNumberOfAuditors: Scalars['Boolean']['output'];
  changeNumberOfShares: Scalars['Boolean']['output'];
  changePostTransferPurchaseRight: Scalars['Boolean']['output'];
  changeRightOfFirstRefusal: Scalars['Boolean']['output'];
  changeSeat: Scalars['Boolean']['output'];
  changeShareLimits: Scalars['Boolean']['output'];
  changeSummoning: Scalars['Boolean']['output'];
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  companyName: Scalars['String']['output'];
  consentPrimaryLang?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<GqlIdentity>;
  contactId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  digitalAgm: GqlDigitalAgm;
  fiscalYear: Scalars['String']['output'];
  haveArbitrator: Scalars['Boolean']['output'];
  haveConsent: Scalars['Boolean']['output'];
  haveMajority: Scalars['Boolean']['output'];
  havePostTransferPurchaseRight: Scalars['Boolean']['output'];
  haveRightOfFirstRefusal: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  kindOfBusiness: Scalars['String']['output'];
  majorityPrimaryLang?: Maybe<Scalars['String']['output']>;
  maximumNumberOfAuditors?: Maybe<Scalars['String']['output']>;
  maximumNumberOfBoardMembers?: Maybe<Scalars['String']['output']>;
  maximumNumberOfShares?: Maybe<Scalars['String']['output']>;
  maximumNumberOfSubstituteAuditors?: Maybe<Scalars['String']['output']>;
  maximumNumberOfSubstitutes?: Maybe<Scalars['String']['output']>;
  maximumShareCapital?: Maybe<Scalars['String']['output']>;
  meetingConcern?: Maybe<GqlMeetingConcern>;
  minimumNumberOfAuditors?: Maybe<Scalars['String']['output']>;
  minimumNumberOfBoardMembers?: Maybe<Scalars['String']['output']>;
  minimumNumberOfShares?: Maybe<Scalars['String']['output']>;
  minimumNumberOfSubstituteAuditors?: Maybe<Scalars['String']['output']>;
  minimumNumberOfSubstitutes?: Maybe<Scalars['String']['output']>;
  minimumShareCapital?: Maybe<Scalars['String']['output']>;
  newCompanyNames?: Maybe<Scalars['JSONObject']['output']>;
  numberOfSharesAfter?: Maybe<Scalars['String']['output']>;
  numberOfSharesBefore?: Maybe<Scalars['String']['output']>;
  postTransferPurchaseRightPrimaryLang?: Maybe<Scalars['String']['output']>;
  quotaAfter?: Maybe<Scalars['String']['output']>;
  quotaBefore?: Maybe<Scalars['String']['output']>;
  regFormPaymentThroughBolago: Scalars['Boolean']['output'];
  rightOfFirstRefusalPrimaryLang?: Maybe<Scalars['String']['output']>;
  seat: Scalars['String']['output'];
  shareCapitalBefore?: Maybe<Scalars['String']['output']>;
  signatory?: Maybe<GqlIdentity>;
  signatoryId?: Maybe<Scalars['ID']['output']>;
  snrCaseId?: Maybe<Scalars['ID']['output']>;
  summoningMethodEmail: Scalars['Boolean']['output'];
  summoningMethodFax: Scalars['Boolean']['output'];
  summoningMethodMail: Scalars['Boolean']['output'];
  summoningMethodNewspaper?: Maybe<Scalars['String']['output']>;
  summoningMethodOtherPrimaryLang?: Maybe<Scalars['String']['output']>;
  summoningMethodPoit: Scalars['Boolean']['output'];
  summoningTimeMax: Scalars['String']['output'];
  summoningTimeMin: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlArticlesOfAssocDocument {
  articlesOfAssoc: GqlArticlesOfAssoc;
  articlesOfAssocId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  document: GqlDocument;
  documentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
}

/** Create a authorization */
export interface GqlAuthCreateInput {
  allocation?: InputMaybe<Scalars['Int']['input']>;
  authorizationGroupId: Scalars['ID']['input'];
  cashPayment?: InputMaybe<Scalars['Boolean']['input']>;
  inKindPayment?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Int']['input']>;
  maxRaise?: InputMaybe<Scalars['String']['input']>;
  maxRaiseAmount?: InputMaybe<Scalars['String']['input']>;
  maxRaiseByAoa?: InputMaybe<Scalars['Boolean']['input']>;
  offsetPayment?: InputMaybe<Scalars['Boolean']['input']>;
  singleUse?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface GqlAuthCreateResponse {
  aiSetDoneResponse: GqlAiSetDoneResponse;
  authorization: GqlAuthorization;
}

export interface GqlAuthDeletedResponse {
  aiDeletedResponses?: Maybe<Array<GqlAiDeletedResponse>>;
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  id: Scalars['ID']['output'];
  mcDeletedResponse?: Maybe<GqlMcDeletedResponse>;
}

/** Update a authorization */
export interface GqlAuthUpdateInput {
  allocation?: InputMaybe<Scalars['Int']['input']>;
  authorizationGroupId: Scalars['ID']['input'];
  cashPayment?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  inKindPayment?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Int']['input']>;
  maxRaise?: InputMaybe<Scalars['String']['input']>;
  maxRaiseAmount?: InputMaybe<Scalars['String']['input']>;
  maxRaiseByAoa?: InputMaybe<Scalars['Boolean']['input']>;
  offsetPayment?: InputMaybe<Scalars['Boolean']['input']>;
  singleUse?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface GqlAuthorization {
  allocation: Scalars['Int']['output'];
  authorizationGroup: GqlAuthorizationGroup;
  authorizationGroupId: Scalars['String']['output'];
  cashPayment?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  inKindPayment?: Maybe<Scalars['Boolean']['output']>;
  kind: Scalars['Int']['output'];
  maxRaise?: Maybe<Scalars['String']['output']>;
  maxRaiseAmount?: Maybe<Scalars['String']['output']>;
  maxRaiseByAoa?: Maybe<Scalars['Boolean']['output']>;
  offsetPayment?: Maybe<Scalars['Boolean']['output']>;
  singleUse?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['Date']['output'];
}

export interface GqlAuthorizationGroup {
  authorizationGroupDocuments: Array<GqlAuthorizationGroupDocument>;
  authorizations: Array<GqlAuthorization>;
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  contact?: Maybe<GqlIdentity>;
  contactId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  meetingConcern?: Maybe<GqlMeetingConcern>;
  noBoardStatement: Scalars['Boolean']['output'];
  primaryLang: Scalars['Boolean']['output'];
  regFormPaymentThroughBolago: Scalars['Boolean']['output'];
  secondaryLang: Scalars['Boolean']['output'];
  signatory?: Maybe<GqlIdentity>;
  signatoryId?: Maybe<Scalars['ID']['output']>;
  snrAuthorizationId?: Maybe<Scalars['ID']['output']>;
  snrCaseId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['Date']['output'];
  validThruAgmYear?: Maybe<Scalars['String']['output']>;
  validThruDate?: Maybe<Scalars['Date']['output']>;
}

export interface GqlAuthorizationGroupDocument {
  authorizationGroup: GqlAuthorizationGroup;
  authorizationGroupId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  document: GqlDocument;
  documentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
}

export enum GqlBoardsStatementOption {
  None = 'None',
  Produce = 'Produce',
  ProducedExternally = 'ProducedExternally',
  WaivedByAuth = 'WaivedByAuth',
  Waiver = 'Waiver'
}

export interface GqlCapTable {
  capTableStakes: Array<GqlCapTableStake>;
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  currentShareCount: Scalars['String']['output'];
  dirty: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  pricePerShare?: Maybe<Scalars['String']['output']>;
  recordDate: Scalars['Date']['output'];
  stakeholders: Array<GqlCapTableStakeholder>;
  unregisteredShares?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
}

export interface GqlCapTableStake {
  acqCost?: Maybe<Scalars['String']['output']>;
  cap?: Maybe<Scalars['String']['output']>;
  capTable: GqlCapTable;
  capTableId: Scalars['ID']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  enabled: Scalars['Boolean']['output'];
  floor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId: Scalars['ID']['output'];
  investedAmount?: Maybe<Scalars['String']['output']>;
  kind: Scalars['Int']['output'];
  manualEntry: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  profit?: Maybe<Scalars['String']['output']>;
  stake: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  value?: Maybe<Scalars['String']['output']>;
  wiseQuantity?: Maybe<Scalars['String']['output']>;
}

export interface GqlCapTableStakeDeleteInput {
  id: Scalars['ID']['input'];
}

export interface GqlCapTableStakeSetEnabledInput {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlCapTableStakeUpdateInput {
  acqCost?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  kind?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stake?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlCapTableStakeholder {
  acqCost: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  profit?: Maybe<Scalars['String']['output']>;
  share: Scalars['String']['output'];
  stake: Scalars['String']['output'];
  stakes: Array<GqlCapTableStake>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface GqlCdSetInvitedInput {
  id: Scalars['ID']['input'];
  invited: Scalars['Boolean']['input'];
}

export interface GqlCiCreateInput {
  companyId: Scalars['ID']['input'];
}

export interface GqlCiSetAcceptedByCompanyInput {
  acceptedByCompany: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlCiSetAcceptedByUserInput {
  acceptedByUser: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlCmpCreateInput {
  identityInput: GqlCreateIdentityInput;
}

export interface GqlCmpDeletedResponse {
  id: Scalars['ID']['output'];
}

export interface GqlCmpSetInvitedInput {
  id: Scalars['ID']['input'];
  invited: Scalars['Boolean']['input'];
}

export interface GqlCompany {
  address?: Maybe<Scalars['JSONObject']['output']>;
  addressAsStr?: Maybe<Scalars['String']['output']>;
  allowShortQeso: Scalars['Boolean']['output'];
  articlesOfAssocs: Array<GqlArticlesOfAssoc>;
  authorizationGroups: Array<GqlAuthorizationGroup>;
  authorizedSignatory: Scalars['String']['output'];
  boardMembersMax: Scalars['String']['output'];
  boardMembersMin: Scalars['String']['output'];
  boardSettings?: Maybe<Scalars['JSONObject']['output']>;
  boardSubstitutesMax: Scalars['String']['output'];
  boardSubstitutesMin: Scalars['String']['output'];
  bolagsverketsid?: Maybe<Scalars['String']['output']>;
  capTableTrialExpires?: Maybe<Scalars['Date']['output']>;
  capTables: Array<GqlCapTable>;
  companyInvites: Array<GqlCompanyInvite>;
  companyUsers: Array<GqlCompanyUser>;
  county: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  currency: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  fiscalYear: Scalars['String']['output'];
  foundationDate: Scalars['Date']['output'];
  hubspotObjectId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isAntlerCompany: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  meetingAgendaTemplates: Array<GqlMeetingAgendaTemplate>;
  meetings: Array<GqlMeeting>;
  municipal: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationNumber: Scalars['String']['output'];
  promoCode?: Maybe<Scalars['String']['output']>;
  reservationArbitrator: Scalars['Boolean']['output'];
  reservationConsent: Scalars['Boolean']['output'];
  reservationDescrepancy: Scalars['Boolean']['output'];
  reservationMajority: Scalars['Boolean']['output'];
  reservationNoAuditor: Scalars['Boolean']['output'];
  reservationOther: Scalars['String']['output'];
  reservationPostTransferPurchaseRight: Scalars['Boolean']['output'];
  reservationPreferential: Scalars['Boolean']['output'];
  reservationReconciliation: Scalars['Boolean']['output'];
  reservationReconciliationDate?: Maybe<Scalars['Date']['output']>;
  reservationRightOfFirstRefusal: Scalars['Boolean']['output'];
  reservationVote: Scalars['Boolean']['output'];
  residenceMunicipal: Scalars['String']['output'];
  shareCapital: Scalars['String']['output'];
  shareCapitalMax?: Maybe<Scalars['String']['output']>;
  shareCapitalMin?: Maybe<Scalars['String']['output']>;
  shareClasses?: Maybe<Scalars['JSON']['output']>;
  shareIssues: Array<GqlShareIssue>;
  shareRegisterShareCount: Scalars['Float']['output'];
  shareholderMeetingHowtoCall: Scalars['String']['output'];
  shareholders: Array<GqlShareholder>;
  sharesAtFoundation?: Maybe<Scalars['String']['output']>;
  sharesMax?: Maybe<Scalars['String']['output']>;
  sharesMin?: Maybe<Scalars['String']['output']>;
  sharesTotal: Scalars['String']['output'];
  snrAuthorizations: Array<GqlSnrAuthorization>;
  snrBonds: Array<GqlSnrBond>;
  snrCases: Array<GqlSnrCase>;
  snrShareCapitalChanges: Array<GqlSnrShareCapitalChange>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeInvitePromoCode?: Maybe<Scalars['String']['output']>;
  subscriptions: Array<GqlCompanySubscription>;
  transferDate?: Maybe<Scalars['Date']['output']>;
  updatedAt: Scalars['Date']['output'];
  useGravatar?: Maybe<Scalars['Boolean']['output']>;
  warrantPrograms: Array<GqlWarrantProgram>;
  web?: Maybe<Scalars['String']['output']>;
}


export interface GqlCompanySnrShareCapitalChangesArgs {
  names?: InputMaybe<Array<Scalars['String']['input']>>;
}


export interface GqlCompanyWarrantProgramsArgs {
  kinds?: InputMaybe<Array<GqlWarrantProgramKind>>;
}

export interface GqlCompanyConnection {
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  connection: GqlCompanyConnectionEnum;
  identityId?: Maybe<Scalars['ID']['output']>;
  through?: Maybe<GqlIdentity>;
}

export enum GqlCompanyConnectionEnum {
  CompanyUser = 'companyUser',
  MeetingParticipant = 'meetingParticipant',
  Shareholder = 'shareholder',
  Warrantholder = 'warrantholder'
}

export interface GqlCompanyDirector {
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  functions: Array<Scalars['JSONObject']['output']>;
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId: Scalars['ID']['output'];
  invited: Scalars['Boolean']['output'];
  registeredAbroad: Scalars['Boolean']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlCompanyInvite {
  acceptedByCompany?: Maybe<Scalars['Boolean']['output']>;
  acceptedByUser?: Maybe<Scalars['Boolean']['output']>;
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  user: GqlLimitedUser;
  userId: Scalars['ID']['output'];
}

export interface GqlCompanyProvisionInput {
  organizationNumber: Scalars['String']['input'];
}

export interface GqlCompanySetMeetingFrequencyInput {
  base: Scalars['String']['input'];
  dayOfWeek: Scalars['Int']['input'];
  frequency: Scalars['Int']['input'];
}

export interface GqlCompanySetProfileInput {
  email?: InputMaybe<Scalars['String']['input']>;
  web?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlCompanySetSharesAtFoundationInput {
  sharesAtFoundation: Scalars['String']['input'];
}

export interface GqlCompanySubscription {
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  currentPeriodEnd?: Maybe<Scalars['Date']['output']>;
  currentPeriodStart?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  products: Scalars['JSON']['output'];
  status: Scalars['String']['output'];
  stripeSubId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlCompanySuggestion {
  createdAt: Scalars['Date']['output'];
  functions: Array<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationNumber: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  userId: Scalars['String']['output'];
}

export interface GqlCompanyUser {
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  role: GqlCompanyUserRole;
  updatedAt: Scalars['Date']['output'];
  user: GqlUser;
  userId: Scalars['ID']['output'];
}

export enum GqlCompanyUserRole {
  Admin = 'Admin'
}

export interface GqlCompleteShareCapitalChange {
  caseNumber?: Maybe<Scalars['String']['output']>;
  changedNumberOfShares?: Maybe<Scalars['Int']['output']>;
  changedNumberOfSharesSplitAdjusted?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  decisionDate?: Maybe<Scalars['Date']['output']>;
  filingNumber: Scalars['String']['output'];
  filingYear: Scalars['String']['output'];
  foundersShareFraction?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  kind: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numberOfShares?: Maybe<Scalars['Int']['output']>;
  numberOfSharesSplitAdjusted?: Maybe<Scalars['Int']['output']>;
  paidAmount?: Maybe<Scalars['Float']['output']>;
  postMoney?: Maybe<Scalars['Float']['output']>;
  preMoney?: Maybe<Scalars['Float']['output']>;
  pricePerShare?: Maybe<Scalars['Float']['output']>;
  pricePerShareSplitAdjusted?: Maybe<Scalars['Float']['output']>;
  registrationDate?: Maybe<Scalars['Date']['output']>;
  sccId: Scalars['String']['output'];
  shareCapitalAfter?: Maybe<Scalars['Float']['output']>;
  shareCapitalBefore?: Maybe<Scalars['Float']['output']>;
  shareCapitalChanged?: Maybe<Scalars['Float']['output']>;
  sharePriceFactor?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  splitFactor?: Maybe<Scalars['Float']['output']>;
}

export interface GqlCooptedMeetingParticipant {
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId: Scalars['ID']['output'];
  invited: Scalars['Boolean']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlCreateCapTableInput {
  displayName: Scalars['String']['input'];
  pricePerShare?: InputMaybe<Scalars['String']['input']>;
  recordDate: Scalars['Date']['input'];
  unregisteredShares?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlCreateCapTableStakeInput {
  acqCost?: InputMaybe<Scalars['String']['input']>;
  capTableId: Scalars['ID']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  identityInput: GqlCreateIdentityInput;
  kind: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  stake: Scalars['String']['input'];
}

export interface GqlCreateCapTableStakesInput {
  stakes: Array<GqlCreateCapTableStakeInput>;
}

export interface GqlCreateIdentityInput {
  address?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identityNumber: Scalars['String']['input'];
  identityNumberKind: Scalars['Int']['input'];
  juridicalKind: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlCreateStripeMeetingConcernQuoteInput {
  meetingId: Scalars['ID']['input'];
  stripeCouponId?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlCreateStripeSubscriptionQuoteInput {
  meetingId?: InputMaybe<Scalars['ID']['input']>;
  stripeCouponId?: InputMaybe<Scalars['String']['input']>;
  subPriceId: Scalars['String']['input'];
  subProdId: Scalars['String']['input'];
}

export interface GqlCuCreateInput {
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  personalId: Scalars['ID']['input'];
  role: GqlCompanyUserRole;
}

export interface GqlCuDeletedResponse {
  id: Scalars['ID']['output'];
}

export enum GqlCurrency {
  Aed = 'AED',
  Ars = 'ARS',
  Aud = 'AUD',
  Brl = 'BRL',
  Cad = 'CAD',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Czk = 'CZK',
  Egp = 'EGP',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Hrk = 'HRK',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jpy = 'JPY',
  Krw = 'KRW',
  Kwd = 'KWD',
  Mxn = 'MXN',
  Myr = 'MYR',
  Ngn = 'NGN',
  Nok = 'NOK',
  Nzd = 'NZD',
  Pen = 'PEN',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Qar = 'QAR',
  Ron = 'RON',
  Rub = 'RUB',
  Sar = 'SAR',
  Sek = 'SEK',
  Sgd = 'SGD',
  Thb = 'THB',
  Try = 'TRY',
  Twd = 'TWD',
  Uah = 'UAH',
  Usd = 'USD',
  Vnd = 'VND',
  Zar = 'ZAR'
}

export interface GqlDashboardMilestonesResponse {
  boardMeeting: Scalars['Boolean']['output'];
  paidPlan: Scalars['Boolean']['output'];
  phone: Scalars['Boolean']['output'];
  shareRegister: Scalars['Boolean']['output'];
  signedDocument: Scalars['Boolean']['output'];
  uploadedDoc: Scalars['Boolean']['output'];
  userInvite: Scalars['Boolean']['output'];
}

export enum GqlDecisionStatus {
  Failed = 'FAILED',
  Planned = 'PLANNED',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export interface GqlDeleteStsInput {
  confirm: Scalars['Boolean']['input'];
  ids: Array<Scalars['ID']['input']>;
}

export enum GqlDigitalAgm {
  Allowed = 'Allowed',
  AllowedWithMailVote = 'AllowedWithMailVote',
  NotAllowed = 'NotAllowed'
}

export interface GqlDocDeletedResponse {
  affectedDocuments?: Maybe<Array<GqlDocument>>;
  id: Scalars['ID']['output'];
}

export interface GqlDocGenMcValidatorErrorsResponse {
  mc: GqlMeetingConcern;
  validatorErrors: Array<GqlValidatorMultilangError>;
  validatorStatuses: Array<GqlValidatorStatus>;
}

export interface GqlDocGenMeetingValidatorErrorsResponse {
  appendices: Array<GqlDocGenMcValidatorErrorsResponse>;
  validatorErrors: GqlMeetingValidatorErrors;
}

export enum GqlDocGenState {
  Completed = 'Completed',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Scheduled = 'Scheduled',
  Waiting = 'Waiting'
}

export interface GqlDocIsSignedResponse {
  status?: Maybe<Scalars['String']['output']>;
}

export interface GqlDocSendSignReminderInput {
  id: Scalars['ID']['input'];
}

export interface GqlDocSetTagsInput {
  id: Scalars['ID']['input'];
  userTags: Array<Scalars['String']['input']>;
}

export interface GqlDocSignStartInput {
  id: Scalars['ID']['input'];
}

export interface GqlDocSignStopInput {
  id: Scalars['ID']['input'];
}

export enum GqlDocSignatoryStatus {
  Closed = 'closed',
  Ongoing = 'ongoing',
  Pending = 'pending'
}

export enum GqlDocSystemTagKind {
  AppendixAuthConvertibleIssue = 'AppendixAuthConvertibleIssue',
  AppendixAuthShareIssue = 'AppendixAuthShareIssue',
  AppendixAuthWarrantIssue = 'AppendixAuthWarrantIssue',
  AppendixNiceTerms = 'AppendixNiceTerms',
  AppendixShareIssue = 'AppendixShareIssue',
  AppendixWarrantIssueDecision = 'AppendixWarrantIssueDecision',
  AppendixWarrantTerms = 'AppendixWarrantTerms',
  AppendixWarrantValuation = 'AppendixWarrantValuation',
  AppendixWiseTerms = 'AppendixWiseTerms',
  ArticlesOfAssoc = 'ArticlesOfAssoc',
  ArticlesOfAssocRegForm = 'ArticlesOfAssocRegForm',
  AuditorsStatement = 'AuditorsStatement',
  AuditorsStatementNonCash = 'AuditorsStatementNonCash',
  AuditorsStatementOffset = 'AuditorsStatementOffset',
  AuthorizationRegistrationForm = 'AuthorizationRegistrationForm',
  BoardsStatement = 'BoardsStatement',
  BoardsStatementNonCash = 'BoardsStatementNonCash',
  BoardsStatementOffset = 'BoardsStatementOffset',
  ConvertibleRegistrationForm = 'ConvertibleRegistrationForm',
  EmployeeOptionsAgreement = 'EmployeeOptionsAgreement',
  EmployeeOptionsShareholdersAgreement = 'EmployeeOptionsShareholdersAgreement',
  EmployeeOptionsSummary = 'EmployeeOptionsSummary',
  EmploymentAgreement = 'EmploymentAgreement',
  MeetingAppendix = 'MeetingAppendix',
  MeetingProtocol = 'MeetingProtocol',
  MeetingSummons = 'MeetingSummons',
  MeetingSummonsCoverSheet = 'MeetingSummonsCoverSheet',
  MeetingVotingList = 'MeetingVotingList',
  NiceAgreement = 'NiceAgreement',
  OneWayNda = 'OneWayNda',
  Other = 'Other',
  PowerOfAttorney = 'PowerOfAttorney',
  ShareIssueRegistrationForm = 'ShareIssueRegistrationForm',
  ShareSubscriptionList = 'ShareSubscriptionList',
  ShareholderWaiver = 'ShareholderWaiver',
  TransactionNote = 'TransactionNote',
  WarrantAgreement = 'WarrantAgreement',
  WarrantCertificate = 'WarrantCertificate',
  WarrantRegistrationForm = 'WarrantRegistrationForm',
  WarrantTodoList = 'WarrantTodoList',
  WiseAgreement = 'WiseAgreement'
}

export interface GqlDocument {
  adminCompany: GqlCompany;
  articlesOfAssocDocument?: Maybe<GqlArticlesOfAssocDocument>;
  authorizationGroupDocument?: Maybe<GqlAuthorizationGroupDocument>;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  deletable: Scalars['Boolean']['output'];
  dirty: Scalars['Boolean']['output'];
  documentSignatures: Array<GqlDocumentSignature>;
  filename: Scalars['String']['output'];
  filesize?: Maybe<Scalars['Int']['output']>;
  genState: GqlDocGenState;
  groupId?: Maybe<Scalars['String']['output']>;
  hasSignStatus: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  kind: GqlDocSystemTagKind;
  meetingDocument?: Maybe<GqlMeetingDocument>;
  mimeType: Scalars['String']['output'];
  optionsRegistrationDocument?: Maybe<GqlOptionsRegistrationDocument>;
  reminderSentAt?: Maybe<Scalars['Date']['output']>;
  scriveCtime?: Maybe<Scalars['Date']['output']>;
  scriveMtime?: Maybe<Scalars['Date']['output']>;
  scriveParties?: Maybe<Scalars['JSON']['output']>;
  scriveStatus?: Maybe<GqlScriveDocumentStatus>;
  scriveTimeoutTime?: Maybe<Scalars['String']['output']>;
  sha256?: Maybe<Scalars['String']['output']>;
  shareIssueDocument?: Maybe<GqlShareIssueDocument>;
  shareTransactionDocument?: Maybe<GqlShareTransactionDocument>;
  signAllowedAction: GqlSignAllowedAction;
  signatureMethod?: Maybe<GqlDocumentSignatureMethod>;
  signedFilesize?: Maybe<Scalars['Int']['output']>;
  signedSha256?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  uploaded: Scalars['Boolean']['output'];
  userTags?: Maybe<Array<Scalars['String']['output']>>;
  warrantProgramDocument?: Maybe<GqlWarrantDocument>;
  zignedAgreementStatus?: Maybe<GqlZignedAgreementStatus>;
}

export interface GqlDocumentFilterInput {
  kinds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<GqlDocSignatoryStatus>>;
}

export interface GqlDocumentGenInput {
  genSchedRequest: Scalars['JSONObject']['input'];
}

export interface GqlDocumentGenReq {
  docGen: Scalars['JSONObject']['output'];
}

/** Document language */
export interface GqlDocumentLanguageInput {
  primary_lang?: Scalars['Boolean']['input'];
  secondary_lang?: Scalars['Boolean']['input'];
}

export interface GqlDocumentSignature {
  authMethodToSign?: Maybe<GqlScriveAuthMethod>;
  authMethodToView?: Maybe<GqlScriveAuthMethod>;
  createdAt: Scalars['Date']['output'];
  deliveryMethod?: Maybe<Scalars['String']['output']>;
  document: GqlDocument;
  documentId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  headerPrimaryLang?: Maybe<Scalars['String']['output']>;
  headerSecondaryLang?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  proxy?: Maybe<GqlIdentity>;
  proxyId?: Maybe<Scalars['ID']['output']>;
  scriveEmailDeliveryStatus?: Maybe<Scalars['String']['output']>;
  scriveReadInvitationTime?: Maybe<Scalars['String']['output']>;
  scriveRejectedTime?: Maybe<Scalars['String']['output']>;
  scriveRejectionReason?: Maybe<Scalars['String']['output']>;
  scriveSeenTime?: Maybe<Scalars['String']['output']>;
  scriveSignTime?: Maybe<Scalars['String']['output']>;
  signatory: GqlIdentity;
  signatoryId: Scalars['ID']['output'];
  signed: Scalars['Boolean']['output'];
  updatedAt: Scalars['Date']['output'];
  zignedParticipantRole?: Maybe<GqlZignedParticipantRole>;
  zignedParticipantSigningRoomLink?: Maybe<Scalars['String']['output']>;
  zignedParticipantStatus?: Maybe<GqlZignedParticipantStatus>;
}

export enum GqlDocumentSignatureMethod {
  Analogue = 'Analogue',
  Digital = 'Digital'
}

export interface GqlDocumentUserTagsResponse {
  tags: Array<Scalars['String']['output']>;
}

export interface GqlDocumentsPagedResult {
  models: Array<GqlDocument>;
  totalCount: Scalars['Int']['output'];
}

export interface GqlDsCreateInput {
  documentId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  proxyInput?: InputMaybe<GqlCreateIdentityInput>;
  signatoryInput: GqlCreateIdentityInput;
}

export interface GqlDsDeletedResponse {
  id: Scalars['ID']['output'];
}

export interface GqlDsSetAuthMethodToSignInput {
  authMethodToSign: Scalars['String']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlDsSetEmailAndMobileInput {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlDsSetProxyInput {
  id: Scalars['ID']['input'];
  proxyInput: GqlCreateIdentityInput;
}

export interface GqlEoValidatorErrors {
  dates: GqlValidatorStatus;
  decision: GqlValidatorStatus;
  duration: GqlValidatorStatus;
  language: GqlValidatorStatus;
  maxNumberOfOptions: GqlValidatorStatus;
  shareClasses: GqlValidatorStatus;
  sharePrice: GqlValidatorStatus;
  valuation: GqlValidatorStatus;
}

export interface GqlGetIndividualReportInput {
  date?: InputMaybe<Scalars['Date']['input']>;
  shareholderId: Scalars['ID']['input'];
}

export interface GqlGetIndividualReportResponse {
  shareClasses: Array<GqlIndividualReportShareClass>;
}

export interface GqlIdentity {
  address?: Maybe<Scalars['String']['output']>;
  capTableStakes: Array<GqlCapTableStake>;
  companyDirector?: Maybe<GqlCompanyDirector>;
  companyId: Scalars['ID']['output'];
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  emailMd5?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identityNumber: Scalars['String']['output'];
  identityNumberKind: Scalars['Int']['output'];
  isCurrentUser: Scalars['Boolean']['output'];
  juridicalKind: Scalars['Int']['output'];
  meetingParticipants: Array<GqlMeetingParticipant>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  shareIssueParticipants: Array<GqlShareIssueParticipant>;
  shareholder?: Maybe<GqlShareholder>;
  updatedAt: Scalars['Date']['output'];
  user?: Maybe<GqlLimitedUser>;
  userId?: Maybe<Scalars['ID']['output']>;
}

export interface GqlIdentityFilterInput {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlIdentitySetEmailInput {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlIdentitySetPhoneInput {
  id: Scalars['ID']['input'];
  phone: Scalars['String']['input'];
}

export interface GqlIndividualReportLabelName {
  primaryLang: Scalars['String']['output'];
  secondaryLang: Scalars['String']['output'];
}

export interface GqlIndividualReportPrices {
  pricePerShare?: Maybe<Scalars['String']['output']>;
  totalSum?: Maybe<Scalars['String']['output']>;
}

export interface GqlIndividualReportShareClass {
  averagePricePerDivested?: Maybe<Scalars['Float']['output']>;
  averagePricePerShareAcquired?: Maybe<Scalars['Float']['output']>;
  shareClassName: Scalars['String']['output'];
  splitExists: Scalars['Boolean']['output'];
  totalPricePurchase?: Maybe<Scalars['Float']['output']>;
  totalPriceSale?: Maybe<Scalars['Float']['output']>;
  transactions: Array<GqlIndividualReportTransaction>;
}

export interface GqlIndividualReportTransaction {
  acquisition: Scalars['Boolean']['output'];
  blockEnd: Scalars['Float']['output'];
  blockStart: Scalars['Float']['output'];
  buy?: Maybe<GqlIndividualReportPrices>;
  date: Scalars['String']['output'];
  name: GqlIndividualReportLabelName;
  numberOfShares: Scalars['Int']['output'];
  sell?: Maybe<GqlIndividualReportPrices>;
  sortOrder: Scalars['Float']['output'];
  totalNumberOfShares?: Maybe<Scalars['Int']['output']>;
}

export interface GqlInvestment {
  agreementStatus: GqlAgreementStatus;
  decisionStatus: GqlDecisionStatus;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  lastSubDate?: Maybe<Scalars['Date']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentStatus: Scalars['Int']['output'];
  registrationStatus: GqlRegistrationStatus;
}

export interface GqlLimitedUser {
  admin: Scalars['Boolean']['output'];
  companyInvite: GqlCompanyInvite;
  createdAt: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  personalId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
}

export enum GqlMpKind {
  Boardmember = 'Boardmember',
  Guest = 'Guest',
  Shareholder = 'Shareholder'
}

export interface GqlMatCreateInput {
  isDefault: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
}

export interface GqlMatDeletedResponse {
  id: Scalars['ID']['output'];
}

export interface GqlMatSetIsDefaultInput {
  id: Scalars['ID']['input'];
  isDefault: Scalars['Boolean']['input'];
}

export interface GqlMatSetNameInput {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}

export interface GqlMatiCreateInput {
  commentPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  commentSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  decisionPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  decisionSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  firstGroup: Scalars['Boolean']['input'];
  kind: Scalars['Int']['input'];
  lastGroup: Scalars['Boolean']['input'];
  mandatory: Scalars['Boolean']['input'];
  meetingAgendaTemplateId: Scalars['ID']['input'];
  order: Scalars['Int']['input'];
  subjectPrimaryLang: Scalars['String']['input'];
  subjectSecondaryLang: Scalars['String']['input'];
}

export interface GqlMatiDeletedResponse {
  id: Scalars['ID']['output'];
  updatedMatis: Array<GqlMeetingAgendaTemplateItem>;
}

export interface GqlMatiSetOrderInput {
  id: Scalars['ID']['input'];
  order: Scalars['Int']['input'];
}

export interface GqlMatiUpdateInput {
  commentPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  commentSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  decisionPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  decisionSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  kind: Scalars['Int']['input'];
  mandatory: Scalars['Boolean']['input'];
  meetingAgendaTemplateId: Scalars['ID']['input'];
  order: Scalars['Int']['input'];
  subjectPrimaryLang: Scalars['String']['input'];
  subjectSecondaryLang: Scalars['String']['input'];
}

export interface GqlMcDeletedResponse {
  id: Scalars['ID']['output'];
}

export interface GqlMcsSetApprovedInput {
  appendixNumber: Scalars['Int']['input'];
  approved: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlMeeting {
  agendaItems: Array<GqlAgendaItem>;
  agendaItemsDone: Scalars['Boolean']['output'];
  approvedByMeeting?: Maybe<Scalars['ID']['output']>;
  boardsRecommendation: Scalars['Boolean']['output'];
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  contactEmail?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  createdByMeeting?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['Date']['output']>;
  heldKind: GqlMeetingHeldKind;
  id: Scalars['ID']['output'];
  kind: GqlMeetingKind;
  latestMinutesDocument?: Maybe<GqlDocument>;
  latestSummonsCoverSheet?: Maybe<GqlDocument>;
  latestSummonsDocument?: Maybe<GqlDocument>;
  meetingConcerns: Array<GqlMeetingConcern>;
  meetingDocuments: Array<GqlMeetingDocument>;
  meetingParticipants: Array<GqlMeetingParticipant>;
  meetingTodos: Array<GqlMeetingTodo>;
  number?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  postalVote: Scalars['Boolean']['output'];
  postalVoteDate?: Maybe<Scalars['Date']['output']>;
  postalVoteEmail?: Maybe<Scalars['String']['output']>;
  primaryLang: Scalars['Boolean']['output'];
  registerAttendance?: Maybe<Scalars['Boolean']['output']>;
  registerAttendanceDate?: Maybe<Scalars['Date']['output']>;
  secondaryLang: Scalars['Boolean']['output'];
  startTime: Scalars['Date']['output'];
  status: GqlMeetingStatus;
  summonStatus: GqlMeetingSummonStatus;
  summonsAttachments: Array<GqlDocument>;
  updatedAt: Scalars['Date']['output'];
  usesStepper: Scalars['Boolean']['output'];
}

export interface GqlMeetingAgendaTemplate {
  company: GqlCompany;
  companyId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  meetingAgendaTemplateItems: Array<GqlMeetingAgendaTemplateItem>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlMeetingAgendaTemplateItem {
  commentPrimaryLang?: Maybe<Scalars['String']['output']>;
  commentSecondaryLang?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  decisionPrimaryLang?: Maybe<Scalars['String']['output']>;
  decisionSecondaryLang?: Maybe<Scalars['String']['output']>;
  firstGroup: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['Int']['output'];
  lastGroup: Scalars['Boolean']['output'];
  mandatory: Scalars['Boolean']['output'];
  meetingAgendaTemplate: GqlMeetingAgendaTemplate;
  meetingAgendaTemplateId: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  subjectPrimaryLang: Scalars['String']['output'];
  subjectSecondaryLang: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlMeetingBoardsRecommendationInput {
  boardsRecommendation: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlMeetingConcern {
  appendixNumber: Scalars['Int']['output'];
  approved?: Maybe<Scalars['Boolean']['output']>;
  articlesOfAssoc?: Maybe<GqlArticlesOfAssoc>;
  articlesOfAssocId?: Maybe<Scalars['ID']['output']>;
  authorizationGroup?: Maybe<GqlAuthorizationGroup>;
  authorizationGroupId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  meeting: GqlMeeting;
  meetingId: Scalars['String']['output'];
  shareIssue?: Maybe<GqlShareIssue>;
  shareIssueId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['Date']['output'];
  warrantProgram?: Maybe<GqlWarrantProgram>;
  warrantProgramId?: Maybe<Scalars['ID']['output']>;
}

export interface GqlMeetingCreateInput {
  boardsRecommendation: Scalars['Boolean']['input'];
  createdByMeeting?: InputMaybe<Scalars['ID']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  heldKind: GqlMeetingHeldKind;
  kind: GqlMeetingKind;
  meetingTemplateId?: InputMaybe<Scalars['ID']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  postalVote: Scalars['Boolean']['input'];
  primaryLang: Scalars['Boolean']['input'];
  secondaryLang: Scalars['Boolean']['input'];
  startTime: Scalars['Date']['input'];
  usesStepper: Scalars['Boolean']['input'];
}

export interface GqlMeetingDeletedResponse {
  deletedMcs: Array<GqlMcDeletedResponse>;
  id: Scalars['ID']['output'];
}

export interface GqlMeetingDocument {
  createdAt: Scalars['Date']['output'];
  document?: Maybe<GqlDocument>;
  documentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  meeting: GqlMeeting;
  meetingId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
}

export enum GqlMeetingHeldKind {
  PerCapsulam = 'PerCapsulam',
  Phone = 'Phone',
  Physical = 'Physical',
  PhysicalAndPhone = 'PhysicalAndPhone'
}

export enum GqlMeetingKind {
  BoardMeeting = 'BoardMeeting',
  ExtraShareholdersMeeting = 'ExtraShareholdersMeeting',
  ShareholdersMeeting = 'ShareholdersMeeting'
}

export interface GqlMeetingMcCountsResponse {
  articlesOfAssocs: Scalars['Float']['output'];
  authorizationGroups: Scalars['Float']['output'];
  employeeOptions: Scalars['Float']['output'];
  shareIssues: Scalars['Float']['output'];
  warrantPrograms: Scalars['Float']['output'];
}

export interface GqlMeetingPaidForConcernsResponse {
  shareIssues: Array<GqlShareIssue>;
  warrantPrograms: Array<GqlWarrantProgram>;
}

export interface GqlMeetingParticipant {
  adjuster: Scalars['Boolean']['output'];
  chairman: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  emailId?: Maybe<Scalars['String']['output']>;
  emailSummonsId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId: Scalars['String']['output'];
  initials?: Maybe<Scalars['String']['output']>;
  isShareholder?: Maybe<Scalars['Boolean']['output']>;
  kind: GqlMpKind;
  /** @deprecated use summonsEmail, summonsEmailStatus and summonsEmailFailReason */
  mandrillEvent?: Maybe<Scalars['JSONObject']['output']>;
  meeting: GqlMeeting;
  meetingId?: Maybe<Scalars['String']['output']>;
  meetingParticipantShares: Array<GqlMeetingParticipantShare>;
  position?: Maybe<Scalars['String']['output']>;
  present: Scalars['Boolean']['output'];
  proxy?: Maybe<GqlIdentity>;
  proxyId?: Maybe<Scalars['String']['output']>;
  secretary: Scalars['Boolean']['output'];
  /** @deprecated use summonsEmail, summonsEmailStatus and summonsEmailFailReason */
  sendgridMessageEvent?: Maybe<Scalars['JSONObject']['output']>;
  sendgridMessageId?: Maybe<Scalars['String']['output']>;
  summoned?: Maybe<Scalars['Boolean']['output']>;
  summonsEmail?: Maybe<Scalars['String']['output']>;
  summonsEmailFailReason?: Maybe<Scalars['String']['output']>;
  summonsEmailStatus?: Maybe<GqlSummonsEmailStatus>;
  summonsJobId?: Maybe<Scalars['String']['output']>;
  summonsJobState?: Maybe<Scalars['Int']['output']>;
  summonsSimplifiedState: GqlSummonsSimplifiedState;
  updatedAt: Scalars['Date']['output'];
}

export interface GqlMeetingParticipantFilterInput {
  failedEmailsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  identity?: InputMaybe<GqlIdentityFilterInput>;
  isShareholder?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface GqlMeetingParticipantShare {
  id: Scalars['ID']['output'];
  meetingParticipant?: Maybe<GqlMeetingParticipant>;
  meetingParticipantId: Scalars['ID']['output'];
  numberOfShares?: Maybe<Scalars['String']['output']>;
  numberOfVotes?: Maybe<Scalars['String']['output']>;
  shareClass?: Maybe<GqlShareClass>;
}

/** Create meeting participant share */
export interface GqlMeetingParticipantShareCreateInput {
  meetingParticipantId: Scalars['String']['input'];
  numberOfShares: Scalars['String']['input'];
  numberOfVotes: Scalars['String']['input'];
  shareClass: GqlShareClassInput;
}

export interface GqlMeetingParticipantShareDeletedResponse {
  affectedDocuments: Array<GqlDocument>;
  id: Scalars['ID']['output'];
  mpDeletedResponse?: Maybe<GqlMpDeletedResponse>;
}

/** Update meeting participant share */
export interface GqlMeetingParticipantShareUpdateInput {
  id: Scalars['ID']['input'];
  numberOfShares?: InputMaybe<Scalars['String']['input']>;
  numberOfVotes?: InputMaybe<Scalars['String']['input']>;
  shareClass?: InputMaybe<GqlShareClassInput>;
}

export interface GqlMeetingParticipantShareUpdatedResponse {
  affectedDocuments: Array<GqlDocument>;
  meetingParticipantShare: GqlMeetingParticipantShare;
}

export interface GqlMeetingParticipantsPagedResult {
  models: Array<GqlMeetingParticipant>;
  totalCount: Scalars['Int']['output'];
}

export interface GqlMeetingPostalVoteInput {
  id: Scalars['ID']['input'];
  postalVote: Scalars['Boolean']['input'];
  postalVoteDate?: InputMaybe<Scalars['Date']['input']>;
  postalVoteEmail?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlMeetingSetApprovedInput {
  approved: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlMeetingSetContactEmailInput {
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  registerAttendanceDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface GqlMeetingSetDetailsInput {
  endTime?: InputMaybe<Scalars['Date']['input']>;
  heldKind: GqlMeetingHeldKind;
  id: Scalars['ID']['input'];
  number?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Date']['input'];
}

export interface GqlMeetingSetFunctionsInput {
  id: Scalars['ID']['input'];
  mpCreate: GqlMpCreateInput;
}

export interface GqlMeetingSetFunctionsResponse {
  meeting: GqlMeeting;
  meetingParticipants: Array<GqlMeetingParticipant>;
}

export interface GqlMeetingSetLanguageInput {
  id: Scalars['ID']['input'];
  primaryLang: Scalars['Boolean']['input'];
  secondaryLang: Scalars['Boolean']['input'];
}

export enum GqlMeetingStatus {
  Finished = 'Finished',
  InProgress = 'InProgress',
  Scheduled = 'Scheduled'
}

export enum GqlMeetingSummonStatus {
  Never = 'never',
  Pending = 'pending',
  Sent = 'sent'
}

export interface GqlMeetingTodo {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  meeting: GqlMeeting;
  meetingId: Scalars['ID']['output'];
  todo: GqlTodo;
  todoId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlMeetingValidatorErrors {
  agenda: GqlValidatorStatus;
  details: GqlValidatorStatus;
  emailSummons: GqlValidatorStatus;
  language: GqlValidatorStatus;
  participants: GqlValidatorStatus;
  postalVote: GqlValidatorStatus;
  protocol: GqlValidatorStatus;
  registerAttendance: GqlValidatorStatus;
  summons: GqlValidatorStatus;
  votingList: GqlValidatorStatus;
}

export interface GqlMeetingsPagedResult {
  models: Array<GqlMeeting>;
  totalCount: Scalars['Int']['output'];
}

export interface GqlMinAndMaxOfSharesResponse {
  max?: Maybe<Scalars['String']['output']>;
  min?: Maybe<Scalars['String']['output']>;
}

/** Create warrant participant */
export interface GqlMpCreateInput {
  adjuster: Scalars['Boolean']['input'];
  chairman: Scalars['Boolean']['input'];
  identityInput: GqlCreateIdentityInput;
  isShareholder?: InputMaybe<Scalars['Boolean']['input']>;
  kind: GqlMpKind;
  meetingId: Scalars['ID']['input'];
  present: Scalars['Boolean']['input'];
  secretary: Scalars['Boolean']['input'];
}

export interface GqlMpDeletedResponse {
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  id: Scalars['ID']['output'];
}

export interface GqlMpDeletedShareholdersResponse {
  aiSetDoneResponse: GqlAiSetDoneResponse;
  meetingId: Scalars['ID']['output'];
}

/** Import share state to meeting voting list */
export interface GqlMpImportShareStateInput {
  date: Scalars['Date']['input'];
  meetingId: Scalars['ID']['input'];
}

export interface GqlMpImportShareStateResponse {
  added: Array<GqlMeetingParticipant>;
  affectedDocuments: Array<GqlDocument>;
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  removed: Array<GqlMpDeletedResponse>;
  updated: Array<GqlMeetingParticipant>;
}

export interface GqlMpSetFunctionsInput {
  adjuster: Scalars['Boolean']['input'];
  chairman: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  secretary: Scalars['Boolean']['input'];
}

export interface GqlMpSetPresentInput {
  id: Scalars['ID']['input'];
  present: Scalars['Boolean']['input'];
}

/** set proxy */
export interface GqlMpSetProxyInput {
  id: Scalars['ID']['input'];
  proxyInput: GqlCreateIdentityInput;
}

/** Create meeting todo */
export interface GqlMtCreateInput {
  meetingId: Scalars['ID']['input'];
  todoCreate: GqlTodoCreateInput;
}

export interface GqlMtDeletedResponse {
  id: Scalars['ID']['output'];
}

/** Update meeting todo */
export interface GqlMtSetDoneInput {
  id: Scalars['ID']['input'];
  todoDone: GqlTodoSetDoneInput;
}

export interface GqlMutation {
  agConnectMeeting: GqlAgConnectMeetingResponse;
  agCreateMeeting: GqlAgConnectMeetingResponse;
  agDisconnectMeeting: GqlAgDisconnectMeetingResponse;
  agSetNoBoardStatement: GqlAuthorizationGroup;
  agSetSnrAuthId: GqlAuthorizationGroup;
  agSetSnrCaseId: GqlAuthorizationGroup;
  agSetValidThru: GqlAuthorizationGroup;
  agUpdate: GqlAuthorizationGroup;
  aoaConnectMeeting: GqlArticlesOfAssoc;
  aoaCreateMeeting: GqlArticlesOfAssoc;
  aoaDisconnectMeeting: GqlAoaDisconnectMeetingResponse;
  aoaSetArbitrator: GqlArticlesOfAssoc;
  aoaSetAuditorsLimit: GqlArticlesOfAssoc;
  aoaSetBusiness: GqlArticlesOfAssoc;
  aoaSetConsent: GqlArticlesOfAssoc;
  aoaSetMajority: GqlArticlesOfAssoc;
  aoaSetPostTransferPurchaseRight: GqlArticlesOfAssoc;
  aoaSetRightOfFirstRefusal: GqlArticlesOfAssoc;
  aoaSetShareChange: GqlArticlesOfAssoc;
  aoaSetShareLimits: GqlArticlesOfAssoc;
  aoaSetSummons: GqlArticlesOfAssoc;
  aoaToggleShareChange: GqlArticlesOfAssoc;
  aoaUpdate: GqlArticlesOfAssoc;
  capTableStakeDelete: GqlCapTableStake;
  capTableStakeSetEnabled: GqlCapTableStake;
  capTableStakeUpdate: GqlCapTableStake;
  cdSetInvited: GqlCompanyDirector;
  ciSetAcceptedByCompany: GqlCompanyInvite;
  ciSetAcceptedByUser: GqlCompanyInvite;
  cmpCreate: GqlCooptedMeetingParticipant;
  cmpDelete: GqlCmpDeletedResponse;
  cmpSetInvited: GqlCooptedMeetingParticipant;
  companyDelete?: Maybe<Scalars['Void']['output']>;
  companyProvision: GqlProvisionedCompany;
  companySetMeetingFrequency: GqlCompany;
  companySetProfile: GqlCompany;
  companySetSharesAtFoundation: GqlCompany;
  companySyncToHubspot: GqlCompany;
  createAi: Array<GqlAgendaItem>;
  createAid: GqlAgendaItemDecision;
  createArticlesOfAssoc: GqlArticlesOfAssoc;
  createAuthorization: GqlAuthCreateResponse;
  createAuthorizationGroup: GqlAuthorizationGroup;
  createCapTable: GqlCapTable;
  createCapTableStake: GqlCapTableStake;
  createCapTableStakes: Array<GqlCapTableStake>;
  createCompanyInvite: GqlCompanyInvite;
  createCompanyUser: GqlCompanyUser;
  createDocumentSignature: GqlDocumentSignature;
  createIdentity: GqlIdentity;
  createMeeting: GqlMeeting;
  createMeetingParticipant: GqlMeetingParticipant;
  createMeetingParticipantShare: GqlMeetingParticipantShare;
  createMeetingTodo: GqlMeetingTodo;
  createNiceFounder: GqlNiceFounderCreatedResponse;
  createOptionsRegistration: GqlOptionsRegistration;
  createOptionsSharesRegistration: GqlOptionsSharesRegistration;
  createOptionsSharesSubscriberRepresentative: GqlOptionsSharesSubscriberRepresentative;
  createOptionsSubscriberRepresentative: GqlOptionsSubscriberRepresentative;
  createShareIssue: GqlShareIssue;
  createShareIssueBoardMember: GqlShareIssueBoardMember;
  createShareIssueParticipant: GqlShareIssueParticipant;
  createShareIssueRegistration: GqlShareIssueRegistration;
  createShareTransactionGroup: GqlStgCreatedResponse;
  createShareholder: GqlShareholder;
  createWarrantBoardMember: GqlWarrantBoardMember;
  createWarrantProgram: GqlWarrantProgram;
  createWarrantShareholder: GqlWarrantShareholder;
  createWdbFromBond: GqlWiseDependencyBond;
  createWiseDepBond: GqlWiseDependencyBond;
  createWpFromSnrBond: GqlWarrantProgram;
  deleteAi: GqlAiDeletedResponse;
  deleteAid: GqlAidDeletedResponse;
  deleteArticlesOfAssoc: GqlAoaDeletedResponse;
  deleteAuthorization: GqlAuthDeletedResponse;
  deleteAuthorizationGroup: GqlAgDeletedResponse;
  deleteCapTable?: Maybe<Scalars['Void']['output']>;
  deleteCompanyInvite: Scalars['Boolean']['output'];
  deleteCompanyUser: GqlCuDeletedResponse;
  deleteDocument: GqlDocDeletedResponse;
  deleteDocumentSignature: GqlDsDeletedResponse;
  deleteEmptyShareTransactionGroup: GqlStsMutationResponse;
  deleteMeeting: GqlMeetingDeletedResponse;
  deleteMeetingParticipant: GqlMpDeletedResponse;
  deleteMeetingParticipantShare: GqlMeetingParticipantShareDeletedResponse;
  deleteMeetingShareholders: GqlMpDeletedShareholdersResponse;
  deleteMeetingTodo: GqlMtDeletedResponse;
  deleteNiceFounder: GqlNiceFounderDeletedResponse;
  deleteOptionsSharesSubscriberRepresentative: GqlOptionsSharesSubscriberRepresentativeDeletedResponse;
  deleteOptionsSubscriberRepresentative: GqlOptionsSubscriberRepresentativeDeletedResponse;
  deletePasskey: GqlPasskeyDeletedResponse;
  deleteShareIssue: GqlSiDeletedResponse;
  deleteShareIssueBoardMember: GqlSibmDeletedResponse;
  deleteShareIssueParticipant: GqlSipDeletedResponse;
  deleteShareTransactionGroup: GqlStsMutationResponse;
  deleteShareTransactions: GqlStsMutationResponse;
  deleteShareholder: GqlShDeletedResponse;
  deleteWarrantBoardMember: GqlWpbmDeletedResponse;
  deleteWarrantProgram: GqlWpDeletedResponse;
  deleteWarrantShareholder: GqlWshDeletedResponse;
  deleteWiseDependencyBond: GqlWdbDeletedResponse;
  docGenAgRegistrationForm: GqlDocument;
  docGenAoaRegistrationForm: GqlDocument;
  docGenConvertibleRegistrationForm: GqlDocument;
  docGenIndividualReport: GqlDocumentGenReq;
  docGenIndividualReportSh: GqlDocumentGenReq;
  docGenNiceAgreement: GqlDocument;
  docGenOptionsShareSubscriptionList: GqlDocument;
  docGenPowerOfAttorney: GqlDocument;
  docGenQesoAgreement: GqlDocument;
  docGenScheduleDocGen: GqlDocument;
  docGenShareCertificate: GqlDocumentGenReq;
  docGenShareSubscriptionList: GqlDocument;
  docGenSiRegistrationForm: GqlDocument;
  docGenTransactionNote: GqlDocumentGenReq;
  docGenTransactionNoteSh: GqlDocumentGenReq;
  docGenWarrantAgreement: GqlDocument;
  docGenWarrantRegistrationForm: GqlDocument;
  docGenWarrantSharesRegistrationForm: GqlDocument;
  docGenWiseAgreement: GqlDocument;
  docGenerateMeetingMinutes: GqlDocument;
  docGenerateMeetingSummons: GqlDocument;
  docGenerateMeetingSummonsDirect: GqlDocument;
  docSendSignReminder: GqlDocument;
  docSetTags: GqlDocument;
  docSignRefresh: GqlDocument;
  docSignStart: GqlDocument;
  docSignStop: GqlDocument;
  docSoftDelete: GqlDocDeletedResponse;
  dsSetAuthMethodToSign: GqlDocumentSignature;
  dsSetEmailAndMobile: GqlDocumentSignature;
  dsSetProxy: GqlDocumentSignature;
  duplicateCapTable: GqlCapTable;
  editWiseDependencyBond: GqlWiseDependencyBond;
  matCreate: Array<GqlMeetingAgendaTemplate>;
  matDelete: GqlMatDeletedResponse;
  matSetIsDefault: Array<GqlMeetingAgendaTemplate>;
  matSetName: GqlMeetingAgendaTemplate;
  matiCreate: Array<GqlMeetingAgendaTemplateItem>;
  matiDelete: GqlMatiDeletedResponse;
  matiSetOrder: Array<GqlMeetingAgendaTemplateItem>;
  matiUpdate: Array<GqlMeetingAgendaTemplateItem>;
  meetingSetFunctions: GqlMeetingSetFunctionsResponse;
  mpImportShareState: GqlMpImportShareStateResponse;
  mpSetFunctions: Array<GqlMeetingParticipant>;
  mpSetPresent: GqlMeetingParticipant;
  mpSetProxy: GqlMeetingParticipant;
  mtSetDone: GqlMeetingTodo;
  niceFounderImportFounders: GqlNiceFounderImportFoundersResponse;
  orDelete: GqlOrDeletedResponse;
  orSetPayBeforeDate: GqlOrUpdateResponse;
  orSetPayment: GqlOrUpdateResponse;
  orSetSnrCase: GqlOptionsRegistration;
  orSignatoryCreate: GqlOrSignatoryCreateResponse;
  orSignatoryDelete: GqlOrSignatoryDeletedResponse;
  orUpdate: GqlOrUpdateResponse;
  osrDelete: GqlOsrDeletedResponse;
  osrSetPayBeforeDate: GqlOsrUpdateResponse;
  osrSetPayment: GqlOsrUpdateResponse;
  osrSetRecordDay: GqlOsrUpdateResponse;
  osrSetRegDetails: GqlOsrUpdateResponse;
  osrSetSnrCase: GqlOptionsSharesRegistration;
  osrUpdate: GqlOsrUpdateResponse;
  ossCreate: GqlOptionsSharesSubscriber;
  ossDelete: GqlOssDeletedResponse;
  ossSetPaymentStatus: GqlOssUpdateResponse;
  ossUpdate: GqlOssUpdateResponse;
  refreshCapTable: GqlCapTable;
  refreshIdentity: GqlIdentity;
  resetAis: Array<GqlAgendaItem>;
  sendMailMeetingSummonsToAll: Array<GqlMeetingParticipant>;
  sendMailMeetingSummonsToOne: GqlMeetingParticipant;
  sendPromoInvite: Scalars['Boolean']['output'];
  setAiDone: GqlAiSetDoneResponse;
  setAiDoneWithApprovableMeetings: GqlAiWithMeetingsResponse;
  setAiDoneWithMcs: GqlAiWithMcsResponse;
  setAiDoneWithNextMeetings: GqlAiWithMeetingsResponse;
  setAiOrder: Array<GqlAgendaItem>;
  setIdentityEmail: GqlIdentity;
  setIdentityPhone: GqlIdentity;
  setMcApproved: GqlMeetingConcern;
  setMeetingBoardsRecommendation: GqlMeeting;
  setMeetingContactEmail: GqlMeeting;
  setMeetingDetails: GqlMeeting;
  setMeetingLanguage: GqlMeeting;
  setMeetingPostalVote: GqlMeeting;
  siAddShareClass: GqlShareIssue;
  siConnectAuthorization: GqlShareIssue;
  siConnectMeeting: GqlSiConnectMeetingResponse;
  siCreateMeeting: GqlSiConnectMeetingResponse;
  siDeleteShareClass: GqlShareIssue;
  siDisconnectAuthorization: GqlSiDisconnectAuthorizationResponse;
  siDisconnectMeeting: GqlSiDisconnectMeetingResponse;
  siEditShareClass: GqlShareIssue;
  siSetBoardStatement: GqlSiSetBoardsStatementResponse;
  siSetDescription: GqlShareIssue;
  siSetLanguage: GqlShareIssue;
  siSetNewShares: GqlShareIssue;
  siSetNonCashStatement: GqlShareIssue;
  siSetPaymentKind: GqlSiSetPaymentKindResponse;
  siSetSubscriptionDates: GqlShareIssue;
  siUpdate: GqlShareIssue;
  sipEdit: GqlSipUpdateResponse;
  sipSetNumberOfShares: GqlSipUpdateResponse;
  sipSetPaymentKind: GqlSipUpdateResponse;
  sipSetPaymentStatus: GqlSipUpdateResponse;
  sipSetProxy: GqlSipUpdateResponse;
  sirSetCurrencyExchangeRate: GqlSirUpdateResponse;
  sirSetPayment: GqlSirUpdateResponse;
  sirSetPaymentDueDate: GqlSirUpdateResponse;
  sirSetSnrCase: GqlShareIssueRegistration;
  sirUpdate: GqlSirUpdateResponse;
  stCreateIssue: Array<GqlShareTransaction>;
  stCreateTransfer: GqlStsMutationResponse;
  stCreateUnissue: GqlStsMutationResponse;
  stSetComment: GqlShareTransaction;
  stSetPricePerShare: GqlShareTransaction;
  stSetShareCertificateDate: GqlShareTransaction;
  stgSetSccId: GqlShareTransactionGroup;
  stgSetup: Array<GqlShareTransactionGroup>;
  stripeAcceptQuote: GqlStripeInvoice;
  stripeCreateBillingPortalSession: GqlStripeBillingPortalSession;
  stripeCreateCustomer: GqlCompany;
  stripeCreateMeetingConcernsPaymentQuote: GqlStripeQuote;
  stripeCreateSetupCheckoutSession: GqlStripeCheckoutSession;
  stripeCreateSubscriptionCheckoutSession: GqlStripeCheckoutSession;
  stripeCreateSubscriptionQuote: GqlStripeQuote;
  stripeDeletePaymentMethod: GqlStripePaymentMethod;
  stripeEstablishInvitePromo: GqlStripePromotionCode;
  stripePayInvoice: GqlStripeInvoice;
  stripePreviewSubUpdate: GqlStripeUpcomingInvoice;
  stripeQuoteSetPromo: GqlStripeQuote;
  stripeSetDefaultPaymentMethod: GqlStripeCustomer;
  stripeUpgradeSubscription: GqlStripeSubscription;
  todoSetDone: GqlTodo;
  updateAid: GqlAgendaItemDecision;
  updateAuthorization: GqlAuthorization;
  updateCapTable: GqlCapTable;
  updateIdentity: GqlIdentity;
  updateMeetingParticipantShare: GqlMeetingParticipantShareUpdatedResponse;
  updateTodo: GqlTodo;
  userSetContactDetails: GqlUser;
  userSyncToHubspot: GqlUser;
  wpAddShareClass: GqlWarrantProgram;
  wpConnectMeeting: GqlWpConnectMeetingResponse;
  wpCreateMeeting: GqlWpConnectMeetingResponse;
  wpDeleteShareClass: GqlWarrantProgram;
  wpDisconnectAuthorization: GqlWpDisconnectAuthorizationResponse;
  wpDisconnectMeeting: GqlWpDisconnectMeetingResponse;
  wpEditShareClass: GqlWarrantProgram;
  wpRefreshInterestRate: GqlWarrantProgram;
  wpSetBoardsStatement: GqlWpSetBoardsStatementResponse;
  wpSetDates: GqlWarrantProgram;
  wpSetDescription: GqlWarrantProgram;
  wpSetDuration: GqlWarrantProgram;
  wpSetLanguage: GqlWarrantProgram;
  wpSetMaxNumberOfOptions: GqlWarrantProgram;
  wpSetNicePrice: GqlWarrantProgram;
  wpSetNonCashStatement: GqlWarrantProgram;
  wpSetNumberOfShares: GqlWarrantProgram;
  wpSetPaymentKind: GqlWpSetPaymentKindResponse;
  wpSetPlayground: GqlWarrantProgram;
  wpSetReason: GqlWarrantProgram;
  wpSetStrikePrice: GqlWarrantProgram;
  wpSetValuation: GqlWarrantProgram;
  wpSetVolatility: GqlWarrantProgram;
  wpSetWiseFullyDiluted: GqlWdbResetResponse;
  wpSetWisePrice: GqlWarrantProgram;
  wpSetWiseRecordDay: GqlWdbResetResponse;
  wpaSetDuration: GqlWarrantProgram;
  wpaSetStrikePrice: GqlWarrantProgram;
  wppAddCustomVestingOccasion: GqlWarrantParticipant;
  wppCreate: GqlWarrantParticipant;
  wppDelete: GqlWppDeletedResponse;
  wppDeleteCustomVestingOccasion: GqlWarrantParticipant;
  wppUpdate: GqlWppUpdateResponse;
  wppUpdateCustomVestingOccasion: GqlWarrantParticipant;
  wshImportShareState: GqlWshImportShareStateResponse;
  wshRemoveProxy: GqlWshUpdateResponse;
  wshSetProxy: GqlWshUpdateResponse;
}


export interface GqlMutationAgConnectMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAgConnectMeetingInput;
}


export interface GqlMutationAgCreateMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAgCreateMeetingInput;
}


export interface GqlMutationAgDisconnectMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAgDisconnectMeetingInput;
}


export interface GqlMutationAgSetNoBoardStatementArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAgSetNoBoardStatementInput;
}


export interface GqlMutationAgSetSnrAuthIdArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAgSetSnrAuthIdInput;
}


export interface GqlMutationAgSetSnrCaseIdArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAgSetSnrCaseIdInput;
}


export interface GqlMutationAgSetValidThruArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAgSetValidThruInput;
}


export interface GqlMutationAgUpdateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAgUpdateInput;
}


export interface GqlMutationAoaConnectMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaConnectMeetingInput;
}


export interface GqlMutationAoaCreateMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaCreateMeetingInput;
}


export interface GqlMutationAoaDisconnectMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaDisconnectMeetingInput;
}


export interface GqlMutationAoaSetArbitratorArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaSetArbitratorInput;
}


export interface GqlMutationAoaSetAuditorsLimitArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaSetAuditorsLimitInput;
}


export interface GqlMutationAoaSetBusinessArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaSetBusinessInput;
}


export interface GqlMutationAoaSetConsentArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaSetConsentInput;
}


export interface GqlMutationAoaSetMajorityArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaSetMajorityInput;
}


export interface GqlMutationAoaSetPostTransferPurchaseRightArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaSetPostTransferPurchaseRightInput;
}


export interface GqlMutationAoaSetRightOfFirstRefusalArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaSetRightOfFirstRefusalInput;
}


export interface GqlMutationAoaSetShareChangeArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaSetShareChangeInput;
}


export interface GqlMutationAoaSetShareLimitsArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaSetShareLimitsInput;
}


export interface GqlMutationAoaSetSummonsArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaSetSummonsInput;
}


export interface GqlMutationAoaToggleShareChangeArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationAoaUpdateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaUpdateInput;
}


export interface GqlMutationCapTableStakeDeleteArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCapTableStakeDeleteInput;
}


export interface GqlMutationCapTableStakeSetEnabledArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCapTableStakeSetEnabledInput;
}


export interface GqlMutationCapTableStakeUpdateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCapTableStakeUpdateInput;
}


export interface GqlMutationCdSetInvitedArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCdSetInvitedInput;
}


export interface GqlMutationCiSetAcceptedByCompanyArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCiSetAcceptedByCompanyInput;
}


export interface GqlMutationCiSetAcceptedByUserArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCiSetAcceptedByUserInput;
}


export interface GqlMutationCmpCreateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCmpCreateInput;
}


export interface GqlMutationCmpDeleteArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationCmpSetInvitedArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCmpSetInvitedInput;
}


export interface GqlMutationCompanyDeleteArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlMutationCompanyProvisionArgs {
  input: GqlCompanyProvisionInput;
}


export interface GqlMutationCompanySetMeetingFrequencyArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCompanySetMeetingFrequencyInput;
}


export interface GqlMutationCompanySetProfileArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCompanySetProfileInput;
}


export interface GqlMutationCompanySetSharesAtFoundationArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCompanySetSharesAtFoundationInput;
}


export interface GqlMutationCompanySyncToHubspotArgs {
  id: Scalars['ID']['input'];
}


export interface GqlMutationCreateAiArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAiCreateInput;
}


export interface GqlMutationCreateAidArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAidCreateInput;
}


export interface GqlMutationCreateArticlesOfAssocArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAoaCreateInput;
}


export interface GqlMutationCreateAuthorizationArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAuthCreateInput;
}


export interface GqlMutationCreateAuthorizationGroupArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlMutationCreateCapTableArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCreateCapTableInput;
}


export interface GqlMutationCreateCapTableStakeArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCreateCapTableStakeInput;
}


export interface GqlMutationCreateCapTableStakesArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCreateCapTableStakesInput;
}


export interface GqlMutationCreateCompanyInviteArgs {
  input: GqlCiCreateInput;
}


export interface GqlMutationCreateCompanyUserArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCuCreateInput;
}


export interface GqlMutationCreateDocumentSignatureArgs {
  companyId: Scalars['ID']['input'];
  input: GqlDsCreateInput;
}


export interface GqlMutationCreateIdentityArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCreateIdentityInput;
}


export interface GqlMutationCreateMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMeetingCreateInput;
}


export interface GqlMutationCreateMeetingParticipantArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMpCreateInput;
}


export interface GqlMutationCreateMeetingParticipantShareArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMeetingParticipantShareCreateInput;
}


export interface GqlMutationCreateMeetingTodoArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMtCreateInput;
}


export interface GqlMutationCreateNiceFounderArgs {
  companyId: Scalars['ID']['input'];
  input: GqlNiceFounderCreateInput;
}


export interface GqlMutationCreateOptionsRegistrationArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOrCreateInput;
}


export interface GqlMutationCreateOptionsSharesRegistrationArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOsrCreateInput;
}


export interface GqlMutationCreateOptionsSharesSubscriberRepresentativeArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOptionsSharesSubscriberRepresentativeCreateInput;
}


export interface GqlMutationCreateOptionsSubscriberRepresentativeArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOptionsSubscriberRepresentativeCreateInput;
}


export interface GqlMutationCreateShareIssueArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiCreateInput;
}


export interface GqlMutationCreateShareIssueBoardMemberArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSibmCreateInput;
}


export interface GqlMutationCreateShareIssueParticipantArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSipCreateInput;
}


export interface GqlMutationCreateShareIssueRegistrationArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSirCreateInput;
}


export interface GqlMutationCreateShareTransactionGroupArgs {
  companyId: Scalars['ID']['input'];
  input: GqlStgCreateInput;
}


export interface GqlMutationCreateShareholderArgs {
  companyId: Scalars['ID']['input'];
  input: GqlShCreateInput;
}


export interface GqlMutationCreateWarrantBoardMemberArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpbmCreateInput;
}


export interface GqlMutationCreateWarrantProgramArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpCreateInput;
}


export interface GqlMutationCreateWarrantShareholderArgs {
  input: GqlWshCreateInput;
}


export interface GqlMutationCreateWdbFromBondArgs {
  companyId: Scalars['ID']['input'];
  snrBondId: Scalars['ID']['input'];
  warrantProgramId: Scalars['ID']['input'];
}


export interface GqlMutationCreateWiseDepBondArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWdbCreateInput;
}


export interface GqlMutationCreateWpFromSnrBondArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpFromSnrBondInput;
}


export interface GqlMutationDeleteAiArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteAidArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteArticlesOfAssocArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteAuthorizationArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteAuthorizationGroupArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteCapTableArgs {
  companyId: Scalars['ID']['input'];
  input: Scalars['ID']['input'];
}


export interface GqlMutationDeleteCompanyInviteArgs {
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteCompanyUserArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteDocumentArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteDocumentSignatureArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteEmptyShareTransactionGroupArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteMeetingArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteMeetingParticipantArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteMeetingParticipantShareArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteMeetingShareholdersArgs {
  companyId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}


export interface GqlMutationDeleteMeetingTodoArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteNiceFounderArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteOptionsSharesSubscriberRepresentativeArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteOptionsSubscriberRepresentativeArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeletePasskeyArgs {
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteShareIssueArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteShareIssueBoardMemberArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteShareIssueParticipantArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteShareTransactionGroupArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteShareTransactionsArgs {
  companyId: Scalars['ID']['input'];
  input: GqlDeleteStsInput;
}


export interface GqlMutationDeleteShareholderArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteWarrantBoardMemberArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteWarrantProgramArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDeleteWarrantShareholderArgs {
  id: Scalars['String']['input'];
}


export interface GqlMutationDeleteWiseDependencyBondArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDocGenAgRegistrationFormArgs {
  agId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenAoaRegistrationFormArgs {
  aoaId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenConvertibleRegistrationFormArgs {
  companyId: Scalars['ID']['input'];
  optionsRegistrationId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenIndividualReportArgs {
  companyId: Scalars['ID']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
  docLanguage: GqlDocumentLanguageInput;
  shareholderId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenIndividualReportShArgs {
  companyId: Scalars['ID']['input'];
  docLanguage: GqlDocumentLanguageInput;
  shareholderId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenNiceAgreementArgs {
  companyId: Scalars['ID']['input'];
  signatureMethod: GqlDocumentSignatureMethod;
  warrantParticipantId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenOptionsShareSubscriptionListArgs {
  companyId: Scalars['ID']['input'];
  optionsSharesSubscriberId: Scalars['ID']['input'];
  signatureMethod: GqlDocumentSignatureMethod;
}


export interface GqlMutationDocGenPowerOfAttorneyArgs {
  companyId: Scalars['ID']['input'];
  meetingParticipantId: Scalars['ID']['input'];
  signatureMethod: GqlDocumentSignatureMethod;
}


export interface GqlMutationDocGenQesoAgreementArgs {
  companyId: Scalars['ID']['input'];
  signatureMethod: GqlDocumentSignatureMethod;
  warrantParticipantId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenScheduleDocGenArgs {
  companyId: Scalars['ID']['input'];
  input: GqlDocumentGenInput;
}


export interface GqlMutationDocGenShareCertificateArgs {
  blockNumber: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  docLanguage: GqlDocumentLanguageInput;
  shareholderId: Scalars['ID']['input'];
  transactionId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenShareSubscriptionListArgs {
  companyId: Scalars['ID']['input'];
  shareIssueParticipantId: Scalars['ID']['input'];
  signatureMethod: GqlDocumentSignatureMethod;
}


export interface GqlMutationDocGenSiRegistrationFormArgs {
  companyId: Scalars['ID']['input'];
  sirId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenTransactionNoteArgs {
  companyId: Scalars['ID']['input'];
  docLanguage: GqlDocumentLanguageInput;
  shareTransactionId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenTransactionNoteShArgs {
  companyId: Scalars['ID']['input'];
  docLanguage: GqlDocumentLanguageInput;
  shareTransactionId: Scalars['ID']['input'];
  shareholderId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenWarrantAgreementArgs {
  companyId: Scalars['ID']['input'];
  signatureMethod: GqlDocumentSignatureMethod;
  warrantParticipantId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenWarrantRegistrationFormArgs {
  companyId: Scalars['ID']['input'];
  optionsRegistrationId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenWarrantSharesRegistrationFormArgs {
  companyId: Scalars['ID']['input'];
  optionsSharesRegistrationId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenWiseAgreementArgs {
  companyId: Scalars['ID']['input'];
  signatureMethod: GqlDocumentSignatureMethod;
  warrantParticipantId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenerateMeetingMinutesArgs {
  companyId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
  signatureMethod: GqlDocumentSignatureMethod;
}


export interface GqlMutationDocGenerateMeetingSummonsArgs {
  companyId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}


export interface GqlMutationDocGenerateMeetingSummonsDirectArgs {
  companyId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}


export interface GqlMutationDocSendSignReminderArgs {
  companyId: Scalars['ID']['input'];
  input: GqlDocSendSignReminderInput;
}


export interface GqlMutationDocSetTagsArgs {
  companyId: Scalars['ID']['input'];
  input: GqlDocSetTagsInput;
}


export interface GqlMutationDocSignRefreshArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDocSignStartArgs {
  companyId: Scalars['ID']['input'];
  input: GqlDocSignStartInput;
}


export interface GqlMutationDocSignStopArgs {
  companyId: Scalars['ID']['input'];
  input: GqlDocSignStopInput;
}


export interface GqlMutationDocSoftDeleteArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationDsSetAuthMethodToSignArgs {
  companyId: Scalars['ID']['input'];
  input: GqlDsSetAuthMethodToSignInput;
}


export interface GqlMutationDsSetEmailAndMobileArgs {
  companyId: Scalars['ID']['input'];
  input: GqlDsSetEmailAndMobileInput;
}


export interface GqlMutationDsSetProxyArgs {
  companyId: Scalars['ID']['input'];
  input: GqlDsSetProxyInput;
}


export interface GqlMutationDuplicateCapTableArgs {
  companyId: Scalars['ID']['input'];
  input: Scalars['ID']['input'];
}


export interface GqlMutationEditWiseDependencyBondArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWdbEditInput;
}


export interface GqlMutationMatCreateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMatCreateInput;
}


export interface GqlMutationMatDeleteArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationMatSetIsDefaultArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMatSetIsDefaultInput;
}


export interface GqlMutationMatSetNameArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMatSetNameInput;
}


export interface GqlMutationMatiCreateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMatiCreateInput;
}


export interface GqlMutationMatiDeleteArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationMatiSetOrderArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMatiSetOrderInput;
}


export interface GqlMutationMatiUpdateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMatiUpdateInput;
}


export interface GqlMutationMeetingSetFunctionsArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMeetingSetFunctionsInput;
}


export interface GqlMutationMpImportShareStateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMpImportShareStateInput;
}


export interface GqlMutationMpSetFunctionsArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMpSetFunctionsInput;
}


export interface GqlMutationMpSetPresentArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMpSetPresentInput;
}


export interface GqlMutationMpSetProxyArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMpSetProxyInput;
}


export interface GqlMutationMtSetDoneArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMtSetDoneInput;
}


export interface GqlMutationNiceFounderImportFoundersArgs {
  companyId: Scalars['ID']['input'];
  input: GqlNiceFounderImportFoundersInput;
}


export interface GqlMutationOrDeleteArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationOrSetPayBeforeDateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOrSetPayBeforeDateInput;
}


export interface GqlMutationOrSetPaymentArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOrSetPaymentInput;
}


export interface GqlMutationOrSetSnrCaseArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOrSetSnrCaseInput;
}


export interface GqlMutationOrSignatoryCreateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOrSignatoryCreateInput;
}


export interface GqlMutationOrSignatoryDeleteArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationOrUpdateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOrUpdateInput;
}


export interface GqlMutationOsrDeleteArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationOsrSetPayBeforeDateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOsrSetPayBeforeDateInput;
}


export interface GqlMutationOsrSetPaymentArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOsrSetPaymentInput;
}


export interface GqlMutationOsrSetRecordDayArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOsrSetRecordDayInput;
}


export interface GqlMutationOsrSetRegDetailsArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOsrSetDetailsInput;
}


export interface GqlMutationOsrSetSnrCaseArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOsrSetSnrCaseInput;
}


export interface GqlMutationOsrUpdateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOsrUpdateInput;
}


export interface GqlMutationOssCreateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOssCreateInput;
}


export interface GqlMutationOssDeleteArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationOssSetPaymentStatusArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOssSetPaymentStatusInput;
}


export interface GqlMutationOssUpdateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlOssUpdateInput;
}


export interface GqlMutationRefreshCapTableArgs {
  companyId: Scalars['ID']['input'];
  input: GqlRefreshCapTableInput;
}


export interface GqlMutationRefreshIdentityArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationResetAisArgs {
  companyId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}


export interface GqlMutationSendMailMeetingSummonsToAllArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSendMailMeetingSummonsToAllInput;
}


export interface GqlMutationSendMailMeetingSummonsToOneArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSendMailMeetingSummonsToOneInput;
}


export interface GqlMutationSendPromoInviteArgs {
  input: GqlUserSendPromoInviteInput;
}


export interface GqlMutationSetAiDoneArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAiSetDoneInput;
}


export interface GqlMutationSetAiDoneWithApprovableMeetingsArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAiSetDoneWithApprovableMeetingsInput;
}


export interface GqlMutationSetAiDoneWithMcsArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAiSetDoneWithMcsInput;
}


export interface GqlMutationSetAiDoneWithNextMeetingsArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAiSetDoneWithNextMeetingsInput;
}


export interface GqlMutationSetAiOrderArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAiSetOrderInput;
}


export interface GqlMutationSetIdentityEmailArgs {
  companyId: Scalars['ID']['input'];
  input: GqlIdentitySetEmailInput;
}


export interface GqlMutationSetIdentityPhoneArgs {
  companyId: Scalars['ID']['input'];
  input: GqlIdentitySetPhoneInput;
}


export interface GqlMutationSetMcApprovedArgs {
  input: GqlMcsSetApprovedInput;
}


export interface GqlMutationSetMeetingBoardsRecommendationArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMeetingBoardsRecommendationInput;
}


export interface GqlMutationSetMeetingContactEmailArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMeetingSetContactEmailInput;
}


export interface GqlMutationSetMeetingDetailsArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMeetingSetDetailsInput;
}


export interface GqlMutationSetMeetingLanguageArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMeetingSetLanguageInput;
}


export interface GqlMutationSetMeetingPostalVoteArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMeetingPostalVoteInput;
}


export interface GqlMutationSiAddShareClassArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiAddShareClassInput;
}


export interface GqlMutationSiConnectAuthorizationArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiConnectAuthorizationInput;
}


export interface GqlMutationSiConnectMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiConnectMeetingInput;
}


export interface GqlMutationSiCreateMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiCreateMeetingInput;
}


export interface GqlMutationSiDeleteShareClassArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiDeleteShareClassInput;
}


export interface GqlMutationSiDisconnectAuthorizationArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiDisconnectAuthorizationInput;
}


export interface GqlMutationSiDisconnectMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiDisconnectMeetingInput;
}


export interface GqlMutationSiEditShareClassArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiEditShareClassInput;
}


export interface GqlMutationSiSetBoardStatementArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiSetBoardsStatementInput;
}


export interface GqlMutationSiSetDescriptionArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiSetDescriptionInput;
}


export interface GqlMutationSiSetLanguageArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiSetLanguageInput;
}


export interface GqlMutationSiSetNewSharesArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiSetNewSharesInput;
}


export interface GqlMutationSiSetNonCashStatementArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiSetNonCashStatementInput;
}


export interface GqlMutationSiSetPaymentKindArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiSetPaymentKindInput;
}


export interface GqlMutationSiSetSubscriptionDatesArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiSetSubscriptionDatesInput;
}


export interface GqlMutationSiUpdateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiUpdateInput;
}


export interface GqlMutationSipEditArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSipEditInput;
}


export interface GqlMutationSipSetNumberOfSharesArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSipSetNumberOfSharesInput;
}


export interface GqlMutationSipSetPaymentKindArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSipSetPaymentKindInput;
}


export interface GqlMutationSipSetPaymentStatusArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSipSetPaymentStatusInput;
}


export interface GqlMutationSipSetProxyArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSipSetProxyInput;
}


export interface GqlMutationSirSetCurrencyExchangeRateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSirSetCurrencyExchangeRateInput;
}


export interface GqlMutationSirSetPaymentArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSirSetPaymentInput;
}


export interface GqlMutationSirSetPaymentDueDateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSirSetPaymentDueDateInput;
}


export interface GqlMutationSirSetSnrCaseArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSirSetSnrCaseInput;
}


export interface GqlMutationSirUpdateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSirUpdateInput;
}


export interface GqlMutationStCreateIssueArgs {
  companyId: Scalars['ID']['input'];
  input: GqlStCreateIssueInput;
}


export interface GqlMutationStCreateTransferArgs {
  companyId: Scalars['ID']['input'];
  input: GqlStCreateTransferInput;
}


export interface GqlMutationStCreateUnissueArgs {
  companyId: Scalars['ID']['input'];
  input: GqlStCreateUnissueInput;
}


export interface GqlMutationStSetCommentArgs {
  companyId: Scalars['ID']['input'];
  input: GqlStSetCommentInput;
}


export interface GqlMutationStSetPricePerShareArgs {
  companyId: Scalars['ID']['input'];
  input: GqlStSetPricePerShareInput;
}


export interface GqlMutationStSetShareCertificateDateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlStSetShareCertificateDateInput;
}


export interface GqlMutationStgSetSccIdArgs {
  companyId: Scalars['ID']['input'];
  input: GqlStgSetSccIdInput;
}


export interface GqlMutationStgSetupArgs {
  companyId: Scalars['ID']['input'];
  input: GqlStgSetupInput;
}


export interface GqlMutationStripeAcceptQuoteArgs {
  companyId: Scalars['ID']['input'];
  pmId?: InputMaybe<Scalars['String']['input']>;
  quoteId: Scalars['String']['input'];
}


export interface GqlMutationStripeCreateBillingPortalSessionArgs {
  companyId: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
}


export interface GqlMutationStripeCreateCustomerArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlMutationStripeCreateMeetingConcernsPaymentQuoteArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCreateStripeMeetingConcernQuoteInput;
}


export interface GqlMutationStripeCreateSetupCheckoutSessionArgs {
  companyId: Scalars['ID']['input'];
  input: GqlStripeCreateSetupCheckoutSessionInput;
}


export interface GqlMutationStripeCreateSubscriptionCheckoutSessionArgs {
  companyId: Scalars['ID']['input'];
  input: GqlStripeCreateSubscriptionCheckoutSessionInput;
}


export interface GqlMutationStripeCreateSubscriptionQuoteArgs {
  companyId: Scalars['ID']['input'];
  input: GqlCreateStripeSubscriptionQuoteInput;
}


export interface GqlMutationStripeDeletePaymentMethodArgs {
  companyId: Scalars['ID']['input'];
  pmId: Scalars['ID']['input'];
}


export interface GqlMutationStripeEstablishInvitePromoArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlMutationStripePayInvoiceArgs {
  companyId: Scalars['ID']['input'];
  invoiceId: Scalars['String']['input'];
  pmId?: InputMaybe<Scalars['String']['input']>;
}


export interface GqlMutationStripePreviewSubUpdateArgs {
  companyId: Scalars['ID']['input'];
  priceId: Scalars['ID']['input'];
}


export interface GqlMutationStripeQuoteSetPromoArgs {
  companyId: Scalars['ID']['input'];
  input: GqlStripeQuoteSetPromoInput;
}


export interface GqlMutationStripeSetDefaultPaymentMethodArgs {
  companyId: Scalars['ID']['input'];
  pmId: Scalars['ID']['input'];
}


export interface GqlMutationStripeUpgradeSubscriptionArgs {
  companyId: Scalars['ID']['input'];
  priceId: Scalars['ID']['input'];
}


export interface GqlMutationTodoSetDoneArgs {
  companyId: Scalars['ID']['input'];
  input: GqlTodoSetDoneInput;
}


export interface GqlMutationUpdateAidArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAidUpdateInput;
}


export interface GqlMutationUpdateAuthorizationArgs {
  companyId: Scalars['ID']['input'];
  input: GqlAuthUpdateInput;
}


export interface GqlMutationUpdateCapTableArgs {
  companyId: Scalars['ID']['input'];
  input: GqlUpdateCapTableInput;
}


export interface GqlMutationUpdateIdentityArgs {
  companyId: Scalars['ID']['input'];
  input: GqlUpdateIdentityInput;
}


export interface GqlMutationUpdateMeetingParticipantShareArgs {
  companyId: Scalars['ID']['input'];
  input: GqlMeetingParticipantShareUpdateInput;
}


export interface GqlMutationUpdateTodoArgs {
  companyId: Scalars['ID']['input'];
  input: GqlTodoUpdateInput;
}


export interface GqlMutationUserSetContactDetailsArgs {
  input: GqlUserSetContactDetailsInput;
}


export interface GqlMutationUserSyncToHubspotArgs {
  id: Scalars['ID']['input'];
}


export interface GqlMutationWpAddShareClassArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpAddShareClassInput;
}


export interface GqlMutationWpConnectMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpConnectMeetingInput;
}


export interface GqlMutationWpCreateMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpCreateMeetingInput;
}


export interface GqlMutationWpDeleteShareClassArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpDeleteShareClassInput;
}


export interface GqlMutationWpDisconnectAuthorizationArgs {
  input: GqlWpDisconnectAuthorizationInput;
}


export interface GqlMutationWpDisconnectMeetingArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpDisconnectMeetingInput;
}


export interface GqlMutationWpEditShareClassArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpEditShareClassInput;
}


export interface GqlMutationWpRefreshInterestRateArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlMutationWpSetBoardsStatementArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetBoardsStatementInput;
}


export interface GqlMutationWpSetDatesArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetDatesInput;
}


export interface GqlMutationWpSetDescriptionArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetDescriptionInput;
}


export interface GqlMutationWpSetDurationArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetDurationInput;
}


export interface GqlMutationWpSetLanguageArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetLanguageInput;
}


export interface GqlMutationWpSetMaxNumberOfOptionsArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetMaxNumberOfOptionsInput;
}


export interface GqlMutationWpSetNicePriceArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetNicePriceInput;
}


export interface GqlMutationWpSetNonCashStatementArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetNonCashStatementInput;
}


export interface GqlMutationWpSetNumberOfSharesArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetNumberOfSharesInput;
}


export interface GqlMutationWpSetPaymentKindArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetPaymentKindInput;
  userId: Scalars['ID']['input'];
}


export interface GqlMutationWpSetPlaygroundArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetPlaygroundInput;
}


export interface GqlMutationWpSetReasonArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetReasonInput;
}


export interface GqlMutationWpSetStrikePriceArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetStrikePriceInput;
}


export interface GqlMutationWpSetValuationArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetValuationInput;
}


export interface GqlMutationWpSetVolatilityArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetVolatilityInput;
}


export interface GqlMutationWpSetWiseFullyDilutedArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetWiseFullyDilutedInput;
}


export interface GqlMutationWpSetWisePriceArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetWisePriceInput;
}


export interface GqlMutationWpSetWiseRecordDayArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetWiseRecordDayInput;
}


export interface GqlMutationWpaSetDurationArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpaSetDurationInput;
}


export interface GqlMutationWpaSetStrikePriceArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpSetStrikePriceInput;
}


export interface GqlMutationWppAddCustomVestingOccasionArgs {
  vestingOccasion: GqlWppVestingOccasionCreateInput;
}


export interface GqlMutationWppCreateArgs {
  companyId: Scalars['String']['input'];
  input: GqlWppCreateInput;
}


export interface GqlMutationWppDeleteArgs {
  companyId: Scalars['String']['input'];
  id: Scalars['String']['input'];
}


export interface GqlMutationWppDeleteCustomVestingOccasionArgs {
  vestingOccasionId: Scalars['ID']['input'];
}


export interface GqlMutationWppUpdateArgs {
  companyId: Scalars['String']['input'];
  input: GqlWppUpdateInput;
}


export interface GqlMutationWppUpdateCustomVestingOccasionArgs {
  vestingOccasion: GqlWppVestingOccasionUpdateInput;
}


export interface GqlMutationWshImportShareStateArgs {
  input: GqlWshImportShareStateInput;
}


export interface GqlMutationWshRemoveProxyArgs {
  id: Scalars['String']['input'];
}


export interface GqlMutationWshSetProxyArgs {
  input: GqlWshSetProxyInput;
}

export interface GqlNextShareCapitalChanges {
  inSync: Scalars['Boolean']['output'];
  next?: Maybe<GqlCompleteShareCapitalChange>;
  prev?: Maybe<GqlCompleteShareCapitalChange>;
}

export interface GqlNiceFounder {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['Date']['output'];
  warrantProgram: GqlWarrantProgram;
  warrantProgramId: Scalars['ID']['output'];
}

export interface GqlNiceFounderCreateInput {
  identityInput: GqlCreateIdentityInput;
  warrantProgramId: Scalars['ID']['input'];
}

export interface GqlNiceFounderCreatedResponse {
  documents: Array<GqlDocument>;
  niceFounder: GqlNiceFounder;
}

export interface GqlNiceFounderDeletedResponse {
  documents: Array<GqlDocument>;
  id: Scalars['ID']['output'];
}

export interface GqlNiceFounderImportFoundersInput {
  warrantProgramId: Scalars['ID']['input'];
}

export interface GqlNiceFounderImportFoundersResponse {
  added: Array<GqlNiceFounder>;
  documents: Array<GqlDocument>;
  removed: Array<GqlNiceFounderDeletedResponse>;
}

export interface GqlOptionalAisAidCreate {
  decisionPrimaryLang?: Maybe<Scalars['String']['output']>;
  decisionSecondaryLang?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
}

export interface GqlOptionsRegistration {
  contact?: Maybe<GqlIdentity>;
  contactId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['ID']['output']>;
  ibanAddress?: Maybe<Scalars['String']['output']>;
  ibanBank?: Maybe<Scalars['String']['output']>;
  ibanBic?: Maybe<Scalars['String']['output']>;
  ibanNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latestRegistrationForm?: Maybe<GqlDocument>;
  orSignatories: Array<GqlOptionsRegistrationSignatory>;
  participants: Array<GqlWarrantParticipant>;
  payBeforeDate?: Maybe<Scalars['Date']['output']>;
  paymentAccountKind?: Maybe<Scalars['Int']['output']>;
  paymentAccountNumber?: Maybe<Scalars['String']['output']>;
  paymentName?: Maybe<Scalars['String']['output']>;
  regFormPaymentThroughBolago: Scalars['Boolean']['output'];
  signatory?: Maybe<GqlIdentity>;
  signatoryId?: Maybe<Scalars['ID']['output']>;
  snrCaseId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['Date']['output'];
  warrantProgram: GqlWarrantProgram;
  warrantProgramId: Scalars['ID']['output'];
}

export interface GqlOptionsRegistrationDocument {
  createdAt: Scalars['Date']['output'];
  document: GqlDocument;
  documentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  optionsRegistrationId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlOptionsRegistrationSignatory {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId: Scalars['ID']['output'];
  optionsRegistration: GqlOptionsRegistration;
  optionsRegistrationId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlOptionsSharesRegistration {
  contact?: Maybe<GqlIdentity>;
  contactId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  ibanAddress?: Maybe<Scalars['String']['output']>;
  ibanBank?: Maybe<Scalars['String']['output']>;
  ibanBic?: Maybe<Scalars['String']['output']>;
  ibanNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latestRegistrationForm?: Maybe<GqlDocument>;
  payBeforeDate?: Maybe<Scalars['Date']['output']>;
  paymentAccountKind?: Maybe<Scalars['Int']['output']>;
  paymentAccountNumber?: Maybe<Scalars['String']['output']>;
  paymentName?: Maybe<Scalars['String']['output']>;
  recordDay?: Maybe<Scalars['Date']['output']>;
  regFormPaymentThroughBolago: Scalars['Boolean']['output'];
  signatory?: Maybe<GqlIdentity>;
  signatoryId?: Maybe<Scalars['ID']['output']>;
  snrCaseId?: Maybe<Scalars['ID']['output']>;
  splitFactor: Scalars['String']['output'];
  subscribers: Array<GqlOptionsSharesSubscriber>;
  updatedAt: Scalars['Date']['output'];
  warrantProgram: GqlWarrantProgram;
  warrantProgramId: Scalars['ID']['output'];
}

export interface GqlOptionsSharesSubscriber {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId: Scalars['ID']['output'];
  latestShareSubscriptionListDoc?: Maybe<GqlDocument>;
  optionsSharesRegistration: GqlOptionsSharesRegistration;
  optionsSharesRegistrationId: Scalars['ID']['output'];
  paymentStatus: Scalars['Int']['output'];
  quantity: Scalars['String']['output'];
  representatives: Array<GqlOptionsSharesSubscriberRepresentative>;
  shareClass: GqlShareClassObject;
  updatedAt: Scalars['Date']['output'];
}

export interface GqlOptionsSharesSubscriberRepresentative {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId: Scalars['ID']['output'];
  optionsSharesSubscriber: GqlOptionsSharesSubscriber;
  optionsSharesSubscriberId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlOptionsSharesSubscriberRepresentativeCreateInput {
  identityInput: GqlCreateIdentityInput;
  optionsSharesSubscriberId: Scalars['ID']['input'];
}

export interface GqlOptionsSharesSubscriberRepresentativeDeletedResponse {
  id: Scalars['ID']['output'];
}

export interface GqlOptionsSubscriberRepresentative {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  warrantParticipant: GqlWarrantParticipant;
  warrantParticipantId: Scalars['ID']['output'];
}

/** Create share issue participant */
export interface GqlOptionsSubscriberRepresentativeCreateInput {
  identityInput: GqlCreateIdentityInput;
  warrantParticipantId: Scalars['ID']['input'];
}

export interface GqlOptionsSubscriberRepresentativeDeletedResponse {
  id: Scalars['ID']['output'];
}

export interface GqlOrCreateInput {
  description: Scalars['String']['input'];
  warrantProgramId: Scalars['ID']['input'];
}

export interface GqlOrDeletedResponse {
  id: Scalars['ID']['output'];
  wppDeletedResponses: Array<GqlWppDeletedResponse>;
}

export interface GqlOrSetPayBeforeDateInput {
  id: Scalars['ID']['input'];
  payBeforeDate: Scalars['Date']['input'];
}

export interface GqlOrSetPaymentInput {
  ibanAddress?: InputMaybe<Scalars['String']['input']>;
  ibanBank?: InputMaybe<Scalars['String']['input']>;
  ibanBic?: InputMaybe<Scalars['String']['input']>;
  ibanNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  paymentAccountKind?: InputMaybe<Scalars['Int']['input']>;
  paymentAccountNumber?: InputMaybe<Scalars['String']['input']>;
  paymentName?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlOrSetSnrCaseInput {
  id: Scalars['ID']['input'];
  snrCaseId?: InputMaybe<Scalars['ID']['input']>;
}

export interface GqlOrSignatoryCreateInput {
  identityInput: GqlCreateIdentityInput;
  optionsRegistrationId: Scalars['ID']['input'];
}

export interface GqlOrSignatoryCreateResponse {
  documents: Array<GqlDocument>;
  sig: GqlOptionsRegistrationSignatory;
}

export interface GqlOrSignatoryDeletedResponse {
  documents: Array<GqlDocument>;
  id: Scalars['ID']['output'];
}

export interface GqlOrUpdateInput {
  contact?: InputMaybe<GqlCreateIdentityInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  regFormPaymentThroughBolago?: InputMaybe<Scalars['Boolean']['input']>;
  signatory?: InputMaybe<GqlCreateIdentityInput>;
}

export interface GqlOrUpdateResponse {
  documents: Array<GqlDocument>;
  or: GqlOptionsRegistration;
}

export interface GqlOrValidatorErrors {
  decision: GqlValidatorStatus;
  options: GqlValidatorStatus;
  participants: GqlValidatorStatus;
  payBeforeDate: GqlValidatorStatus;
  signatories: GqlValidatorStatus;
  signsRegForm: GqlValidatorStatus;
}

export interface GqlOsrCreateInput {
  description: Scalars['String']['input'];
  warrantProgramId: Scalars['ID']['input'];
}

export interface GqlOsrDeletedResponse {
  id: Scalars['ID']['output'];
  ossDeletedResponses: Array<GqlOssDeletedResponse>;
}

export interface GqlOsrSetDetailsInput {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlOsrSetPayBeforeDateInput {
  id: Scalars['ID']['input'];
  payBeforeDate: Scalars['Date']['input'];
}

export interface GqlOsrSetPaymentInput {
  ibanAddress?: InputMaybe<Scalars['String']['input']>;
  ibanBank?: InputMaybe<Scalars['String']['input']>;
  ibanBic?: InputMaybe<Scalars['String']['input']>;
  ibanNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  paymentAccountKind?: InputMaybe<Scalars['Int']['input']>;
  paymentAccountNumber?: InputMaybe<Scalars['String']['input']>;
  paymentName?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlOsrSetRecordDayInput {
  id: Scalars['ID']['input'];
  recordDay: Scalars['Date']['input'];
}

export interface GqlOsrSetSnrCaseInput {
  id: Scalars['ID']['input'];
  snrCaseId?: InputMaybe<Scalars['ID']['input']>;
}

export interface GqlOsrUpdateInput {
  contact?: InputMaybe<GqlCreateIdentityInput>;
  id: Scalars['ID']['input'];
  regFormPaymentThroughBolago?: InputMaybe<Scalars['Boolean']['input']>;
  signatory?: InputMaybe<GqlCreateIdentityInput>;
}

export interface GqlOsrUpdateResponse {
  documents: Array<GqlDocument>;
  osr: GqlOptionsSharesRegistration;
}

export interface GqlOsrValidatorErrors {
  decision: GqlValidatorStatus;
  options: GqlValidatorStatus;
  participants: GqlValidatorStatus;
  payBeforeDate: GqlValidatorStatus;
  recordDay: GqlValidatorStatus;
  signsRegForm: GqlValidatorStatus;
}

export interface GqlOssCreateInput {
  identityInput: GqlCreateIdentityInput;
  optionsSharesRegistrationId: Scalars['ID']['input'];
  quantity: Scalars['String']['input'];
  shareClass: Scalars['JSONObject']['input'];
}

export interface GqlOssDeletedResponse {
  documents: Array<GqlDocument>;
  id: Scalars['ID']['output'];
}

export interface GqlOssSetPaymentStatusInput {
  id: Scalars['ID']['input'];
  paymentStatus: Scalars['Int']['input'];
}

export interface GqlOssUpdateInput {
  id: Scalars['ID']['input'];
  identityInput: GqlCreateIdentityInput;
  quantity?: InputMaybe<Scalars['String']['input']>;
  shareClass?: InputMaybe<Scalars['JSONObject']['input']>;
}

export interface GqlOssUpdateResponse {
  documents: Array<GqlDocument>;
  optionsSharesSubscriber: GqlOptionsSharesSubscriber;
}

export interface GqlOssValidatorErrors {
  paymentStatus: GqlValidatorStatus;
  proxy: GqlValidatorStatus;
}

export interface GqlOwnerStateResponse {
  averagePrice: Scalars['Float']['output'];
  numberOfShares: Scalars['Float']['output'];
  ranges: Array<GqlShareRange>;
  totalPaid: Scalars['Float']['output'];
}

export interface GqlPasskey {
  attestationType: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  signCount: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
  userId: Scalars['String']['output'];
}

export interface GqlPasskeyDeletedResponse {
  id: Scalars['ID']['output'];
}

export interface GqlProvisionedCompany {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface GqlQuery {
  activeCompanySubscription: GqlSutSubscription;
  adminCompanies: Array<GqlCompany>;
  adminScriveDocuments: Array<GqlDocument>;
  adminShareholders: Array<GqlShareholder>;
  adminUsers: Array<GqlUser>;
  agendaItems: Array<GqlAgendaItem>;
  aoaGetAffected: GqlAoaGetAffectedResponse;
  aoaMinAndMaxOfShares: GqlMinAndMaxOfSharesResponse;
  articlesOfAssoc: GqlArticlesOfAssoc;
  articlesOfAssocDocument: GqlArticlesOfAssocDocument;
  articlesOfAssocErrors: GqlAoaValidatorErrors;
  articlesOfAssocs: Array<GqlArticlesOfAssoc>;
  authorizationGroup: GqlAuthorizationGroup;
  authorizationGroupDocument: GqlAuthorizationGroupDocument;
  authorizationGroupErrors: GqlAgValidatorErrors;
  authorizationGroups: Array<GqlAuthorizationGroup>;
  capTable: GqlCapTable;
  capTableStake: GqlCapTableStake;
  capTableStakes: Array<GqlCapTableStake>;
  capTables: Array<GqlCapTable>;
  companies: Array<GqlCompany>;
  company: GqlCompany;
  companyConnections: Array<GqlCompanyConnection>;
  companyDirectors: Array<GqlCompanyDirector>;
  companyInvites: Array<GqlCompanyInvite>;
  companySubscriptions: Array<GqlCompanySubscription>;
  companySuggestions: Array<GqlCompanySuggestion>;
  companyUsers: Array<GqlCompanyUser>;
  cooptedMeetingParticipants: Array<GqlCooptedMeetingParticipant>;
  currentUser: GqlUser;
  dashboardMilestones: GqlDashboardMilestonesResponse;
  docGenValidateMeeting: GqlDocGenMeetingValidatorErrorsResponse;
  document: GqlDocument;
  documentSignatures: Array<GqlDocumentSignature>;
  documentUserTags: GqlDocumentUserTagsResponse;
  documents: GqlDocumentsPagedResult;
  documentsByIds: Array<GqlDocument>;
  employeeOptionsErrors: GqlEoValidatorErrors;
  getShareState: GqlShareStateResponse;
  getShareTransactionDocuments: Array<GqlDocument>;
  identity: GqlIdentity;
  identityByUserId: GqlIdentity;
  individualReport: GqlGetIndividualReportResponse;
  investments: Array<GqlInvestment>;
  meeting: GqlMeeting;
  meetingAgendaTemplates: Array<GqlMeetingAgendaTemplate>;
  meetingByNumber?: Maybe<GqlMeeting>;
  meetingConcerns: Array<GqlMeetingConcern>;
  meetingDocument: GqlMeetingDocument;
  meetingErrors: GqlMeetingValidatorErrors;
  meetingMcCounts: GqlMeetingMcCountsResponse;
  meetingPaidForConcerns: GqlMeetingPaidForConcernsResponse;
  meetingParticipant: GqlMeetingParticipant;
  meetingParticipantShare: GqlMeetingParticipantShare;
  meetingParticipantShares: GqlMeetingParticipantShare;
  meetingParticipants: GqlMeetingParticipantsPagedResult;
  meetingParticipantsByIds: Array<GqlMeetingParticipant>;
  meetingTodos: Array<GqlMeetingTodo>;
  meetings: GqlMeetingsPagedResult;
  nextShareCapitalChanges: GqlNextShareCapitalChanges;
  niceFounders: Array<GqlNiceFounder>;
  optionsRegistration: GqlOptionsRegistration;
  optionsRegistrationErrors: GqlOrValidatorErrors;
  optionsRegistrations: Array<GqlOptionsRegistration>;
  optionsSharesRegistration: GqlOptionsSharesRegistration;
  optionsSharesRegistrationErrors: GqlOsrValidatorErrors;
  optionsSharesRegistrations: Array<GqlOptionsSharesRegistration>;
  optionsSharesSubscriber: GqlOptionsSharesSubscriber;
  optionsSharesSubscriberErrors: GqlOssValidatorErrors;
  optionsSharesSubscribers: Array<GqlOptionsSharesSubscriber>;
  orDocumentLatest?: Maybe<GqlOptionsRegistrationDocument>;
  orSignatories: Array<GqlOptionsRegistrationSignatory>;
  osrVestedAndAllocated: Array<GqlVestedAndAllocated>;
  passkeys: Array<GqlPasskey>;
  ratsitCompanies: Array<GqlRatsitIdentity>;
  ratsitCompaniesAndPeople: Array<GqlRatsitIdentity>;
  ratsitCompany: GqlRatsitIdentity;
  ratsitCompanyBoard: Array<GqlRatsitIdentity>;
  ratsitPeople: Array<GqlRatsitIdentity>;
  ratsitSearch: Array<GqlRatsitIdentity>;
  scriveStatusMeetingMinutesByMeetingId: GqlDocIsSignedResponse;
  searchIdentities: Array<GqlIdentity>;
  sellableShareState: GqlSellableShareResponse;
  shCount: GqlShCountResponse;
  shSellableShares: GqlShSellableSharesResponse;
  shareClasses: Array<GqlShareClass>;
  shareCount: GqlShareCountResponse;
  shareIssue: GqlShareIssue;
  shareIssueDocument: GqlShareIssueDocument;
  shareIssueErrors: GqlShareIssueValidatorErrors;
  shareIssueRegistration: GqlShareIssueRegistration;
  shareIssueRegistrationErrors: GqlSirValidatorErrors;
  shareIssueRegistrations: Array<GqlShareIssueRegistration>;
  shareIssues: Array<GqlShareIssue>;
  shareStateIsValid: Scalars['Boolean']['output'];
  shareTransactionDocument: GqlShareTransactionDocument;
  shareTransactionDocuments: Array<GqlShareTransactionDocument>;
  shareTransactionGroup: GqlShareTransactionGroup;
  shareTransactionGroups: Array<GqlShareTransactionGroup>;
  shareTransactions: Array<GqlShareTransaction>;
  shareholder: GqlShareholder;
  shareholders: Array<GqlShareholder>;
  shareholdersShareState: GqlShareStateResponse;
  siConnectedWps?: Maybe<Array<GqlWarrantProgram>>;
  siCount: Scalars['Int']['output'];
  siSharesLeft: Scalars['Float']['output'];
  sipValidatorErrors: GqlSipValidatorErrors;
  snrAuthNoBoardStatement: Scalars['Boolean']['output'];
  snrAuthorizations: Array<GqlSnrAuthorization>;
  snrBonds: Array<GqlSnrBond>;
  snrCases: GqlSnrCasePagedResult;
  snrQuotaValueAtDate?: Maybe<Scalars['Float']['output']>;
  snrShareCapitalChanges: Array<GqlSnrShareCapitalChange>;
  stripeCompanyProducts: Array<GqlStripeProduct>;
  stripeCompanySubscription?: Maybe<GqlStripeSubscription>;
  stripeCustomer?: Maybe<GqlStripeCustomer>;
  stripeInvoice: GqlStripeInvoice;
  stripeInvoices: Array<GqlStripeInvoice>;
  stripeMeetingConcernsInvoices: Array<GqlStripeInvoice>;
  stripePaymentIntent: GqlStripePaymentIntent;
  stripePaymentMethods: Array<GqlStripePaymentMethod>;
  stripePrices: Array<GqlStripePrice>;
  stripeProduct: GqlStripeProduct;
  stripeProducts: Array<GqlStripeProduct>;
  stripePromoCode: GqlStripePromotionCode;
  stripeQuote: GqlStripeQuote;
  stripeSubscriptions: Array<GqlStripeSubscription>;
  todos: Array<GqlTodo>;
  todosUnfinished: Array<GqlTodo>;
  userCompanyInvites: Array<GqlCompanyInvite>;
  userHubspotIdentityToken?: Maybe<Scalars['String']['output']>;
  userIdentity: GqlIdentity;
  warrantBoardMembers: Array<GqlWarrantBoardMember>;
  warrantDocument: GqlWarrantDocument;
  warrantParticipant: GqlWarrantParticipant;
  warrantParticipantErrors: GqlWppValidatorErrors;
  warrantProgram: GqlWarrantProgram;
  warrantProgramErrors: GqlWpValidatorErrors;
  warrantPrograms: GqlWarrantProgramsPagedResult;
  warrantShareholders: Array<GqlWarrantShareholder>;
  wiseDependencyBonds: Array<GqlWiseDependencyBond>;
  wiseErrors: GqlWiseValidatorErrors;
  wpCount: Scalars['Int']['output'];
  wpDecisionErrors: GqlWpDecisionValidatorErrors;
  wpOptionsLeft: Scalars['Float']['output'];
}


export interface GqlQueryActiveCompanySubscriptionArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryAdminCompaniesArgs {
  input: GqlASearchCompaniesInput;
}


export interface GqlQueryAdminScriveDocumentsArgs {
  input: GqlASearchScriveDocumentsInput;
}


export interface GqlQueryAdminShareholdersArgs {
  input: GqlASearchShareholdersInput;
}


export interface GqlQueryAdminUsersArgs {
  input: GqlASearchUsersInput;
}


export interface GqlQueryAgendaItemsArgs {
  companyId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}


export interface GqlQueryAoaGetAffectedArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryAoaMinAndMaxOfSharesArgs {
  companyId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}


export interface GqlQueryArticlesOfAssocArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryArticlesOfAssocDocumentArgs {
  companyId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
}


export interface GqlQueryArticlesOfAssocErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryArticlesOfAssocsArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryAuthorizationGroupArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryAuthorizationGroupDocumentArgs {
  companyId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
}


export interface GqlQueryAuthorizationGroupErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryAuthorizationGroupsArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryCapTableArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryCapTableStakeArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryCapTableStakesArgs {
  capTableId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryCapTablesArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryCompaniesArgs {
  filter?: InputMaybe<Scalars['String']['input']>;
}


export interface GqlQueryCompanyArgs {
  id: Scalars['ID']['input'];
}


export interface GqlQueryCompanyConnectionsArgs {
  connectionType?: InputMaybe<GqlCompanyConnectionEnum>;
}


export interface GqlQueryCompanyDirectorsArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryCompanyInvitesArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryCompanySubscriptionsArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryCompanyUsersArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryCooptedMeetingParticipantsArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryDashboardMilestonesArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryDocGenValidateMeetingArgs {
  companyId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}


export interface GqlQueryDocumentArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryDocumentSignaturesArgs {
  companyId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
}


export interface GqlQueryDocumentUserTagsArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryDocumentsArgs {
  companyId: Scalars['ID']['input'];
  filter?: InputMaybe<GqlDocumentFilterInput>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
}


export interface GqlQueryDocumentsByIdsArgs {
  companyId: Scalars['ID']['input'];
  ids: Array<Scalars['ID']['input']>;
}


export interface GqlQueryEmployeeOptionsErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryGetShareStateArgs {
  companyId: Scalars['ID']['input'];
  untilTransaction?: InputMaybe<Scalars['String']['input']>;
}


export interface GqlQueryGetShareTransactionDocumentsArgs {
  companyId: Scalars['String']['input'];
}


export interface GqlQueryIdentityArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryIdentityByUserIdArgs {
  companyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}


export interface GqlQueryIndividualReportArgs {
  companyId: Scalars['ID']['input'];
  input: GqlGetIndividualReportInput;
}


export interface GqlQueryInvestmentsArgs {
  companyId: Scalars['ID']['input'];
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
}


export interface GqlQueryMeetingArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryMeetingAgendaTemplatesArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryMeetingByNumberArgs {
  companyId: Scalars['ID']['input'];
  number: Scalars['String']['input'];
}


export interface GqlQueryMeetingConcernsArgs {
  companyId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}


export interface GqlQueryMeetingDocumentArgs {
  companyId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
}


export interface GqlQueryMeetingErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryMeetingMcCountsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryMeetingPaidForConcernsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryMeetingParticipantArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryMeetingParticipantShareArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryMeetingParticipantSharesArgs {
  companyId: Scalars['ID']['input'];
  meetingParticipantId: Scalars['ID']['input'];
}


export interface GqlQueryMeetingParticipantsArgs {
  companyId: Scalars['ID']['input'];
  filter?: InputMaybe<GqlMeetingParticipantFilterInput>;
  limit?: Scalars['Int']['input'];
  meetingId: Scalars['ID']['input'];
  offset?: Scalars['Int']['input'];
}


export interface GqlQueryMeetingParticipantsByIdsArgs {
  companyId: Scalars['ID']['input'];
  ids: Array<Scalars['ID']['input']>;
  meetingId: Scalars['ID']['input'];
}


export interface GqlQueryMeetingTodosArgs {
  companyId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}


export interface GqlQueryMeetingsArgs {
  companyId: Scalars['ID']['input'];
  kinds?: InputMaybe<Array<GqlMeetingKind>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  sortDirection?: InputMaybe<Scalars['String']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
}


export interface GqlQueryNextShareCapitalChangesArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryNiceFoundersArgs {
  companyId: Scalars['ID']['input'];
  warrantProgramId: Scalars['ID']['input'];
}


export interface GqlQueryOptionsRegistrationArgs {
  id: Scalars['ID']['input'];
}


export interface GqlQueryOptionsRegistrationErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryOptionsRegistrationsArgs {
  companyId: Scalars['ID']['input'];
  warrantProgramId: Scalars['ID']['input'];
}


export interface GqlQueryOptionsSharesRegistrationArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryOptionsSharesRegistrationErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryOptionsSharesRegistrationsArgs {
  warrantProgramId: Scalars['ID']['input'];
}


export interface GqlQueryOptionsSharesSubscriberArgs {
  ossId: Scalars['ID']['input'];
}


export interface GqlQueryOptionsSharesSubscriberErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryOptionsSharesSubscribersArgs {
  osrId: Scalars['ID']['input'];
}


export interface GqlQueryOrDocumentLatestArgs {
  companyId: Scalars['ID']['input'];
  optionsRegistrationId: Scalars['ID']['input'];
}


export interface GqlQueryOrSignatoriesArgs {
  companyId: Scalars['ID']['input'];
  optionsRegistrationId: Scalars['ID']['input'];
}


export interface GqlQueryOsrVestedAndAllocatedArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryRatsitCompaniesArgs {
  name: Scalars['String']['input'];
}


export interface GqlQueryRatsitCompaniesAndPeopleArgs {
  name: Scalars['String']['input'];
}


export interface GqlQueryRatsitCompanyArgs {
  orgNumber: Scalars['String']['input'];
}


export interface GqlQueryRatsitCompanyBoardArgs {
  orgNumber: Scalars['String']['input'];
}


export interface GqlQueryRatsitPeopleArgs {
  who: Scalars['String']['input'];
}


export interface GqlQueryRatsitSearchArgs {
  juridicalKinds: Array<Scalars['Int']['input']>;
  searchString: Scalars['String']['input'];
}


export interface GqlQueryScriveStatusMeetingMinutesByMeetingIdArgs {
  companyId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}


export interface GqlQuerySearchIdentitiesArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSearchIdentitiesInput;
}


export interface GqlQuerySellableShareStateArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSellableSharesInput;
}


export interface GqlQueryShCountArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryShSellableSharesArgs {
  companyId: Scalars['ID']['input'];
  input: GqlShSellableSharesInput;
}


export interface GqlQueryShareClassesArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryShareCountArgs {
  companyId: Scalars['ID']['input'];
  input?: InputMaybe<GqlShareCountInput>;
}


export interface GqlQueryShareIssueArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryShareIssueDocumentArgs {
  companyId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
}


export interface GqlQueryShareIssueErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryShareIssueRegistrationArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryShareIssueRegistrationErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryShareIssueRegistrationsArgs {
  companyId: Scalars['ID']['input'];
  shareIssueId: Scalars['ID']['input'];
}


export interface GqlQueryShareIssuesArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryShareStateIsValidArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryShareTransactionDocumentArgs {
  documentId: Scalars['String']['input'];
}


export interface GqlQueryShareTransactionGroupArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryShareTransactionGroupsArgs {
  companyId: Scalars['ID']['input'];
  filter?: InputMaybe<GqlStgFilterInput>;
  identityId?: InputMaybe<Scalars['ID']['input']>;
}


export interface GqlQueryShareTransactionsArgs {
  companyId: Scalars['ID']['input'];
  shareTransactionGroupId: Scalars['ID']['input'];
}


export interface GqlQueryShareholderArgs {
  companyId: Scalars['ID']['input'];
  identityId: Scalars['ID']['input'];
}


export interface GqlQueryShareholdersArgs {
  companyId: Scalars['ID']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}


export interface GqlQueryShareholdersShareStateArgs {
  companyId: Scalars['ID']['input'];
  identityId: Scalars['ID']['input'];
}


export interface GqlQuerySiConnectedWpsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQuerySiCountArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQuerySiSharesLeftArgs {
  companyId: Scalars['ID']['input'];
  input: GqlSiSharesLeftInput;
}


export interface GqlQuerySipValidatorErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQuerySnrAuthNoBoardStatementArgs {
  companyId: Scalars['ID']['input'];
  snrAuthId: Scalars['ID']['input'];
}


export interface GqlQuerySnrAuthorizationsArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQuerySnrBondsArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQuerySnrCasesArgs {
  companyId: Scalars['ID']['input'];
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
}


export interface GqlQuerySnrQuotaValueAtDateArgs {
  companyId: Scalars['ID']['input'];
  date: Scalars['Date']['input'];
}


export interface GqlQuerySnrShareCapitalChangesArgs {
  companyId: Scalars['ID']['input'];
  sort?: InputMaybe<GqlSortOrder>;
}


export interface GqlQueryStripeCompanyProductsArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryStripeCompanySubscriptionArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryStripeCustomerArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryStripeInvoiceArgs {
  companyId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
}


export interface GqlQueryStripeInvoicesArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryStripeMeetingConcernsInvoicesArgs {
  companyId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}


export interface GqlQueryStripePaymentIntentArgs {
  companyId: Scalars['ID']['input'];
  paymentIntentId: Scalars['ID']['input'];
}


export interface GqlQueryStripePaymentMethodsArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryStripeProductArgs {
  id: Scalars['String']['input'];
}


export interface GqlQueryStripePromoCodeArgs {
  code: Scalars['String']['input'];
}


export interface GqlQueryStripeQuoteArgs {
  companyId: Scalars['ID']['input'];
  quoteId: Scalars['String']['input'];
}


export interface GqlQueryStripeSubscriptionsArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryTodosArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryTodosUnfinishedArgs {
  companyId: Scalars['ID']['input'];
}


export interface GqlQueryUserIdentityArgs {
  companyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}


export interface GqlQueryWarrantBoardMembersArgs {
  companyId: Scalars['ID']['input'];
  warrantProgramId: Scalars['ID']['input'];
}


export interface GqlQueryWarrantDocumentArgs {
  companyId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
}


export interface GqlQueryWarrantParticipantArgs {
  id: Scalars['ID']['input'];
}


export interface GqlQueryWarrantParticipantErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryWarrantProgramArgs {
  id: Scalars['ID']['input'];
}


export interface GqlQueryWarrantProgramErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryWarrantProgramsArgs {
  companyId: Scalars['ID']['input'];
  kinds: Array<GqlWarrantProgramKind>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
}


export interface GqlQueryWarrantShareholdersArgs {
  companyId: Scalars['ID']['input'];
  warrantProgramId: Scalars['ID']['input'];
}


export interface GqlQueryWiseDependencyBondsArgs {
  companyId: Scalars['ID']['input'];
  warrantProgramId: Scalars['ID']['input'];
}


export interface GqlQueryWiseErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryWpCountArgs {
  companyId: Scalars['ID']['input'];
  kind: Array<Scalars['Int']['input']>;
}


export interface GqlQueryWpDecisionErrorsArgs {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface GqlQueryWpOptionsLeftArgs {
  companyId: Scalars['ID']['input'];
  input: GqlWpOptionsLeftInput;
}

export interface GqlQuoteComputed {
  recurring?: Maybe<GqlQuoteComputedRecurring>;
  upfront: GqlQuoteComputedUpfront;
}

export interface GqlQuoteComputedRecurring {
  amount_subtotal: Scalars['Float']['output'];
  amount_total: Scalars['Float']['output'];
  interval: Scalars['String']['output'];
  interval_count: Scalars['Float']['output'];
  total_details: GqlQuoteComputedTotalDetails;
}

export interface GqlQuoteComputedTotalDetails {
  amount_discount: Scalars['Float']['output'];
  amount_tax: Scalars['Float']['output'];
}

export interface GqlQuoteComputedUpfront {
  amount_subtotal: Scalars['Float']['output'];
  amount_total: Scalars['Float']['output'];
  line_items?: Maybe<GqlStripeLineItemList>;
  total_details: GqlQuoteComputedTotalDetails;
}

export interface GqlQuoteTotalDetails {
  amount_discount: Scalars['Float']['output'];
  amount_tax: Scalars['Float']['output'];
}

export interface GqlRatsitIdentity {
  address?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  functionCode?: Maybe<Scalars['String']['output']>;
  functionText?: Maybe<Scalars['String']['output']>;
  identityNumber: Scalars['String']['output'];
  identityNumberKind: Scalars['Int']['output'];
  juridicalKind: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface GqlRefreshCapTableInput {
  clearEdits: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
}

export enum GqlRegistrationStatus {
  NotApplicable = 'NOT_APPLICABLE',
  NotRegistered = 'NOT_REGISTERED',
  Registered = 'REGISTERED'
}

export interface GqlRiksbankenObservations {
  date: Scalars['String']['output'];
  value: Scalars['Float']['output'];
}

export interface GqlRiksbankenObservationsInput {
  date: Scalars['String']['input'];
  value: Scalars['Float']['input'];
}

export enum GqlStRounded {
  Down = 'Down',
  None = 'None',
  Up = 'Up'
}

export enum GqlScriveAuthMethod {
  SeBankid = 'se_bankid',
  Standard = 'standard'
}

export enum GqlScriveDocumentStatus {
  Canceled = 'canceled',
  Closed = 'closed',
  DocumentError = 'document_error',
  Pending = 'pending',
  Preparation = 'preparation',
  Rejected = 'rejected',
  Timedout = 'timedout'
}

export interface GqlSearchIdentitiesInput {
  juridicalKinds: Array<Scalars['Int']['input']>;
  pageOffset: Scalars['Float']['input'];
  pageSize: Scalars['Float']['input'];
  relations?: InputMaybe<GqlSearchIdentitiesRelations>;
  searchString: Scalars['String']['input'];
  similarity?: InputMaybe<Scalars['Float']['input']>;
}

export interface GqlSearchIdentitiesRelations {
  companyDirector?: InputMaybe<Scalars['Boolean']['input']>;
  meetingParticipant?: InputMaybe<Scalars['Boolean']['input']>;
  shareholder?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface GqlSellableShareResponse {
  sellableShares: Array<GqlSellableShares>;
  shareRegisterIsValid: Scalars['Boolean']['output'];
}

export interface GqlSellableShares {
  shareClasses: Array<GqlShareClassObject>;
  shareholder: GqlShareholder;
  shares: Scalars['JSONObject']['output'];
}

export interface GqlSellableSharesInput {
  date: Scalars['Date']['input'];
  sortOrder: Scalars['Int']['input'];
}

export interface GqlSendMailMeetingSummonsToAllInput {
  documentId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
}

export interface GqlSendMailMeetingSummonsToOneInput {
  documentId: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
  meetingParticipantId: Scalars['ID']['input'];
}

export enum GqlSeverity {
  Error = 'error',
  Warning = 'warning'
}

export interface GqlShCountResponse {
  count: Scalars['Float']['output'];
}

export interface GqlShCreateInput {
  companyId: Scalars['ID']['input'];
  identityInput: GqlCreateIdentityInput;
}

export interface GqlShDeletedResponse {
  id: Scalars['ID']['output'];
}

export interface GqlShSellableShares {
  shareClasses: Array<GqlShareClassObject>;
  shares: Scalars['JSONObject']['output'];
}

export interface GqlShSellableSharesInput {
  date: Scalars['Date']['input'];
  id: Scalars['ID']['input'];
  sortOrder: Scalars['Int']['input'];
}

export interface GqlShSellableSharesResponse {
  sellableShares: GqlShSellableShares;
}

export interface GqlShareClass {
  name: Scalars['String']['output'];
  voteWeight: Scalars['String']['output'];
}

export interface GqlShareClassInput {
  name: Scalars['String']['input'];
  voteWeight: Scalars['String']['input'];
}

export interface GqlShareClassObject {
  max?: Maybe<Scalars['String']['output']>;
  min?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  quantity?: Maybe<Scalars['String']['output']>;
  vote_weight: Scalars['String']['output'];
}

export interface GqlShareCountInput {
  date: Scalars['Date']['input'];
  sortOrder: Scalars['Int']['input'];
}

export interface GqlShareCountResponse {
  shareRegisterLastUpdate?: Maybe<Scalars['Date']['output']>;
  shareRegisterShareCount: Scalars['String']['output'];
  snrLastUpdate: Scalars['Date']['output'];
  snrShareCount: Scalars['String']['output'];
}

export interface GqlShareIssue {
  approvedByMeeting?: Maybe<Scalars['Boolean']['output']>;
  boardMembers: Array<GqlShareIssueBoardMember>;
  boardsStatementDate?: Maybe<Scalars['Date']['output']>;
  boardsStatementEn?: Maybe<Scalars['String']['output']>;
  boardsStatementOption?: Maybe<GqlBoardsStatementOption>;
  boardsStatementSv?: Maybe<Scalars['String']['output']>;
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  currency: GqlCurrency;
  decisionKind?: Maybe<GqlDecisionKind>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  maxNumberOfShares?: Maybe<Scalars['String']['output']>;
  maxPaidAmount?: Maybe<Scalars['String']['output']>;
  meetingConcern?: Maybe<GqlMeetingConcern>;
  minNumberOfShares?: Maybe<Scalars['String']['output']>;
  nonCashStatementPl?: Maybe<Scalars['String']['output']>;
  nonCashStatementSl?: Maybe<Scalars['String']['output']>;
  numberOfSharesBefore?: Maybe<Scalars['String']['output']>;
  paidForAt?: Maybe<Scalars['Date']['output']>;
  paymentCount: GqlSiPaymentCountResponse;
  paymentKindCash?: Maybe<Scalars['Boolean']['output']>;
  paymentKindNonCash?: Maybe<Scalars['Boolean']['output']>;
  paymentKindOffset?: Maybe<Scalars['Boolean']['output']>;
  preferentialRights: Scalars['Boolean']['output'];
  pricePerShare?: Maybe<Scalars['String']['output']>;
  primaryLang: Scalars['Boolean']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  purpose: GqlShareIssuePurpose;
  quotaValue?: Maybe<Scalars['String']['output']>;
  restrictedToParticipants?: Maybe<Scalars['Boolean']['output']>;
  secondaryLang: Scalars['Boolean']['output'];
  shareClasses: Scalars['JSON']['output'];
  shareIssueDocuments: Array<GqlShareIssueDocument>;
  shareIssueRegistrations: Array<GqlShareIssueRegistration>;
  snrAuthorization?: Maybe<GqlSnrAuthorization>;
  snrAuthorizationId?: Maybe<Scalars['ID']['output']>;
  snrShareCapitalChangeId?: Maybe<Scalars['ID']['output']>;
  stripeInvoiceId?: Maybe<Scalars['String']['output']>;
  subscriptionEndDate?: Maybe<Scalars['Date']['output']>;
  subscriptionStartDate?: Maybe<Scalars['Date']['output']>;
  updatedAt: Scalars['Date']['output'];
}

export interface GqlShareIssueBoardMember {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId: Scalars['ID']['output'];
  shareIssue: GqlShareIssue;
  shareIssueId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlShareIssueDocument {
  createdAt: Scalars['Date']['output'];
  document: GqlDocument;
  documentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  shareIssue: GqlShareIssue;
  shareIssueId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlShareIssueParticipant {
  claim?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId: Scalars['ID']['output'];
  latestShareSubscriptionList?: Maybe<GqlDocument>;
  numberOfShares?: Maybe<Scalars['String']['output']>;
  numberOfSharesMax?: Maybe<Scalars['String']['output']>;
  paymentKind: Scalars['Int']['output'];
  paymentStatus: Scalars['Int']['output'];
  proxy?: Maybe<GqlIdentity>;
  proxyId?: Maybe<Scalars['ID']['output']>;
  shareClass?: Maybe<Scalars['JSONObject']['output']>;
  shareIssueRegistration: GqlShareIssueRegistration;
  shareIssueRegistrationId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
}

export enum GqlShareIssuePurpose {
  Capital = 'Capital',
  Qeso = 'QESO'
}

export interface GqlShareIssueRegistration {
  contact?: Maybe<GqlIdentity>;
  contactId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyExchangeRate?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  ibanAddress?: Maybe<Scalars['String']['output']>;
  ibanBank?: Maybe<Scalars['String']['output']>;
  ibanBic?: Maybe<Scalars['String']['output']>;
  ibanNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  participants: Array<GqlShareIssueParticipant>;
  paymentAccountKind?: Maybe<Scalars['Int']['output']>;
  paymentAccountNumber?: Maybe<Scalars['String']['output']>;
  paymentDueDate?: Maybe<Scalars['Date']['output']>;
  paymentName?: Maybe<Scalars['String']['output']>;
  regFormPaymentThroughBolago: Scalars['Boolean']['output'];
  shareIssue: GqlShareIssue;
  shareIssueId: Scalars['String']['output'];
  signatory?: Maybe<GqlIdentity>;
  signatoryId?: Maybe<Scalars['ID']['output']>;
  snrCaseId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
}

export interface GqlShareIssueValidatorErrors {
  boardsStatement: GqlValidatorStatus;
  decision: GqlValidatorStatus;
  language: GqlValidatorStatus;
  newShares: GqlValidatorStatus;
  nonCash: GqlValidatorStatus;
  payByOffset: GqlValidatorStatus;
  paymentKind: GqlValidatorStatus;
  preferentialRights: GqlValidatorStatus;
  shareClasses: GqlValidatorStatus;
  subscriptionDate: GqlValidatorStatus;
}

export interface GqlShareRange {
  end: Scalars['Float']['output'];
  pricePerShare?: Maybe<Scalars['String']['output']>;
  shareCertificateDate?: Maybe<Scalars['String']['output']>;
  shareClass: GqlShareClass;
  snrSccId?: Maybe<Scalars['String']['output']>;
  sortOrder: Scalars['Float']['output'];
  splitFactor?: Maybe<Scalars['String']['output']>;
  start: Scalars['Float']['output'];
  stgId: Scalars['String']['output'];
  transactionDate: Scalars['Date']['output'];
  transactionId: Scalars['String']['output'];
  transactionKind: GqlTransactionKind;
}

export interface GqlShareStateResponse {
  averagePrice: Scalars['String']['output'];
  isValid: Scalars['Boolean']['output'];
  shareState?: Maybe<Scalars['JSONObject']['output']>;
}

export interface GqlShareTransaction {
  blockEnd: Scalars['String']['output'];
  blockStart: Scalars['String']['output'];
  buyer?: Maybe<GqlShareholder>;
  buyerId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  pricePerShare?: Maybe<Scalars['String']['output']>;
  rounded: GqlStRounded;
  seller?: Maybe<GqlShareholder>;
  sellerId?: Maybe<Scalars['String']['output']>;
  shareCertificateDate?: Maybe<Scalars['Date']['output']>;
  shareClassFrom?: Maybe<Scalars['JSONObject']['output']>;
  shareClassTo?: Maybe<Scalars['JSONObject']['output']>;
  shareTransactionDocuments: Array<GqlShareTransactionDocument>;
  shareTransactionGroup: GqlShareTransactionGroup;
  shareTransactionGroupId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['Date']['output'];
}

export interface GqlShareTransactionDocument {
  createdAt: Scalars['Date']['output'];
  document: GqlDocument;
  documentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  shareTransaction: GqlShareTransaction;
  shareTransactionId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlShareTransactionGroup {
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['Int']['output'];
  shareIssueId?: Maybe<Scalars['ID']['output']>;
  shareTransactions: Array<GqlShareTransaction>;
  snrShareCapitalChange?: Maybe<GqlSnrShareCapitalChange>;
  snrShareCapitalChangeId?: Maybe<Scalars['ID']['output']>;
  sortOrder: Scalars['Int']['output'];
  splitFactor?: Maybe<Scalars['String']['output']>;
  transactionDate: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  warrantProgramId?: Maybe<Scalars['ID']['output']>;
}

export interface GqlShareholder {
  company: GqlCompany;
  companyId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId: Scalars['String']['output'];
  ownerCompanyId?: Maybe<Scalars['String']['output']>;
  shareState?: Maybe<GqlOwnerStateResponse>;
  shareTransactions: Array<GqlShareTransaction>;
  updatedAt: Scalars['Date']['output'];
}


export interface GqlShareholderShareStateArgs {
  date?: InputMaybe<Scalars['Date']['input']>;
}

export interface GqlSharesLeftToAllocate {
  allocated: Scalars['Float']['output'];
  left: Scalars['Float']['output'];
  name: Scalars['String']['output'];
}

export interface GqlSiAddShareClassInput {
  id: Scalars['ID']['input'];
  max: Scalars['String']['input'];
  min: Scalars['String']['input'];
  name: Scalars['String']['input'];
  vote_weight: Scalars['String']['input'];
}

/** Connect auth */
export interface GqlSiConnectAuthorizationInput {
  id: Scalars['ID']['input'];
  snrAuthorizationId: Scalars['ID']['input'];
}

/** Connect meeting */
export interface GqlSiConnectMeetingInput {
  id: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
  snrAuthorizationId?: InputMaybe<Scalars['ID']['input']>;
}

export interface GqlSiConnectMeetingResponse {
  aiSetDoneResponse: GqlAiSetDoneResponse;
  shareIssue: GqlShareIssue;
}

/** Create share issue */
export interface GqlSiCreateInput {
  description: Scalars['String']['input'];
  preferentialRights: Scalars['Boolean']['input'];
  primaryLang: Scalars['Boolean']['input'];
  secondaryLang: Scalars['Boolean']['input'];
  shareClasses: Array<Scalars['JSONObject']['input']>;
}

/** Create and connect meeting */
export interface GqlSiCreateMeetingInput {
  id: Scalars['ID']['input'];
  meetingCreate: GqlMeetingCreateInput;
  snrAuthorizationId?: InputMaybe<Scalars['ID']['input']>;
}

export interface GqlSiDeleteShareClassInput {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}

export interface GqlSiDeletedResponse {
  aiDeletedResponses?: Maybe<Array<GqlAiDeletedResponse>>;
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  id: Scalars['ID']['output'];
  mcDeletedResponse?: Maybe<GqlMcDeletedResponse>;
}

/** Disconnect authorization, will also disconnect meeting if there is one connected */
export interface GqlSiDisconnectAuthorizationInput {
  id: Scalars['ID']['input'];
}

export interface GqlSiDisconnectAuthorizationResponse {
  aiDeletedResponses?: Maybe<Array<GqlAiDeletedResponse>>;
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  mcDeletedResponse?: Maybe<GqlMcDeletedResponse>;
  shareIssue: GqlShareIssue;
}

/** Disconnect meeting */
export interface GqlSiDisconnectMeetingInput {
  id: Scalars['ID']['input'];
}

export interface GqlSiDisconnectMeetingResponse {
  aiDeletedResponses?: Maybe<Array<GqlAiDeletedResponse>>;
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  mcDeletedResponse?: Maybe<GqlMcDeletedResponse>;
  shareIssue: GqlShareIssue;
}

export interface GqlSiEditShareClassInput {
  id: Scalars['ID']['input'];
  max: Scalars['String']['input'];
  min: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface GqlSiPaymentCountResponse {
  notPaid: Scalars['Float']['output'];
  paid: Scalars['Float']['output'];
  waiting: Scalars['Float']['output'];
}

export interface GqlSiSetBoardsStatementInput {
  boardsStatementDate?: InputMaybe<Scalars['Date']['input']>;
  boardsStatementEn?: InputMaybe<Scalars['String']['input']>;
  boardsStatementOption: GqlBoardsStatementOption;
  boardsStatementSv?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

export interface GqlSiSetBoardsStatementResponse {
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  shareIssue: GqlShareIssue;
}

export interface GqlSiSetDescriptionInput {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlSiSetLanguageInput {
  id: Scalars['ID']['input'];
  primaryLang: Scalars['Boolean']['input'];
  secondaryLang: Scalars['Boolean']['input'];
}

export interface GqlSiSetNewSharesInput {
  currency: GqlCurrency;
  id: Scalars['ID']['input'];
  maxNumberOfShares: Scalars['String']['input'];
  minNumberOfShares: Scalars['String']['input'];
  numberOfSharesBefore: Scalars['String']['input'];
  pricePerShare: Scalars['String']['input'];
  quotaValue: Scalars['String']['input'];
}

export interface GqlSiSetNonCashStatementInput {
  id: Scalars['ID']['input'];
  nonCashStatementPl?: InputMaybe<Scalars['String']['input']>;
  nonCashStatementSl?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlSiSetPaymentKindInput {
  id: Scalars['ID']['input'];
  paymentKindCash: Scalars['Boolean']['input'];
  paymentKindNonCash: Scalars['Boolean']['input'];
  paymentKindOffset: Scalars['Boolean']['input'];
}

export interface GqlSiSetPaymentKindResponse {
  documents: Array<GqlDocument>;
  shareIssue: GqlShareIssue;
  sipDeletedResponses: Array<GqlSipDeletedResponse>;
}

/** Set si subscription dates */
export interface GqlSiSetSubscriptionDatesInput {
  id: Scalars['ID']['input'];
  paymentDueDate: Scalars['Date']['input'];
  subscriptionEndDate: Scalars['Date']['input'];
  subscriptionStartDate: Scalars['Date']['input'];
}

export interface GqlSiSharesLeftInput {
  id: Scalars['ID']['input'];
  shareClassName: Scalars['String']['input'];
}

export interface GqlSiUpdateInput {
  id: Scalars['ID']['input'];
  preferentialRights?: InputMaybe<Scalars['Boolean']['input']>;
  purpose?: InputMaybe<GqlShareIssuePurpose>;
  subscriptionEndDate?: InputMaybe<Scalars['Date']['input']>;
}

/** Create share issue participant */
export interface GqlSibmCreateInput {
  identityInput: GqlCreateIdentityInput;
  shareIssueId: Scalars['ID']['input'];
}

export interface GqlSibmDeletedResponse {
  id: Scalars['ID']['output'];
}

export enum GqlSignAllowedAction {
  None = 'None',
  Restart = 'Restart',
  Start = 'Start',
  Stop = 'Stop'
}

/** Create share issue participant */
export interface GqlSipCreateInput {
  claim?: InputMaybe<Scalars['String']['input']>;
  identityInput: GqlCreateIdentityInput;
  numberOfShares?: InputMaybe<Scalars['String']['input']>;
  paymentKind: Scalars['Int']['input'];
  shareClass?: InputMaybe<Scalars['JSONObject']['input']>;
  shareIssueRegistrationId: Scalars['ID']['input'];
}

export interface GqlSipDeletedResponse {
  id: Scalars['ID']['output'];
}

export interface GqlSipEditInput {
  claim: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  numberOfShares: Scalars['String']['input'];
  shareClass: Scalars['JSONObject']['input'];
}

export interface GqlSipSetNumberOfSharesInput {
  id: Scalars['ID']['input'];
  numberOfShares?: InputMaybe<Scalars['String']['input']>;
  shareClass: Scalars['JSONObject']['input'];
}

export interface GqlSipSetPaymentKindInput {
  id: Scalars['ID']['input'];
  paymentKind: Scalars['Int']['input'];
}

/** Set sip payment status */
export interface GqlSipSetPaymentStatusInput {
  id: Scalars['ID']['input'];
  paymentStatus: Scalars['Int']['input'];
}

export interface GqlSipSetProxyInput {
  id: Scalars['ID']['input'];
  proxyInput: GqlCreateIdentityInput;
}

export interface GqlSipUpdateResponse {
  documents: Array<GqlDocument>;
  shareIssueParticipant: GqlShareIssueParticipant;
}

export interface GqlSipValidatorErrors {
  paymentStatus: GqlValidatorStatus;
  proxy: GqlValidatorStatus;
}

export interface GqlSirCreateInput {
  description: Scalars['String']['input'];
  shareIssueId: Scalars['ID']['input'];
}

export interface GqlSirSetCurrencyExchangeRateInput {
  currencyExchangeRate: Scalars['String']['input'];
  id: Scalars['ID']['input'];
}

export interface GqlSirSetPaymentDueDateInput {
  id: Scalars['ID']['input'];
  paymentDueDate: Scalars['Date']['input'];
}

export interface GqlSirSetPaymentInput {
  ibanAddress?: InputMaybe<Scalars['String']['input']>;
  ibanBank?: InputMaybe<Scalars['String']['input']>;
  ibanBic?: InputMaybe<Scalars['String']['input']>;
  ibanNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  paymentAccountKind?: InputMaybe<Scalars['Int']['input']>;
  paymentAccountNumber?: InputMaybe<Scalars['String']['input']>;
  paymentName?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlSirSetSnrCaseInput {
  id: Scalars['ID']['input'];
  snrCaseId?: InputMaybe<Scalars['ID']['input']>;
}

export interface GqlSirUpdateInput {
  contact?: InputMaybe<GqlCreateIdentityInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  regFormPaymentThroughBolago?: InputMaybe<Scalars['Boolean']['input']>;
  signatory?: InputMaybe<GqlCreateIdentityInput>;
}

export interface GqlSirUpdateResponse {
  documents: Array<GqlDocument>;
  sir: GqlShareIssueRegistration;
}

export interface GqlSirValidatorErrors {
  currencyExchangeRate: GqlValidatorStatus;
  decision: GqlValidatorStatus;
  options: GqlValidatorStatus;
  participants: GqlValidatorStatus;
  payment: GqlValidatorStatus;
  paymentDueDate: GqlValidatorStatus;
  paymentStatus: GqlValidatorStatus;
  signsRegForm: GqlValidatorStatus;
}

export interface GqlSnrAuthorization {
  auths: Array<GqlSnrAuthorizationAuth>;
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  filingNumber: Scalars['String']['output'];
  filingYear: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  registrationDate: Scalars['Date']['output'];
  serialNumber: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
}

export interface GqlSnrAuthorizationAuth {
  before: Scalars['String']['output'];
  before_year?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  kind: Scalars['String']['output'];
}

export interface GqlSnrBond {
  bondType: Scalars['String']['output'];
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  decisionBy?: Maybe<Scalars['String']['output']>;
  decisionDate?: Maybe<Scalars['Date']['output']>;
  filingNumber: Scalars['String']['output'];
  filingYear: Scalars['String']['output'];
  freetext?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  numberOfOptions?: Maybe<Scalars['String']['output']>;
  numberOfOptionsMax?: Maybe<Scalars['String']['output']>;
  numberOfOptionsMin?: Maybe<Scalars['String']['output']>;
  numberOfSharesMax?: Maybe<Scalars['String']['output']>;
  partRegistration: Scalars['Boolean']['output'];
  registrationDate: Scalars['Date']['output'];
  serialNumber: Scalars['String']['output'];
  shareCapitalCanBeIncreaseBy?: Maybe<Scalars['String']['output']>;
  shareCapitalCanBeIncreaseByCurrency?: Maybe<Scalars['String']['output']>;
  shareClass?: Maybe<Scalars['String']['output']>;
  shareSubEndDate?: Maybe<Scalars['Date']['output']>;
  shareSubStartDate?: Maybe<Scalars['Date']['output']>;
  updatedAt: Scalars['Date']['output'];
  warrantSubFrom?: Maybe<Scalars['Date']['output']>;
  warrantSubTo?: Maybe<Scalars['Date']['output']>;
  withoutLoan?: Maybe<Scalars['Boolean']['output']>;
}

export interface GqlSnrCase {
  arrivalDate?: Maybe<Scalars['Date']['output']>;
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  filingNumber: Scalars['String']['output'];
  filingYear: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  registrationDate?: Maybe<Scalars['Date']['output']>;
  status: Array<GqlSnrCaseStatus>;
  subjects: Array<GqlSnrCaseSubject>;
  updatedAt: Scalars['Date']['output'];
}

export interface GqlSnrCasePagedResult {
  models: Array<GqlSnrCase>;
  totalCount: Scalars['Int']['output'];
}

export interface GqlSnrCaseStatus {
  kod: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface GqlSnrCaseSubject {
  kind: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface GqlSnrShareCapitalChange {
  amountChange: Scalars['String']['output'];
  amountTotal: Scalars['String']['output'];
  amountTotalBeforeChange: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  companyValue?: Maybe<Scalars['String']['output']>;
  companyValuePreMoney?: Maybe<Scalars['String']['output']>;
  correctedSplitFactor?: Maybe<Scalars['String']['output']>;
  correctedSplitSharesChange?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  decisionDate?: Maybe<Scalars['Date']['output']>;
  decisionDateWarning: Scalars['Boolean']['output'];
  done: Scalars['Boolean']['output'];
  filingNumber: Scalars['String']['output'];
  filingYear: Scalars['String']['output'];
  foundersShareFraction?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  registrationDate: Scalars['Date']['output'];
  serialNumber: Scalars['String']['output'];
  shareClasses?: Maybe<Scalars['JSON']['output']>;
  sharePriceFactor?: Maybe<Scalars['String']['output']>;
  shareValue?: Maybe<Scalars['String']['output']>;
  shareValueSplitAdjusted?: Maybe<Scalars['String']['output']>;
  sharesChange?: Maybe<Scalars['String']['output']>;
  sharesChangeSplitAdjusted?: Maybe<Scalars['String']['output']>;
  sharesLeftToAllocate: Scalars['Float']['output'];
  sharesLeftToAllocateByShareClass: Array<GqlSharesLeftToAllocate>;
  sharesTotal?: Maybe<Scalars['String']['output']>;
  sharesTotalAfterChange?: Maybe<Scalars['String']['output']>;
  sharesTotalSplitAdjusted?: Maybe<Scalars['String']['output']>;
  snrSplitFactor: Scalars['String']['output'];
  snrSplitSharesChange: Scalars['String']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
  sortOrderOverride?: Maybe<Scalars['Float']['output']>;
  sortOrderSnr?: Maybe<Scalars['Float']['output']>;
  splitFactor: Scalars['String']['output'];
  splitSharesChange: Scalars['String']['output'];
  transactionValue?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
}

export enum GqlSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface GqlStCreateIssueInput {
  buyerId: Scalars['ID']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  numberOfShares: Scalars['Int']['input'];
  pricePerShare?: InputMaybe<Scalars['String']['input']>;
  shareClassTo: Scalars['JSONObject']['input'];
  shareTransactionGroupId: Scalars['ID']['input'];
}

export interface GqlStCreateTransferInput {
  buyerId: Scalars['ID']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  numberOfShares: Scalars['Int']['input'];
  pricePerShare?: InputMaybe<Scalars['String']['input']>;
  sellerId: Scalars['ID']['input'];
  shareClassFrom: Scalars['JSONObject']['input'];
  shareClassTo: Scalars['JSONObject']['input'];
  shareTransactionGroupId: Scalars['ID']['input'];
}

export interface GqlStCreateUnissueInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  numberOfShares: Scalars['Int']['input'];
  pricePerShare?: InputMaybe<Scalars['String']['input']>;
  sellerId: Scalars['ID']['input'];
  shareClassFrom: Scalars['JSONObject']['input'];
  shareTransactionGroupId: Scalars['ID']['input'];
}

export interface GqlStSetCommentInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

export interface GqlStSetPricePerShareInput {
  id: Scalars['ID']['input'];
  pricePerShare?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlStSetShareCertificateDateInput {
  id: Scalars['ID']['input'];
  shareCertificateDate: Scalars['Date']['input'];
}

export interface GqlStgCreateInput {
  kind: Scalars['Int']['input'];
  roundupShareholderId?: InputMaybe<Scalars['String']['input']>;
  shareIssueId?: InputMaybe<Scalars['ID']['input']>;
  snrShareCapitalChangeId?: InputMaybe<Scalars['ID']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  splitFactor?: InputMaybe<Scalars['String']['input']>;
  transactionDate: Scalars['Date']['input'];
  warrantProgramId?: InputMaybe<Scalars['ID']['input']>;
}

export interface GqlStgCreatedResponse {
  next?: Maybe<GqlCompleteShareCapitalChange>;
  transactions: Array<GqlShareTransactionGroup>;
}

export interface GqlStgDeletedResponse {
  id: Scalars['ID']['output'];
}

export interface GqlStgFilterInput {
  maxDate: Scalars['Date']['input'];
}

export interface GqlStgSetSccIdInput {
  id: Scalars['ID']['input'];
  snrShareCapitalChangeId?: InputMaybe<Scalars['ID']['input']>;
}

export interface GqlStgSetupInput {
  split?: InputMaybe<GqlStgSetupSplitInput>;
  transferDate: Scalars['Date']['input'];
  transfers: Array<GqlStgSetupTransferInput>;
}

export interface GqlStgSetupSplitInput {
  sortOrder: Scalars['Int']['input'];
  splitDate: Scalars['Date']['input'];
  splitFactor: Scalars['String']['input'];
}

export interface GqlStgSetupTransferInput {
  buyerId: Scalars['ID']['input'];
  numberOfShares: Scalars['Int']['input'];
}

export interface GqlStripeAddress {
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
}

export interface GqlStripeBillingPortalSession {
  configuration: Scalars['String']['output'];
  created: Scalars['Float']['output'];
  customer: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  livemode: Scalars['Boolean']['output'];
  locale?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
  on_behalf_of?: Maybe<Scalars['String']['output']>;
  return_url: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export interface GqlStripeCheckoutSession {
  after_expiration?: Maybe<Scalars['JSON']['output']>;
  allow_promotion_codes?: Maybe<Scalars['Boolean']['output']>;
  amount_subtotal?: Maybe<Scalars['Float']['output']>;
  amount_total?: Maybe<Scalars['Float']['output']>;
  billing_address_collection?: Maybe<Scalars['String']['output']>;
  cancel_url: Scalars['String']['output'];
  client_reference_id?: Maybe<Scalars['String']['output']>;
  consent?: Maybe<Scalars['JSON']['output']>;
  consent_collection?: Maybe<Scalars['JSON']['output']>;
  created: Scalars['Float']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  currency_conversion?: Maybe<Scalars['JSON']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  customer_creation?: Maybe<Scalars['String']['output']>;
  customer_details?: Maybe<Scalars['JSON']['output']>;
  customer_email?: Maybe<Scalars['String']['output']>;
  expires_at: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  line_items?: Maybe<Array<Scalars['JSON']['output']>>;
  livemode: Scalars['Boolean']['output'];
  locale?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  mode: Scalars['String']['output'];
  object: Scalars['String']['output'];
  payment_intent?: Maybe<Scalars['String']['output']>;
  payment_link?: Maybe<Scalars['String']['output']>;
  payment_method_options?: Maybe<Scalars['JSON']['output']>;
  payment_method_types: Array<Scalars['String']['output']>;
  payment_status: Scalars['String']['output'];
  phone_number_collection?: Maybe<Scalars['JSON']['output']>;
  recovered_from?: Maybe<Scalars['String']['output']>;
  setup_intent?: Maybe<Scalars['String']['output']>;
  shipping_address_collection?: Maybe<Scalars['JSON']['output']>;
  shipping_options: Scalars['JSON']['output'];
  shipping_rate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  submit_type?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<Scalars['String']['output']>;
  success_url: Scalars['String']['output'];
  total_details?: Maybe<Scalars['JSON']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface GqlStripeCoupon {
  amount_off?: Maybe<Scalars['Float']['output']>;
  applies_to?: Maybe<GqlStripeCouponAppliesTo>;
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
  percent_off?: Maybe<Scalars['Float']['output']>;
}

export interface GqlStripeCouponAppliesTo {
  products: Array<Scalars['String']['output']>;
}

export interface GqlStripeCreateSetupCheckoutSessionInput {
  cancelUrl: Scalars['String']['input'];
  successUrl: Scalars['String']['input'];
}

export interface GqlStripeCreateSubscriptionCheckoutSessionInput {
  cancelUrl: Scalars['String']['input'];
  price: Scalars['String']['input'];
  successUrl: Scalars['String']['input'];
}

export interface GqlStripeCustomer {
  address?: Maybe<GqlStripeAddress>;
  balance: Scalars['Float']['output'];
  created: Scalars['Float']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  default_source?: Maybe<Scalars['String']['output']>;
  delinquent?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['JSONObject']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice_prefix?: Maybe<Scalars['String']['output']>;
  invoice_settings: GqlStripeCustomerInvoiceSettings;
  livemode: Scalars['Boolean']['output'];
  metadata: Scalars['JSONObject']['output'];
  name?: Maybe<Scalars['String']['output']>;
  next_invoice_sequence?: Maybe<Scalars['Float']['output']>;
  object: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  preferred_locales?: Maybe<Array<Scalars['String']['output']>>;
  shipping?: Maybe<Scalars['JSONObject']['output']>;
  sources?: Maybe<Scalars['JSONObject']['output']>;
  subscriptions?: Maybe<Scalars['JSONObject']['output']>;
  tax_exempt?: Maybe<Scalars['String']['output']>;
  tax_ids?: Maybe<Scalars['JSONObject']['output']>;
}

export interface GqlStripeCustomerInvoiceSettings {
  default_payment_method?: Maybe<Scalars['String']['output']>;
}

export interface GqlStripeDiscount {
  coupon: GqlStripeCoupon;
  customer?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  invoice?: Maybe<Scalars['String']['output']>;
  invoice_item?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
  promotion_code?: Maybe<Scalars['String']['output']>;
  start: Scalars['Float']['output'];
  subscription?: Maybe<Scalars['String']['output']>;
}

export interface GqlStripeInvoice {
  account_country?: Maybe<Scalars['String']['output']>;
  account_name?: Maybe<Scalars['String']['output']>;
  amount_due: Scalars['Float']['output'];
  amount_paid: Scalars['Float']['output'];
  amount_remaining: Scalars['Float']['output'];
  application?: Maybe<Scalars['String']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  attempt_count: Scalars['Float']['output'];
  attempted: Scalars['Boolean']['output'];
  auto_advance?: Maybe<Scalars['Boolean']['output']>;
  created: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  customer_address?: Maybe<GqlStripeAddress>;
  customer_email?: Maybe<Scalars['String']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  customer_phone?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['Float']['output']>;
  effective_at?: Maybe<Scalars['Float']['output']>;
  ending_balance?: Maybe<Scalars['Float']['output']>;
  footer?: Maybe<Scalars['String']['output']>;
  hosted_invoice_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice_pdf?: Maybe<Scalars['String']['output']>;
  last_finalization_error?: Maybe<Scalars['JSON']['output']>;
  lineItems: Array<GqlStripeInvoiceLineItem>;
  livemode: Scalars['Boolean']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  next_payment_attempt?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  paid_out_of_band: Scalars['Boolean']['output'];
  payment_intent?: Maybe<Scalars['String']['output']>;
  period_end: Scalars['Float']['output'];
  period_start: Scalars['Float']['output'];
  post_payment_credit_notes_amount: Scalars['Float']['output'];
  pre_payment_credit_notes_amount: Scalars['Float']['output'];
  quote?: Maybe<Scalars['String']['output']>;
  receipt_number?: Maybe<Scalars['String']['output']>;
  starting_balance: Scalars['Float']['output'];
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  status?: Maybe<GqlStripeInvoiceStatus>;
  status_transitions: GqlStripeInvoiceStatusTransitions;
  subscription?: Maybe<Scalars['String']['output']>;
  subscription_proration_date?: Maybe<Scalars['Float']['output']>;
  subtotal: Scalars['Float']['output'];
  tax?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
  webhooks_delivered_at?: Maybe<Scalars['Float']['output']>;
}

export interface GqlStripeInvoiceLineItem {
  amount: Scalars['Float']['output'];
  amount_excluding_tax?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discount_amounts?: Maybe<Scalars['JSON']['output']>;
  discountable: Scalars['Boolean']['output'];
  discounts: Scalars['JSON']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  invoice_item?: Maybe<Scalars['String']['output']>;
  livemode: Scalars['Boolean']['output'];
  metadata: Scalars['JSON']['output'];
  object: Scalars['String']['output'];
  period: Scalars['JSON']['output'];
  plan?: Maybe<Scalars['JSON']['output']>;
  price?: Maybe<Scalars['JSON']['output']>;
  proration: Scalars['Boolean']['output'];
  proration_details?: Maybe<Scalars['JSON']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  subscription?: Maybe<Scalars['String']['output']>;
  subscription_item?: Maybe<Scalars['String']['output']>;
  tax_amounts?: Maybe<Scalars['JSON']['output']>;
  tax_rates?: Maybe<Scalars['JSON']['output']>;
  type: Scalars['String']['output'];
  unit_amount_excluding_tax?: Maybe<Scalars['String']['output']>;
}

export enum GqlStripeInvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void'
}

export interface GqlStripeInvoiceStatusTransitions {
  finalized_at?: Maybe<Scalars['Int']['output']>;
  marked_uncollectible_at?: Maybe<Scalars['Int']['output']>;
  paid_at?: Maybe<Scalars['Int']['output']>;
  voided_at?: Maybe<Scalars['Int']['output']>;
}

export interface GqlStripeLineItem {
  amount_discount: Scalars['Float']['output'];
  amount_subtotal: Scalars['Float']['output'];
  amount_tax: Scalars['Float']['output'];
  amount_total: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  discounts?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  object: Scalars['String']['output'];
  price?: Maybe<GqlStripePrice>;
  quantity?: Maybe<Scalars['Float']['output']>;
  taxes?: Maybe<Scalars['JSON']['output']>;
}

export interface GqlStripeLineItemList {
  data: Array<GqlStripeLineItem>;
  has_more: Scalars['Boolean']['output'];
  object: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export interface GqlStripePaymentIntent {
  amount: Scalars['Float']['output'];
  amount_capturable: Scalars['Float']['output'];
  amount_details: Scalars['JSON']['output'];
  amount_received: Scalars['Float']['output'];
  application?: Maybe<Scalars['String']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  automatic_payment_methods?: Maybe<Scalars['JSON']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  cancellation_reason?: Maybe<Scalars['String']['output']>;
  capture_method: Scalars['String']['output'];
  client_secret: Scalars['String']['output'];
  confirmation_method: Scalars['String']['output'];
  created: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  customer: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoice?: Maybe<Scalars['String']['output']>;
  last_payment_error?: Maybe<Scalars['JSON']['output']>;
  latest_charge?: Maybe<Scalars['String']['output']>;
  metadata: Scalars['JSON']['output'];
  next_action?: Maybe<Scalars['JSON']['output']>;
  object: Scalars['String']['output'];
  on_behalf_of?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_method_options: Scalars['JSON']['output'];
  payment_method_types: Array<Scalars['String']['output']>;
  processing?: Maybe<Scalars['JSON']['output']>;
  receipt_email?: Maybe<Scalars['String']['output']>;
  review?: Maybe<Scalars['String']['output']>;
  setup_future_usage?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
}

export interface GqlStripePaymentMethod {
  billing_details: Scalars['JSON']['output'];
  card?: Maybe<GqlStripePmCard>;
  created: Scalars['Float']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<GqlStripePmLink>;
  livemode: Scalars['Boolean']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  object: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface GqlStripePmCard {
  brand: Scalars['String']['output'];
  checks?: Maybe<Scalars['JSON']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  display_brand?: Maybe<Scalars['String']['output']>;
  exp_month: Scalars['Float']['output'];
  exp_year: Scalars['Float']['output'];
  fingerprint?: Maybe<Scalars['String']['output']>;
  funding: Scalars['String']['output'];
  iin?: Maybe<Scalars['String']['output']>;
  issuer?: Maybe<Scalars['String']['output']>;
  last4: Scalars['String']['output'];
  networks?: Maybe<Scalars['JSON']['output']>;
  three_d_secure_usage?: Maybe<Scalars['JSON']['output']>;
  wallet?: Maybe<Scalars['JSON']['output']>;
}

export interface GqlStripePmLink {
  email?: Maybe<Scalars['String']['output']>;
}

export interface GqlStripePrice {
  active: Scalars['Boolean']['output'];
  billing_scheme: Scalars['String']['output'];
  created: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  livemode: Scalars['Boolean']['output'];
  lookup_key?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
  product: Scalars['String']['output'];
  recurring?: Maybe<GqlStripePriceRecurring>;
  tax_behavior?: Maybe<Scalars['String']['output']>;
  tiers?: Maybe<Scalars['JSON']['output']>;
  tiers_mode?: Maybe<Scalars['String']['output']>;
  transform_quantity?: Maybe<Scalars['JSON']['output']>;
  type: Scalars['String']['output'];
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['String']['output']>;
}

export interface GqlStripePriceRecurring {
  aggregate_usage?: Maybe<Scalars['String']['output']>;
  interval: GqlStripeRecurringInterval;
  interval_count: Scalars['Float']['output'];
  trial_period_days?: Maybe<Scalars['Int']['output']>;
  usage_type: Scalars['String']['output'];
}

export interface GqlStripeProduct {
  active: Scalars['Boolean']['output'];
  attributes?: Maybe<Scalars['JSON']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  created: Scalars['Float']['output'];
  deactivate_on?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images: Scalars['JSON']['output'];
  livemode: Scalars['Boolean']['output'];
  metadata: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
  object: Scalars['String']['output'];
  package_dimensions?: Maybe<Scalars['JSON']['output']>;
  shippable?: Maybe<Scalars['Boolean']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  tax_code?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  unit_label?: Maybe<Scalars['String']['output']>;
  updated: Scalars['Float']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export interface GqlStripePromotionCode {
  active: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  coupon: GqlStripeCoupon;
  id: Scalars['ID']['output'];
  object: Scalars['String']['output'];
}

export interface GqlStripeQuote {
  amount_subtotal: Scalars['Float']['output'];
  amount_total: Scalars['Float']['output'];
  application?: Maybe<Scalars['String']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  collection_method: Scalars['String']['output'];
  computed: GqlQuoteComputed;
  created: Scalars['Float']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  default_tax_rates?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discounts: Scalars['JSON']['output'];
  expires_at: Scalars['Float']['output'];
  footer?: Maybe<Scalars['String']['output']>;
  from_quote?: Maybe<Scalars['JSON']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice?: Maybe<Scalars['String']['output']>;
  invoice_settings: Scalars['JSON']['output'];
  line_items?: Maybe<GqlStripeLineItemList>;
  livemode: Scalars['Boolean']['output'];
  metadata: Scalars['JSON']['output'];
  number?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
  on_behalf_of?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  status_transitions: Scalars['JSON']['output'];
  subscription?: Maybe<Scalars['String']['output']>;
  subscription_data: Scalars['JSON']['output'];
  subscription_schedule?: Maybe<Scalars['String']['output']>;
  total_details: GqlQuoteTotalDetails;
  transfer_data?: Maybe<Scalars['JSON']['output']>;
}

export interface GqlStripeQuoteSetPromoInput {
  couponId: Scalars['String']['input'];
  quoteId: Scalars['String']['input'];
}

export enum GqlStripeRecurringInterval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year'
}

export interface GqlStripeSubscription {
  application: Scalars['String']['output'];
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  billing_cycle_anchor: Scalars['Float']['output'];
  billing_thresholds?: Maybe<Scalars['JSON']['output']>;
  cancel_at?: Maybe<Scalars['Float']['output']>;
  cancel_at_period_end: Scalars['Boolean']['output'];
  canceled_at?: Maybe<Scalars['Float']['output']>;
  collection_method: Scalars['String']['output'];
  created: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  current_period_end: Scalars['Float']['output'];
  current_period_start: Scalars['Float']['output'];
  customer: Scalars['String']['output'];
  days_until_due?: Maybe<Scalars['Float']['output']>;
  default_payment_method?: Maybe<Scalars['String']['output']>;
  default_source?: Maybe<Scalars['String']['output']>;
  default_tax_rates?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<GqlStripeDiscount>;
  ended_at?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  items: GqlStripeSubscriptionItems;
  latest_invoice?: Maybe<Scalars['String']['output']>;
  livemode: Scalars['Boolean']['output'];
  metadata: Scalars['JSON']['output'];
  next_pending_invoice_item_invoice?: Maybe<Scalars['Float']['output']>;
  object: Scalars['String']['output'];
  pause_collection?: Maybe<Scalars['JSON']['output']>;
  payment_settings?: Maybe<Scalars['JSON']['output']>;
  pending_invoice_item_interval?: Maybe<Scalars['JSON']['output']>;
  pending_setup_intent?: Maybe<Scalars['String']['output']>;
  pending_update?: Maybe<Scalars['JSON']['output']>;
  schedule?: Maybe<Scalars['String']['output']>;
  start_date: Scalars['Float']['output'];
  status: GqlStripeSubscriptionStatus;
  subscriptionItems: Array<GqlStripeSubscriptionItem>;
  transfer_data?: Maybe<Scalars['JSON']['output']>;
  trial_end?: Maybe<Scalars['Float']['output']>;
  trial_start?: Maybe<Scalars['Float']['output']>;
}

export interface GqlStripeSubscriptionItem {
  billing_thresholds?: Maybe<Scalars['JSON']['output']>;
  created: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  metadata: Scalars['JSON']['output'];
  object: Scalars['String']['output'];
  plan: Scalars['JSON']['output'];
  price: GqlStripePrice;
  quantity?: Maybe<Scalars['JSON']['output']>;
  subscription: Scalars['JSON']['output'];
  tax_rates?: Maybe<Scalars['JSON']['output']>;
}

export interface GqlStripeSubscriptionItems {
  data: Array<GqlStripeSubscriptionItem>;
  has_more: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
}

export enum GqlStripeSubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Paused = 'paused',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

export interface GqlStripeUpcomingInvoice {
  account_country?: Maybe<Scalars['String']['output']>;
  account_name?: Maybe<Scalars['String']['output']>;
  amount_due: Scalars['Float']['output'];
  amount_paid: Scalars['Float']['output'];
  amount_remaining: Scalars['Float']['output'];
  application?: Maybe<Scalars['String']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  attempt_count: Scalars['Float']['output'];
  attempted: Scalars['Boolean']['output'];
  auto_advance?: Maybe<Scalars['Boolean']['output']>;
  created: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  customer_address?: Maybe<GqlStripeAddress>;
  customer_email?: Maybe<Scalars['String']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  customer_phone?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['Float']['output']>;
  effective_at?: Maybe<Scalars['Float']['output']>;
  ending_balance?: Maybe<Scalars['Float']['output']>;
  footer?: Maybe<Scalars['String']['output']>;
  hosted_invoice_url?: Maybe<Scalars['String']['output']>;
  invoice_pdf?: Maybe<Scalars['String']['output']>;
  last_finalization_error?: Maybe<Scalars['JSON']['output']>;
  lineItems: Array<GqlStripeInvoiceLineItem>;
  livemode: Scalars['Boolean']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  next_payment_attempt?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  paid_out_of_band: Scalars['Boolean']['output'];
  period_end: Scalars['Float']['output'];
  period_start: Scalars['Float']['output'];
  post_payment_credit_notes_amount: Scalars['Float']['output'];
  pre_payment_credit_notes_amount: Scalars['Float']['output'];
  quote?: Maybe<Scalars['String']['output']>;
  receipt_number?: Maybe<Scalars['String']['output']>;
  starting_balance: Scalars['Float']['output'];
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  status_transitions: Scalars['JSON']['output'];
  subscription?: Maybe<Scalars['String']['output']>;
  subscription_proration_date?: Maybe<Scalars['Float']['output']>;
  subtotal: Scalars['Float']['output'];
  tax?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
  webhooks_delivered_at?: Maybe<Scalars['Float']['output']>;
}

export interface GqlStsMutationResponse {
  createdSts: Array<GqlShareTransaction>;
  deletedIds: Array<Scalars['ID']['output']>;
  deletedStgs?: Maybe<Array<GqlStgDeletedResponse>>;
  stgs?: Maybe<Array<GqlShareTransactionGroup>>;
  updatedSts: Array<GqlShareTransaction>;
}

export interface GqlSubscription {
  documentUpdated: GqlDocument;
  meetingParticipantUpdated: GqlMeetingParticipant;
}


export interface GqlSubscriptionDocumentUpdatedArgs {
  channel: Scalars['String']['input'];
}


export interface GqlSubscriptionMeetingParticipantUpdatedArgs {
  channel: Scalars['String']['input'];
}

export enum GqlSummonsEmailStatus {
  Blocked = 'blocked',
  Bounce = 'bounce',
  Bounced = 'bounced',
  Deferred = 'deferred',
  Delivered = 'delivered',
  Dropped = 'dropped',
  Processed = 'processed',
  Queued = 'queued',
  Rejected = 'rejected',
  Sent = 'sent',
  SoftBounced = 'softBounced',
  Spam = 'spam',
  Unsub = 'unsub'
}

export enum GqlSummonsSimplifiedState {
  Failed = 'failed',
  NotSent = 'notSent',
  Ok = 'ok',
  Pending = 'pending'
}

export interface GqlSutSubscription {
  activeProduct?: Maybe<GqlStripeProduct>;
  canUseProFeature: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  stripeSub?: Maybe<GqlStripeSubscription>;
}

export interface GqlTodo {
  createdAt: Scalars['Date']['output'];
  descriptionPrimaryLang?: Maybe<Scalars['String']['output']>;
  descriptionSecondaryLang?: Maybe<Scalars['String']['output']>;
  done: Scalars['Boolean']['output'];
  dueDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  identity?: Maybe<GqlIdentity>;
  identityId?: Maybe<Scalars['ID']['output']>;
  meetingTodo: GqlMeetingTodo;
  updatedAt: Scalars['Date']['output'];
}

/** Create todo */
export interface GqlTodoCreateInput {
  descriptionPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  descriptionSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  identityId?: InputMaybe<Scalars['ID']['input']>;
}

/** todo set done */
export interface GqlTodoSetDoneInput {
  done: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
}

/** Update todo */
export interface GqlTodoUpdateInput {
  descriptionPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  descriptionSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  identityId?: InputMaybe<Scalars['ID']['input']>;
}

export enum GqlTransactionKind {
  BlockPartition = 'BlockPartition',
  CurrencyChange = 'CurrencyChange',
  Issue = 'Issue',
  Merge = 'Merge',
  ShareClassConvert = 'ShareClassConvert',
  Split = 'Split',
  Transfer = 'Transfer',
  Unissue = 'Unissue'
}

export interface GqlUpdateCapTableInput {
  displayName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  pricePerShare?: InputMaybe<Scalars['String']['input']>;
  recordDate?: InputMaybe<Scalars['Date']['input']>;
  unregisteredShares?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlUpdateIdentityInput {
  address?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlUser {
  admin: Scalars['Boolean']['output'];
  communicationLanguage: Scalars['String']['output'];
  companyInvites: Array<GqlCompanyInvite>;
  companySuggestionsSyncedDate?: Maybe<Scalars['Date']['output']>;
  companyUsers: Array<GqlCompanyUser>;
  createdAt: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  emailMd5?: Maybe<Scalars['String']['output']>;
  hubspotObjectId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identities: Array<GqlIdentity>;
  jwtEpoch: Scalars['Int']['output'];
  lastSignInAt?: Maybe<Scalars['Date']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  optionsInShPortal: Scalars['Boolean']['output'];
  personalId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  signInCount: Scalars['Int']['output'];
  unconfirmedEmail?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
}

export interface GqlUserSendPromoInviteInput {
  email: Scalars['String']['input'];
  english: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  promoCode: Scalars['String']['input'];
}

export interface GqlUserSetContactDetailsInput {
  communicationLanguage: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
}

export interface GqlValidatorError {
  long?: Maybe<Scalars['String']['output']>;
  short?: Maybe<Scalars['String']['output']>;
}

export interface GqlValidatorMultilangError {
  en: GqlValidatorError;
  sv: GqlValidatorError;
}

export interface GqlValidatorStatus {
  completed: Scalars['Boolean']['output'];
  error?: Maybe<GqlValidatorMultilangError>;
  hasError: Scalars['Boolean']['output'];
  severity: GqlSeverity;
}

export interface GqlVestedAndAllocated {
  allocated: Scalars['String']['output'];
  identity: GqlIdentity;
  shareClass: GqlShareClassObject;
  subscribable: Scalars['String']['output'];
  total: Scalars['String']['output'];
  vested: Scalars['String']['output'];
}

export interface GqlWarrantBoardMember {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  identity?: Maybe<GqlIdentity>;
  identityId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  warrantProgram: GqlWarrantProgram;
  warrantProgramId: Scalars['ID']['output'];
}

export interface GqlWarrantDocument {
  createdAt: Scalars['Date']['output'];
  document: GqlDocument;
  documentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  warrantProgram: GqlWarrantProgram;
  warrantProgramId: Scalars['ID']['output'];
}

export interface GqlWarrantParticipant {
  claim?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  customVestingFullyConfigured: Scalars['Boolean']['output'];
  forcedRedemption: Scalars['Boolean']['output'];
  forcedSaleAtIpo: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identity: GqlIdentity;
  identityId: Scalars['ID']['output'];
  latestAgreementDoc?: Maybe<GqlDocument>;
  leaveOfAbsenceDays: Scalars['Int']['output'];
  optionsRegistration: GqlOptionsRegistration;
  optionsRegistrationId: Scalars['ID']['output'];
  paymentKind: Scalars['Int']['output'];
  paymentStatus: Scalars['Int']['output'];
  quantity?: Maybe<Scalars['String']['output']>;
  representatives: Array<GqlOptionsSubscriberRepresentative>;
  repurchaseVested: Scalars['Boolean']['output'];
  shareClass: Scalars['JSONObject']['output'];
  splitFactor: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  vestedPercentage: Scalars['String']['output'];
  vestedQuantity: Scalars['String']['output'];
  vestingAcceleration: Scalars['Boolean']['output'];
  vestingCliffDuration: Scalars['Int']['output'];
  vestingCustomKindPrimaryLang?: Maybe<Scalars['String']['output']>;
  vestingCustomKindSecondaryLang?: Maybe<Scalars['String']['output']>;
  vestingDuration: Scalars['Int']['output'];
  vestingFrequency: Scalars['Int']['output'];
  vestingKind: Scalars['Int']['output'];
  vestingOccasions: Array<GqlWarrantParticipantVestingOccasion>;
}

export interface GqlWarrantParticipantVestingOccasion {
  descriptionPrimaryLang?: Maybe<Scalars['String']['output']>;
  descriptionSecondaryLang?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isValid: Scalars['Boolean']['output'];
  quantity: Scalars['String']['output'];
  requirementPrimaryLang?: Maybe<Scalars['String']['output']>;
  requirementSecondaryLang?: Maybe<Scalars['String']['output']>;
  sortOrder: Scalars['Int']['output'];
  vestingDate?: Maybe<Scalars['Date']['output']>;
  warrantParticipantId: Scalars['ID']['output'];
}

export interface GqlWarrantProgram {
  agreementStatus: GqlAgreementStatus;
  antlerWise: Scalars['Boolean']['output'];
  boardsStatementDate?: Maybe<Scalars['Date']['output']>;
  boardsStatementEn?: Maybe<Scalars['String']['output']>;
  boardsStatementOption?: Maybe<GqlBoardsStatementOption>;
  boardsStatementSv?: Maybe<Scalars['String']['output']>;
  company: GqlCompany;
  companyId: Scalars['ID']['output'];
  contact?: Maybe<GqlIdentity>;
  createdAt: Scalars['Date']['output'];
  currency: GqlCurrency;
  decisionStatus: GqlDecisionStatus;
  description: Scalars['String']['output'];
  discountPricePerWarrant?: Maybe<Scalars['String']['output']>;
  forAdvisors: Scalars['Boolean']['output'];
  forBoardMembers: Scalars['Boolean']['output'];
  forEmployees: Scalars['Boolean']['output'];
  ibanAddress?: Maybe<Scalars['String']['output']>;
  ibanBank?: Maybe<Scalars['String']['output']>;
  ibanBic?: Maybe<Scalars['String']['output']>;
  ibanNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  illiquidityDiscount?: Maybe<Scalars['String']['output']>;
  invoiceKind?: Maybe<Scalars['String']['output']>;
  maxNumberOfOptions?: Maybe<Scalars['String']['output']>;
  meetingConcern?: Maybe<GqlMeetingConcern>;
  minRoundSizeAmount?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  niceFounders: Array<GqlNiceFounder>;
  nonCashStatementPl?: Maybe<Scalars['String']['output']>;
  nonCashStatementSl?: Maybe<Scalars['String']['output']>;
  optionsRegistrations: Array<GqlOptionsRegistration>;
  optionsSharesRegistrations: Array<GqlOptionsSharesRegistration>;
  paidForAt?: Maybe<Scalars['Date']['output']>;
  payBeforeDate?: Maybe<Scalars['Date']['output']>;
  paymentAccountKind: Scalars['Int']['output'];
  paymentAccountNumber?: Maybe<Scalars['String']['output']>;
  paymentKindCash: Scalars['Boolean']['output'];
  paymentKindNonCash: Scalars['Boolean']['output'];
  paymentKindOffset: Scalars['Boolean']['output'];
  paymentName?: Maybe<Scalars['String']['output']>;
  pricePerWarrant?: Maybe<Scalars['String']['output']>;
  primaryLang: Scalars['Boolean']['output'];
  registrationStatus: GqlRegistrationStatus;
  riksbankenRates?: Maybe<Array<GqlRiksbankenObservations>>;
  riskfreeInterestRate?: Maybe<Scalars['String']['output']>;
  secondaryLang: Scalars['Boolean']['output'];
  serviceKind?: Maybe<Scalars['Int']['output']>;
  shareClasses: Scalars['JSON']['output'];
  shareSubscribeEndDate?: Maybe<Scalars['Date']['output']>;
  shareSubscribeStartDate?: Maybe<Scalars['Date']['output']>;
  shareholdersAgreementKind?: Maybe<Scalars['Int']['output']>;
  signatory?: Maybe<GqlIdentity>;
  signatoryId?: Maybe<Scalars['String']['output']>;
  snrAuthorization?: Maybe<GqlSnrAuthorization>;
  snrAuthorizationId?: Maybe<Scalars['String']['output']>;
  snrBondId?: Maybe<Scalars['String']['output']>;
  snrCaseId?: Maybe<Scalars['ID']['output']>;
  strikePrice?: Maybe<Scalars['String']['output']>;
  strikePriceFromQuotaValue?: Maybe<Scalars['Boolean']['output']>;
  stripeInvoiceId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  useNetStrike: Scalars['Boolean']['output'];
  useRiksbankenRate?: Maybe<Scalars['Boolean']['output']>;
  usesStepper: Scalars['Boolean']['output'];
  valuationCompanyValue?: Maybe<Scalars['String']['output']>;
  valuationDate?: Maybe<Scalars['Date']['output']>;
  valuationMethod?: Maybe<Scalars['Float']['output']>;
  valuationNumberOfShares?: Maybe<Scalars['String']['output']>;
  valuationSccId?: Maybe<Scalars['String']['output']>;
  valuationShareIssueId?: Maybe<Scalars['String']['output']>;
  volatility?: Maybe<Scalars['String']['output']>;
  volatilityDate?: Maybe<Scalars['String']['output']>;
  volatilityDocument?: Maybe<Scalars['String']['output']>;
  warrantBoardMembers: Array<GqlWarrantBoardMember>;
  warrantDocuments: Array<GqlWarrantDocument>;
  warrantEndDate?: Maybe<Scalars['Date']['output']>;
  warrantKind: GqlWarrantProgramKind;
  warrantShareholders: Array<GqlWarrantShareholder>;
  warrantStartDate?: Maybe<Scalars['Date']['output']>;
  warrantSubscribeEndDate?: Maybe<Scalars['Date']['output']>;
  warrantsForQeso: Scalars['Boolean']['output'];
  wiseCeil?: Maybe<Scalars['String']['output']>;
  wiseDepBondsRecordDay?: Maybe<Scalars['Date']['output']>;
  wiseDependencyBonds: Array<GqlWiseDependencyBond>;
  wiseFloor?: Maybe<Scalars['String']['output']>;
  wiseFullyDiluted?: Maybe<Scalars['Boolean']['output']>;
}

/** Kind of warrant program */
export enum GqlWarrantProgramKind {
  Employee = 'Employee',
  Nice = 'Nice',
  Warrant = 'Warrant',
  Wise = 'Wise'
}

export interface GqlWarrantProgramsPagedResult {
  models: Array<GqlWarrantProgram>;
  totalCount: Scalars['Int']['output'];
}

export interface GqlWarrantShareholder {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  numberOfShares: Scalars['Int']['output'];
  proxy?: Maybe<GqlIdentity>;
  proxyId?: Maybe<Scalars['ID']['output']>;
  shareholder: GqlShareholder;
  shareholderId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  warrantProgram: GqlWarrantProgram;
  warrantProgramId: Scalars['ID']['output'];
}

export interface GqlWdbCreateInput {
  kind: Scalars['String']['input'];
  name: Scalars['String']['input'];
  numberOfOptions: Scalars['String']['input'];
  warrantProgramId: Scalars['ID']['input'];
}

export interface GqlWdbDeletedResponse {
  id: Scalars['ID']['output'];
  warrantProgram: GqlWarrantProgram;
}

export interface GqlWdbEditInput {
  id: Scalars['ID']['input'];
  kind: Scalars['String']['input'];
  name: Scalars['String']['input'];
  numberOfOptions: Scalars['String']['input'];
}

export interface GqlWdbResetResponse {
  deletedIds: Array<Scalars['ID']['output']>;
  warrantProgram: GqlWarrantProgram;
  wiseDebendencyBonds: Array<GqlWiseDependencyBond>;
}

export interface GqlWiseDependencyBond {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
  numberOfOptions: Scalars['String']['output'];
  snrBondId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['Date']['output'];
  warrantProgram: GqlWarrantProgram;
  warrantProgramId: Scalars['ID']['output'];
}

export interface GqlWiseValidatorErrors {
  convertiblePrice: GqlValidatorStatus;
  dates: GqlValidatorStatus;
  dilution: GqlValidatorStatus;
  duration: GqlValidatorStatus;
  language: GqlValidatorStatus;
  nonCash: GqlValidatorStatus;
  payByOffset: GqlValidatorStatus;
  paymentKind: GqlValidatorStatus;
  recordDay: GqlValidatorStatus;
}

export interface GqlWpAddShareClassInput {
  id: Scalars['ID']['input'];
  max: Scalars['String']['input'];
  min: Scalars['String']['input'];
  name: Scalars['String']['input'];
  vote_weight: Scalars['String']['input'];
}

/** Connect meeting */
export interface GqlWpConnectMeetingInput {
  id: Scalars['ID']['input'];
  meetingId: Scalars['ID']['input'];
  snrAuthorizationId?: InputMaybe<Scalars['ID']['input']>;
}

export interface GqlWpConnectMeetingResponse {
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  warrantProgram: GqlWarrantProgram;
}

/** Create warrant program */
export interface GqlWpCreateInput {
  antlerWise: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  discountPricePerWarrant: Scalars['String']['input'];
  illiquidityDiscount: Scalars['String']['input'];
  paymentAccountKind: Scalars['Int']['input'];
  primaryLang: Scalars['Boolean']['input'];
  secondaryLang: Scalars['Boolean']['input'];
  shareClasses: Scalars['JSON']['input'];
  useRiksbankenRate: Scalars['Boolean']['input'];
  warrantKind: GqlWarrantProgramKind;
  warrantsForQeso: Scalars['Boolean']['input'];
}

/** Create and connect meeting */
export interface GqlWpCreateMeetingInput {
  id: Scalars['ID']['input'];
  meetingCreate: GqlMeetingCreateInput;
  snrAuthorizationId?: InputMaybe<Scalars['ID']['input']>;
}

export interface GqlWpDecisionValidatorErrors {
  auditorsStatement: GqlValidatorStatus;
  boardsStatement: GqlValidatorStatus;
  meeting: GqlValidatorStatus;
}

export interface GqlWpDeleteShareClassInput {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}

export interface GqlWpDeletedResponse {
  aiDeletedResponses?: Maybe<Array<GqlAiDeletedResponse>>;
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  id: Scalars['ID']['output'];
  mcDeletedResponse?: Maybe<GqlMcDeletedResponse>;
  orDeletedResponses: Array<GqlOrDeletedResponse>;
  osrDeletedResponses: Array<GqlOsrDeletedResponse>;
}

/** Disconnect authorization, will also disconnect meeting if there is one connected */
export interface GqlWpDisconnectAuthorizationInput {
  id: Scalars['ID']['input'];
}

export interface GqlWpDisconnectAuthorizationResponse {
  aiDeletedResponses?: Maybe<Array<GqlAiDeletedResponse>>;
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  mcDeletedResponse?: Maybe<GqlMcDeletedResponse>;
  warrantProgram: GqlWarrantProgram;
}

/** Disconnect meeting */
export interface GqlWpDisconnectMeetingInput {
  id: Scalars['ID']['input'];
}

export interface GqlWpDisconnectMeetingResponse {
  aiDeletedResponses?: Maybe<Array<GqlAiDeletedResponse>>;
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  mcDeletedResponse?: Maybe<GqlMcDeletedResponse>;
  warrantProgram: GqlWarrantProgram;
}

export interface GqlWpEditShareClassInput {
  id: Scalars['ID']['input'];
  max: Scalars['String']['input'];
  min: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

/** Create warrant program from snr bond */
export interface GqlWpFromSnrBondInput {
  description: Scalars['String']['input'];
  snrBondId: Scalars['ID']['input'];
}

export interface GqlWpOptionsLeftInput {
  id: Scalars['ID']['input'];
  shareClassName: Scalars['String']['input'];
}

/** Set wpp boards statement */
export interface GqlWpSetBoardsStatementInput {
  boardsStatementDate?: InputMaybe<Scalars['Date']['input']>;
  boardsStatementEn?: InputMaybe<Scalars['String']['input']>;
  boardsStatementOption: GqlBoardsStatementOption;
  boardsStatementSv?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

export interface GqlWpSetBoardsStatementResponse {
  aiSetDoneResponse?: Maybe<GqlAiSetDoneResponse>;
  warrantProgram: GqlWarrantProgram;
}

/** Set wpp dates */
export interface GqlWpSetDatesInput {
  id: Scalars['ID']['input'];
  payBeforeDate?: InputMaybe<Scalars['Date']['input']>;
  shareSubscribeEndDate: Scalars['Date']['input'];
  shareSubscribeStartDate: Scalars['Date']['input'];
  warrantKind: GqlWarrantProgramKind;
  warrantSubscribeEndDate?: InputMaybe<Scalars['Date']['input']>;
}

/** Set wpp description */
export interface GqlWpSetDescriptionInput {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
}

/** Set wp duration */
export interface GqlWpSetDurationInput {
  duration: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  riksbankenRates?: InputMaybe<Array<GqlRiksbankenObservationsInput>>;
  riskfreeInterestRate?: InputMaybe<Scalars['String']['input']>;
  useRiksbankenRate?: InputMaybe<Scalars['Boolean']['input']>;
  warrantStartDate: Scalars['Date']['input'];
}

/** Set wp languages */
export interface GqlWpSetLanguageInput {
  id: Scalars['ID']['input'];
  primaryLang: Scalars['Boolean']['input'];
  secondaryLang: Scalars['Boolean']['input'];
}

/** Set max number of options */
export interface GqlWpSetMaxNumberOfOptionsInput {
  id: Scalars['ID']['input'];
  maxNumberOfOptions: Scalars['String']['input'];
}

export interface GqlWpSetNicePriceInput {
  id: Scalars['ID']['input'];
  illiquidityDiscount: Scalars['String']['input'];
  maxNumberOfOptions: Scalars['String']['input'];
  minRoundSizeAmount?: InputMaybe<Scalars['String']['input']>;
  pricePerWarrant: Scalars['String']['input'];
  wiseCeil: Scalars['String']['input'];
  wiseFloor: Scalars['String']['input'];
}

/** Set non cash statement */
export interface GqlWpSetNonCashStatementInput {
  id: Scalars['ID']['input'];
  nonCashStatementPl?: InputMaybe<Scalars['String']['input']>;
  nonCashStatementSl?: InputMaybe<Scalars['String']['input']>;
  paymentKind?: InputMaybe<Scalars['Int']['input']>;
}

export interface GqlWpSetNumberOfSharesInput {
  id: Scalars['ID']['input'];
  valuationNumberOfShares: Scalars['String']['input'];
}

/** Set wp payment kind */
export interface GqlWpSetPaymentKindInput {
  id: Scalars['ID']['input'];
  paymentKindCash: Scalars['Boolean']['input'];
  paymentKindNonCash: Scalars['Boolean']['input'];
  paymentKindOffset: Scalars['Boolean']['input'];
}

export interface GqlWpSetPaymentKindResponse {
  documents: Array<GqlDocument>;
  orDeletedResponses: Array<GqlOrDeletedResponse>;
  warrantProgram: GqlWarrantProgram;
  wppDeletedResponses: Array<GqlWppDeletedResponse>;
}

/** Set wp price per warrant and other values from playground */
export interface GqlWpSetPlaygroundInput {
  duration: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  illiquidityDiscount: Scalars['String']['input'];
  pricePerShare: Scalars['String']['input'];
  pricePerWarrant: Scalars['String']['input'];
  riksbankenRates?: InputMaybe<Array<GqlRiksbankenObservationsInput>>;
  riskfreeInterestRate: Scalars['String']['input'];
  strikePrice: Scalars['String']['input'];
  useRiksbankenRate: Scalars['Boolean']['input'];
  volatility: Scalars['String']['input'];
}

export interface GqlWpSetReasonInput {
  forAdvisors: Scalars['Boolean']['input'];
  forBoardMembers: Scalars['Boolean']['input'];
  forEmployees: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
}

/** Set wp strike price */
export interface GqlWpSetStrikePriceInput {
  id: Scalars['ID']['input'];
  strikePrice: Scalars['String']['input'];
  strikePriceFromQuotaValue?: InputMaybe<Scalars['Boolean']['input']>;
  useNetStrike: Scalars['Boolean']['input'];
}

/** Set wp valuation */
export interface GqlWpSetValuationInput {
  id: Scalars['ID']['input'];
  valuationCompanyValue: Scalars['String']['input'];
  valuationDate: Scalars['Date']['input'];
  valuationMethod: Scalars['Int']['input'];
  valuationNumberOfShares: Scalars['String']['input'];
  valuationSccId?: InputMaybe<Scalars['ID']['input']>;
  valuationShareIssueId?: InputMaybe<Scalars['ID']['input']>;
}

/** Set wp strike price */
export interface GqlWpSetVolatilityInput {
  id: Scalars['ID']['input'];
  volatility: Scalars['String']['input'];
}

export interface GqlWpSetWiseFullyDilutedInput {
  id: Scalars['ID']['input'];
  wiseFullyDiluted: Scalars['Boolean']['input'];
}

export interface GqlWpSetWisePriceInput {
  currency: GqlCurrency;
  id: Scalars['ID']['input'];
  illiquidityDiscount: Scalars['String']['input'];
  maxNumberOfOptions: Scalars['String']['input'];
  pricePerWarrant: Scalars['String']['input'];
  wiseCeil: Scalars['String']['input'];
  wiseFloor: Scalars['String']['input'];
}

export interface GqlWpSetWiseRecordDayInput {
  id: Scalars['ID']['input'];
  wiseDepBondsRecordDay: Scalars['Date']['input'];
}

export interface GqlWpValidatorErrors {
  dates: GqlValidatorStatus;
  duration: GqlValidatorStatus;
  language: GqlValidatorStatus;
  maxNumberOfOptions: GqlValidatorStatus;
  nonCash: GqlValidatorStatus;
  payByOffset: GqlValidatorStatus;
  paymentKind: GqlValidatorStatus;
  playground: GqlValidatorStatus;
  shareClasses: GqlValidatorStatus;
  strikePrice: GqlValidatorStatus;
  valuation: GqlValidatorStatus;
  volatility: GqlValidatorStatus;
}

/** Set wp start date */
export interface GqlWpaSetDurationInput {
  id: Scalars['ID']['input'];
  warrantEndDate: Scalars['Date']['input'];
  warrantStartDate: Scalars['Date']['input'];
}

/** Create share issue participant */
export interface GqlWpbmCreateInput {
  identityInput: GqlCreateIdentityInput;
  warrantProgramId: Scalars['ID']['input'];
}

export interface GqlWpbmDeletedResponse {
  id: Scalars['ID']['output'];
}

/** Create warrant participant */
export interface GqlWppCreateInput {
  claim?: InputMaybe<Scalars['String']['input']>;
  identityInput: GqlCreateIdentityInput;
  optionsRegistrationId: Scalars['ID']['input'];
  paymentKind: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['String']['input']>;
  shareClass: Scalars['JSONObject']['input'];
}

/** Delete warrant participant response */
export interface GqlWppDeletedResponse {
  documents: Array<GqlDocument>;
  id: Scalars['ID']['output'];
  osrDeletedResponses: Array<GqlOptionsSubscriberRepresentativeDeletedResponse>;
}

export interface GqlWppUpdateInput {
  claim?: InputMaybe<Scalars['String']['input']>;
  forcedRedemption?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  identityInput?: InputMaybe<GqlCreateIdentityInput>;
  leaveOfAbsenceDays?: InputMaybe<Scalars['Int']['input']>;
  paymentStatus?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['String']['input']>;
  repurchaseVested?: InputMaybe<Scalars['Boolean']['input']>;
  shareClass?: InputMaybe<Scalars['JSONObject']['input']>;
  vestingAcceleration?: InputMaybe<Scalars['Boolean']['input']>;
  vestingCliffDuration?: InputMaybe<Scalars['Int']['input']>;
  vestingCustomKindPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  vestingCustomKindSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  vestingDuration?: InputMaybe<Scalars['Int']['input']>;
  vestingFrequency?: InputMaybe<Scalars['Int']['input']>;
  vestingKind?: InputMaybe<Scalars['Int']['input']>;
}

/** Update warrant participant response */
export interface GqlWppUpdateResponse {
  documents: Array<GqlDocument>;
  warrantParticipant: GqlWarrantParticipant;
}

export interface GqlWppValidatorErrors {
  cliff: GqlValidatorStatus;
  paymentStatus: GqlValidatorStatus;
  proxy: GqlValidatorStatus;
}

/** Create vesting occassion */
export interface GqlWppVestingOccasionCreateInput {
  descriptionPrimaryLang: Scalars['String']['input'];
  descriptionSecondaryLang: Scalars['String']['input'];
  quantity: Scalars['String']['input'];
  requirementPrimaryLang: Scalars['String']['input'];
  requirementSecondaryLang: Scalars['String']['input'];
  sortOrder: Scalars['Int']['input'];
  vestingDate?: InputMaybe<Scalars['Date']['input']>;
  warrantParticipantId: Scalars['ID']['input'];
}

export interface GqlWppVestingOccasionUpdateInput {
  descriptionPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  descriptionSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  quantity?: InputMaybe<Scalars['String']['input']>;
  requirementPrimaryLang?: InputMaybe<Scalars['String']['input']>;
  requirementSecondaryLang?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  vestingDate?: InputMaybe<Scalars['Date']['input']>;
}

/** Create warrant participant */
export interface GqlWshCreateInput {
  numberOfShares: Scalars['Int']['input'];
  shareholderId: Scalars['ID']['input'];
  warrantProgramId: Scalars['ID']['input'];
}

export interface GqlWshDeletedResponse {
  id: Scalars['ID']['output'];
}

/** Import share state to warrant shareholder */
export interface GqlWshImportShareStateInput {
  date: Scalars['Date']['input'];
  warrantProgramId: Scalars['ID']['input'];
}

export interface GqlWshImportShareStateResponse {
  added: Array<GqlWarrantShareholder>;
  removed: Array<GqlWshDeletedResponse>;
}

/** set proxy */
export interface GqlWshSetProxyInput {
  id: Scalars['ID']['input'];
  proxyInput: GqlCreateIdentityInput;
}

export interface GqlWshUpdateResponse {
  documents: Array<GqlDocument>;
  warrantShareholder: GqlWarrantShareholder;
}

export enum GqlZignedAgreementStatus {
  Cancelled = 'cancelled',
  Draft = 'draft',
  FailedToGenerate = 'failed_to_generate',
  Fulfilled = 'fulfilled',
  Generating = 'generating',
  Open = 'open',
  Pending = 'pending'
}

export enum GqlZignedParticipantRole {
  Approver = 'approver',
  Signer = 'signer'
}

export enum GqlZignedParticipantStatus {
  Draft = 'draft',
  Fulfilled = 'fulfilled',
  Initiated = 'initiated',
  Pending = 'pending',
  Processing = 'processing',
  Rejected = 'rejected'
}

export enum GqlDecisionKind {
  BoardDecision = 'BoardDecision',
  BoardDecisionWithShApproval = 'BoardDecisionWithShApproval',
  ShareHoldersMeeting = 'ShareHoldersMeeting'
}
