import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { svMessages, enMessages } from '@startuptools/auth';
import { i18n, I18n } from '@lingui/core';

@Injectable({ providedIn: 'root' })
export class ReactI18n {
  private _allMessages = {
    en: enMessages,
    sv: svMessages,
  };

  private _i18n: I18n;

  constructor(@Inject(LOCALE_ID) localeId: string) {
    i18n.load(this._allMessages);
    i18n.activate(localeId);
    this._i18n = i18n;
  }

  get i18n() {
    return this._i18n;
  }
}
