import { Transform, plainToInstance } from 'class-transformer';

import { PaymentStatus } from '@startuptools/common/participants';
import { ShareClass } from '@startuptools/common/transactions';
import { jsonParse } from './utils';
import { Identity } from './identity.model';
import { GqlDocument, GqlOptionsSharesSubscriber } from '../graphql/operations';
import { AutoFields } from '../models';
import { OptionsSharesRegistrationId } from './options-shares-registration.model';
import { OptionsSharesSubscriberRepresentativeWithIncludes } from './options-shares-subscriber-representatives.model';

export interface OssWithIncludes {
  model: OptionsSharesSubscriber;
  identity?: Identity;
  representatives: OptionsSharesSubscriberRepresentativeWithIncludes[];
}

export type OptionsSharesSubscriberId = string;

export class OptionsSharesSubscriber
  implements Omit<GqlOptionsSharesSubscriber, 'identity' | 'representatives' | 'optionsSharesRegistration'>
{
  id: OptionsSharesSubscriberId;
  quantity: string;
  @Transform(({ value }) => plainToInstance(ShareClass, jsonParse(value as string)), { toClassOnly: true })
  shareClass: ShareClass;
  identityId: string;
  proxyId: string;
  paymentStatus: PaymentStatus;
  latestShareSubscriptionListDoc: GqlDocument | null;
  repurchaseVested: boolean;
  optionsSharesRegistrationId: OptionsSharesRegistrationId;
  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Omit<OptionsSharesSubscriber, AutoFields | 'identity'>) {
    Object.assign(this, seed);
  }
}
