import { Trans } from '@lingui/react';
import { Box, Modal, ModalClose, ModalDialog, Tabs, TabList, TabPanel, Tab } from '@startuptools/ui';
import { DialogContent } from '@mui/joy';
import { Companies } from './components/Companies';
import { Users } from './components/Users';
import { Shareholders } from './components/Shareholders';
import { AdminScriveDocuments } from './components/ScriveDocument/ScriveDocuments';

export const AdminDialog = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal open onClose={onClose}>
      <ModalDialog sx={{ margin: 2, width: '95%', maxWidth: '1600px', height: '90%' }}>
        <ModalClose />
        <DialogContent sx={{ marginTop: 3 }}>
          <Box sx={{ width: '95%', maxWidth: '1600px', height: '90%' }}>
            <Tabs>
              <TabList sticky="top">
                <Tab>
                  <Trans id="All Companies" />
                </Tab>
                <Tab>
                  <Trans id="All Users" />
                </Tab>
                <Tab>
                  <Trans id="All Shareholders" />
                </Tab>
                <Tab>
                  <Trans id="Pending Scrive Documents" />
                </Tab>
              </TabList>
              <TabPanel value={0}>
                <Companies onClose={onClose} />
              </TabPanel>
              <TabPanel value={1}>
                <Users onClose={onClose} />
              </TabPanel>
              <TabPanel value={2}>
                <Shareholders onClose={onClose} />
              </TabPanel>
              <TabPanel value={3}>
                <AdminScriveDocuments />
              </TabPanel>
            </Tabs>
          </Box>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};
