import { ArrayElement } from '@startuptools/common/common';
import { DocumentSignatureStatus } from '../../../components/document-signatures/DocumentSignatureStatus';
import { GqlScriveDocumentsQuery } from './ScriveDocuments.graphql';
import {
  Button,
  ButtonGroup,
  Card,
  DateFormat,
  ExpandLessIcon,
  ExpandMoreIcon,
  Gravatar,
  IconButton,
  RefreshIcon,
  SendIcon,
  Snackbar,
  Table,
} from '@startuptools/ui';
import { Trans, useLingui } from '@lingui/react';
import { useDocSignRefreshMutation } from '../../../components/document-signatures/DocumentSignatures.graphql';
import { useDocSendSignReminderMutation } from '../../../graphql/react-operations';
import { ReactNode, useState } from 'react';
import { IdentityDisplay } from '../../../components/identity/IdentityDisplay';

export const ScriveDocumentRow = ({ doc }: { doc: ArrayElement<GqlScriveDocumentsQuery['adminScriveDocuments']> }) => {
  const { i18n } = useLingui();

  const [open, setOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState<ReactNode | null>(null);
  const [doSendReminder, { loading: sendLoading }] = useDocSendSignReminderMutation();
  const [doRefresh, { loading: refreshLoading }] = useDocSignRefreshMutation();

  const handleSendReminder = async (doc: { companyId: string; id: string }) => {
    await doSendReminder({ variables: { companyId: doc.companyId, input: { id: doc.id } } });
    setShowSnackbar(<Trans id="Reminder to sign was sent!" />);
  };

  const handleRefresh = async (doc: { companyId: string; id: string }) => {
    await doRefresh({ variables: { companyId: doc.companyId, id: doc.id } });
    setShowSnackbar(<Trans id="The document signature status was refreshed!" />);
  };

  const loading = sendLoading || refreshLoading;

  return (
    <>
      <tr>
        <td>
          <IconButton variant="outlined" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </td>
        <td>{doc.filename}</td>
        <td>
          <DocumentSignatureStatus
            scriveStatus={doc.scriveStatus}
            zignedAgreementStatus={doc.zignedAgreementStatus}
            signatureMethod={doc.signatureMethod}
          />
        </td>
        <td>
          <DateFormat locale={i18n.locale}>{doc.scriveTimeoutTime}</DateFormat>
        </td>
        <td>
          <DateFormat locale={i18n.locale} relative>
            {doc.updatedAt}
          </DateFormat>
        </td>
        <td>
          <DateFormat locale={i18n.locale} relative>
            {doc.reminderSentAt}
          </DateFormat>
        </td>
        <td align="right">
          <ButtonGroup>
            <Button
              variant="outlined"
              size="sm"
              disabled={loading}
              onClick={() => handleRefresh(doc)}
              startDecorator={<RefreshIcon />}
            >
              <Trans id="Refresh" />
            </Button>
            <Button
              variant="outlined"
              size="sm"
              disabled={loading}
              onClick={() => handleSendReminder(doc)}
              startDecorator={<SendIcon />}
            >
              <Trans id="Reminder" />
            </Button>
          </ButtonGroup>
        </td>
      </tr>
      {open && (
        <>
          <tr>
            <td />
            <td>
              <IdentityDisplay name={doc.adminCompany.name} secondRow={doc.adminCompany.organizationNumber} />
            </td>
            <td colSpan={5}>{doc.adminCompany.email}</td>
          </tr>
          <tr>
            <td colSpan={7}>
              <Card>
                <Table sx={{ tableLayout: 'auto' }}>
                  <thead>
                    <tr>
                      <th>
                        <Trans id="User" />
                      </th>
                      <th>
                        <Trans id="Email" />
                      </th>
                      <th>
                        <Trans id="Phone" />
                      </th>
                    </tr>
                  </thead>
                  {doc.adminCompany.companyUsers.map(cu => (
                    <tr key={cu.id}>
                      <td>
                        <IdentityDisplay
                          name={cu.user.name}
                          secondRow={cu.user.personalId}
                          startDecorator={
                            <Gravatar md5Hash={cu.user.emailMd5} uuid={cu.user.id} size="sm">
                              {cu.user.name?.[0]}
                            </Gravatar>
                          }
                        />
                      </td>
                      <td>{cu.user.email}</td>
                      <td>{cu.user.phone}</td>
                    </tr>
                  ))}
                </Table>
              </Card>
            </td>
          </tr>
        </>
      )}
      <Snackbar
        open={Boolean(showSnackbar)}
        color="success"
        onClose={() => setShowSnackbar(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {showSnackbar}
      </Snackbar>
    </>
  );
};
