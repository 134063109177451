import { PaymentStatus, PaymentKind } from '@startuptools/common/participants';
import { Transform } from 'class-transformer';
import { toNumber } from 'lodash-es';
import { BigNumber } from 'bignumber.js';
import { ShareClass } from '@startuptools/common/transactions';

import { jsonParse, jsonStringify } from './utils';
import { Identity } from './identity.model';
import type { ShareIssue } from './share-issue.model';
import { GqlShareIssueParticipant } from '../graphql/operations';

export interface SipWithIncludes {
  model: ShareIssueParticipant;
  identity: Identity;
  proxy?: Identity;
}

export class ShareIssueParticipant implements Omit<GqlShareIssueParticipant, 'identity' | 'shareIssueRegistration'> {
  id: string;

  numberOfShares?: string;
  numberOfSharesMax?: string;

  @Transform(({ value }: { value: string }) => jsonParse(value), {
    toClassOnly: true,
  })
  @Transform(({ value }) => jsonStringify(value), { toPlainOnly: true })
  shareClass: ShareClass;
  identityId: string;
  proxyId?: string;
  shareIssueRegistrationId: string;
  paymentStatus: PaymentStatus;
  paymentKind: PaymentKind;
  paymentDate?: string;
  claim?: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Partial<ShareIssueParticipant>) {
    Object.assign(this, seed);
  }

  getAmountToPay(shareIssue: ShareIssue): number {
    return toNumber(new BigNumber(this.numberOfShares || 0).times(shareIssue.pricePerShare || '0').decimalPlaces(2));
  }
}
