import { Trans } from '@lingui/react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  FileUploadInput,
  Checkbox,
  DialogActions,
  Button,
  GenericError,
} from '@startuptools/ui';
import { Controller, useForm } from 'react-hook-form';

export type UploadDocForm = {
  file: File;
  signDigitally: boolean;
};

export const DocumentCreateDialog = ({
  onClose,
  upload,
  showExistingDocWarning,
  showSigningWarning,
  mode,
  allowDigitalSignature,
}: {
  onClose: () => void;
  upload: (data: UploadDocForm) => Promise<unknown>;
  showExistingDocWarning: boolean;
  showSigningWarning: boolean;
  mode: 'upload' | 'generate';
  allowDigitalSignature: boolean;
}) => {
  const { register, handleSubmit, control, formState, clearErrors } = useForm<UploadDocForm>();

  return (
    <Dialog loading={formState.isSubmitting}>
      <DialogTitle onClose={onClose}>
        {mode === 'upload' ? <Trans id="Upload document" /> : <Trans id="Create document" />}
      </DialogTitle>
      <form onSubmit={handleSubmit(upload)}>
        <DialogContent>
          {(showExistingDocWarning || showSigningWarning) && (
            <Alert color="warning">
              {showSigningWarning ? (
                <Trans id="A signature process is already ongoing. If you continue, the old process will be cancelled." />
              ) : (
                <Trans id="A document has already been created. Continuing here will replace the document." />
              )}
            </Alert>
          )}

          {mode === 'upload' && (
            <Controller
              render={({ field: { onChange } }) => {
                return (
                  <FileUploadInput
                    onChange={e => {
                      clearErrors();
                      return onChange(e);
                    }}
                    invalid={Boolean(formState.errors.file)}
                    accept={{
                      'application/pdf': ['.pdf'],
                    }}
                  />
                );
              }}
              rules={{ required: true }}
              control={control}
              name="file"
            />
          )}
          {allowDigitalSignature && (
            <Checkbox
              label={<Trans id="The document will be signed digitally" />}
              defaultChecked
              {...register('signDigitally')}
            />
          )}
          {formState.errors.file && (
            <Alert color="warning">
              <Trans id="Select a file before you continue" />
            </Alert>
          )}
          {formState.isSubmitted && !formState.isSubmitSuccessful && !formState.errors.file && <GenericError />}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} disabled={formState.isSubmitting}>
            <Trans id="Cancel" />
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            <Trans id="Continue" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
