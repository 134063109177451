import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { agendaItemDecisionsReducer } from './agenda-item-decisions.reducers';
import { agendaItemsReducer } from './agenda-items.reducers';
import { articlesOfAssocDocumentsReducer } from './articles-of-assoc-documents.reducers';
import { articlesOfAssocsReducer } from './articles-of-assocs.reducers';
import { authorizationGroupDocumentsReducer } from './authorization-groups-documents.reducers';
import { authorizationGroupsReducer } from './authorization-groups.reducers';
import { authorizationsReducer } from './authorizations.reducers';
import { companiesReducer } from './companies.reducers';
import { companyDirectorsReducer } from './company-directors.reducers';
import { documentsReducer } from './documents.reducers';
import { identitiesReducer } from './identities.reducers';
import { meetingAgendaTemplateItemsReducer } from './meeting-agenda-template-items.reducers';
import { meetingAgendaTemplatesReducer } from './meeting-agenda-templates.reducers';
import { meetingConcernsReducer } from './meeting-concerns.reducers';
import { meetingDocumentsReducer } from './meeting-documents.reducers';
import { meetingParticipantsReducer } from './meeting-participants.reducers';
import { meetingsReducer } from './meetings.reducers';
import { niceFoundersReducer } from './nice-founders.reducers';
import { optionsRegistrationSignatoriesReducer } from './options-registration-signatory.reducers';
import { optionsRegistrationsReducer } from '../../store/reducers/options-registrations.reducers';
import { optionsSharesRegistrationsReducer } from './options-shares-registrations.reducers';
import { optionsSharesSubscribersReducer } from './options-shares-subscriber.reducers';
import { optionsSubscriberRepresentativesReducer } from './options-subscriber-representative.reducers';
import { shareIssueBoardMembersReducer } from './share-issue-board-members.reducers';
import { shareIssueDocumentsReducer } from './share-issue-documents.reducer';
import { shareIssueParticipantsReducer } from './share-issue-participants.reducers';
import { shareIssuesReducer } from './share-issues.reducers';
import { shareIssueRegistrationsReducer } from './share-issue-registrations.reducers';
import { shareTransactionGroupsReducer } from './share-transaction-groups.reducers';
import { shareTransactionsReducer } from './share-transactions.reducers';
import { shareholdersReducer } from './shareholders.reducers';
import { snrAuthorizationsReducer } from './snr-authorizations.reducers';
import { snrBondsReducer } from './snr-bonds.reducers';
import { snrCasesReducer } from './snr-cases.reducers';
import { snrShareCapitalChangesReducer } from './snr-share-capital-changes.reducers';
import { usersReducer } from './users.reducers';
import { warrantBoardMembersReducer } from './warrant-board-members.reducers';
import { warrantParticipantsReducer } from './warrant-participants.reducers';
import { warrantProgramDocumentsReducer } from './warrant-program-documents.reducers';
import { warrantProgramsReducer } from './warrant-programs.reducers';
import { warrantShareholdersReducer } from './warrant-shareholders.reducers';
import { wiseDependencyBondReducer } from './wise-dependency-bonds.reducers';
import { optionsSharesSubscriberRepresentativesReducer } from './options-shares-subscriber-representative.reducers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppState = Record<string, any>;

export const reducers: ActionReducerMap<AppState> = {
  agendaItemDecisions: agendaItemDecisionsReducer,
  agendaItems: agendaItemsReducer,
  articlesOfAssocDocuments: articlesOfAssocDocumentsReducer,
  articlesOfAssocs: articlesOfAssocsReducer,
  authorizationGroupDocuments: authorizationGroupDocumentsReducer,
  authorizationGroups: authorizationGroupsReducer,
  authorizations: authorizationsReducer,
  companies: companiesReducer,
  companyDirectors: companyDirectorsReducer,
  documents: documentsReducer,
  identities: identitiesReducer,
  meetingAgendaTemplateItems: meetingAgendaTemplateItemsReducer,
  meetingAgendaTemplates: meetingAgendaTemplatesReducer,
  meetingConcerns: meetingConcernsReducer,
  meetingDocuments: meetingDocumentsReducer,
  meetingParticipants: meetingParticipantsReducer,
  meetings: meetingsReducer,
  niceFounders: niceFoundersReducer,
  optionsRegistrationSignatories: optionsRegistrationSignatoriesReducer,
  optionsRegistrations: optionsRegistrationsReducer,
  optionsSharesRegistrations: optionsSharesRegistrationsReducer,
  optionsSharesSubscribers: optionsSharesSubscribersReducer,
  optionsSharesSubscriberRepresentatives: optionsSharesSubscriberRepresentativesReducer,
  optionsSubscriberRepresentatives: optionsSubscriberRepresentativesReducer,
  shareIssueBoardMembers: shareIssueBoardMembersReducer,
  shareIssueDocuments: shareIssueDocumentsReducer,
  shareIssueParticipants: shareIssueParticipantsReducer,
  shareIssues: shareIssuesReducer,
  shareIssueRegistrations: shareIssueRegistrationsReducer,
  shareTransactionGroups: shareTransactionGroupsReducer,
  shareTransactions: shareTransactionsReducer,
  shareholders: shareholdersReducer,
  snrAuthorizations: snrAuthorizationsReducer,
  snrBonds: snrBondsReducer,
  snrCases: snrCasesReducer,
  snrShareCapitalChanges: snrShareCapitalChangesReducer,
  users: usersReducer,
  warrantBoardMembers: warrantBoardMembersReducer,
  warrantParticipants: warrantParticipantsReducer,
  warrantProgramDocuments: warrantProgramDocumentsReducer,
  warrantPrograms: warrantProgramsReducer,
  warrantShareholders: warrantShareholdersReducer,
  wiseDependencyBonds: wiseDependencyBondReducer,
};

export const metaReducers: MetaReducer<AppState>[] = [];
