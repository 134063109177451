import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ReactWrapperComponent } from '../react-wrapper/react-wrapper.component';
import { SideNav } from './SideNav';
import { ReactI18n } from '../../injectables/react-i18n.service';
import { SubSink } from 'subsink';
import { selectCompany } from '../../store/selectors/companies.base';
import { Store, select } from '@ngrx/store';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  of,
  shareReplay,
  startWith,
  tap,
  throwError,
} from 'rxjs';
import { AppState } from '../../store/reducers';
import { CommonModule } from '@angular/common';
import { NextSccs, nextSccs } from '../../company/share-register/transactions/utils/next-scc';
import { shareTransactionsPlus } from '../../helpers/extended-transaction';
import { selectStgsWithIncludes } from '../../store/selectors/share-transaction-groups.selectors';
import { selectStsWithIncludes } from '../../store/selectors/share-transactions.selectors';
import { selectSnrShareCapitalChanges } from '../../store/selectors/snr-share-capital-changes.selectors';
import { orderBy, isNil } from 'lodash-es';
import { completeSccs, sortSnrSccs } from '@startuptools/common/snr-sccs';
import { selectCurrentUser } from '../../store/selectors/user.selectors';
import { AlertConfirmDialogComponent } from '../simple-dialogs/alert-confirm-dialog.component';
import { GqlDispatchService } from '../../injectables/gql-dispatch.service';
import { MatDialog } from '@angular/material/dialog';
import { companiesActions } from '../../store/actions/companies.actions';
import { GqlCompanyDeleteService } from '../../graphql/operations';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  standalone: true,
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactWrapperComponent, CommonModule],
})
export class SideNavWrapperComponent implements OnDestroy {
  SideNav = SideNav;
  subs = new SubSink();
  public navigate = new EventEmitter<string>();

  public company$ = this.store.pipe(
    select(selectCompany),
    distinctUntilChanged(),
    filter(e => !isNil(e)),
  );
  user$ = this.store.pipe(
    select(selectCurrentUser),
    filter(e => !isNil(e)),
  );

  public angularPathname$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    map(e => e.url),
    startWith(this.router.url),
    distinctUntilChanged(),
  );
  public isAntlerCompany$ = this.company$.pipe(
    map(company => company && company.isAntlerCompany),
    distinctUntilChanged(),
  );

  stgs$ = this.store.pipe(select(selectStgsWithIncludes)).pipe(
    map(stgs => orderBy(stgs, [stg => stg.model.transactionDate, stg => stg.model.sortOrder], ['desc', 'desc'])),
    shareReplay(0),
  );

  snrSccs$ = this.store.pipe(select(selectSnrShareCapitalChanges)).pipe(
    map(sccs => sortSnrSccs(sccs, 'asc')),
    shareReplay(0),
  );

  stsWithIncludes$ = this.store.pipe(select(selectStsWithIncludes())).pipe(
    map(stgs => orderBy(stgs, [stg => stg.model.createdAt], ['asc'])),
    shareReplay(0),
  );

  stps$ = combineLatest([this.stsWithIncludes$, this.stgs$]).pipe(
    map(([sts, stgs]) => {
      return shareTransactionsPlus(sts, stgs);
    }),
    shareReplay(0),
  );

  sccsWithSplits$ = combineLatest([this.snrSccs$, this.stps$]).pipe(
    map(([sccs, stps]) => {
      return completeSccs(sccs, stps);
    }),
    shareReplay(0),
  );

  stsGroups$ = this.stps$.pipe(
    map(sts =>
      sts.reduce((grouped, st) => {
        const key = st.shareTransactionGroupId;
        // @ts-expect-error TS7053
        if (!grouped[key]) {
          // @ts-expect-error TS7053
          grouped[key] = [];
        }
        // @ts-expect-error TS7053
        grouped[key].push(st);
        return grouped;
      }, {}),
    ),
    shareReplay(0),
  );

  nextSnrSccs$ = combineLatest([this.stgs$, this.stsGroups$, this.sccsWithSplits$]).pipe(
    map(([stgs, stsGroups, sccs]) => {
      if (sccs.length === 0) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return {} as NextSccs;
      }
      return nextSccs(stgs, stsGroups, sccs);
    }),
    shareReplay(0),
  );

  public shareRegisterNotUpdated$ = this.nextSnrSccs$.pipe(
    map(nextSccs => !isNil(nextSccs.next)),
    shareReplay(0),
  );

  showDeleteCompanyDialog = () => {
    this.company$.pipe(first()).subscribe(company => {
      AlertConfirmDialogComponent.open(this.dialog, {
        cancel: $localize`Avbryt`,
        confirm: $localize`Ta bort företaget`,
        title: $localize`Ta bort ${company.name}`,
        question: $localize`Är du säker på att du vill ta bort företaget?`,
        text: $localize`Företaget och alla data kopplat till det kommer att tas bort permanent`,
        onSubmit: confirmed => {
          if (confirmed) {
            return this.gqlDispatch.mutate(this.gqlCompanyDelete, { companyId: company.id }).pipe(
              tap(() => {
                localStorage.removeItem('last_company_id');
                this.store.dispatch(companiesActions.oneDeleted({ payload: company.id }));
                void this.router.navigate(['/']);
                this.snackbar.open(`${company.name} togs bort permanent`, undefined, { duration: 5000 });
              }),
              catchError(err => {
                console.error(err);
                this.snackbar.open($localize`Misslyckades att ta bort företaget`, undefined, { duration: 5000 });
                return throwError(() => err);
              }),
            );
          }
          return of(null);
        },
      });
    });
  };

  constructor(
    private store: Store<AppState>,
    public i18n: ReactI18n,
    private router: Router,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private gqlDispatch: GqlDispatchService,
    private gqlCompanyDelete: GqlCompanyDeleteService,
    activeRoute: ActivatedRoute,
  ) {
    this.subs.sink = this.navigate.asObservable().subscribe(route => {
      void router.navigate(route.split('/'), { relativeTo: activeRoute.root });
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
