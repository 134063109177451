import { groupBy, toNumber } from 'lodash-es';

import { rangeCount } from '@startuptools/common/transactions';
import { CompleteScc } from '@startuptools/common/snr-sccs';
import { StgWithIncludes } from '../../../../models/share-transaction-group.model';
import { ShareTransactionPlus } from '../../../../helpers/extended-transaction';

export interface NextSccs {
  next: CompleteScc | undefined;
  prev: CompleteScc | undefined;
}
/**
 *
 * @param stgs
 * @param sts
 * @param sccs MUST BE ORDERED ASC
 */
export function nextSccs(
  stgs: StgWithIncludes[],
  sts: Record<string, ShareTransactionPlus[]>,
  sccs: CompleteScc[],
): NextSccs {
  const sccsWithChange = sccs.filter(scc => scc.changedNumberOfShares !== 0);
  if (stgs.length === 0) {
    return {
      prev: undefined,
      next: sccsWithChange[0],
    };
  }

  const stgsBySccId = groupBy(stgs, stg => stg.model.snrShareCapitalChangeId);

  for (let i = 0; i < sccsWithChange.length; i++) {
    const scc = sccsWithChange[i];
    if (scc.id in stgsBySccId) {
      const relevantStgs = stgsBySccId[scc.id].filter(stg => stg.model.kind === scc.kind);
      const allocated = relevantStgs.reduce((sum, stg) => {
        return (
          sum +
            sts[stg.model.id]?.reduce((s, st) => s + rangeCount(toNumber(st.blockStart), toNumber(st.blockEnd)), 0) ?? 0
        );
      }, 0);
      // @ts-expect-error TS2345
      if (Math.abs(scc.changedNumberOfShares) > allocated) {
        return {
          prev: sccsWithChange[i - 1],
          next: sccsWithChange[i],
        };
      }
    } else {
      // No stg connected to scc
      return {
        prev: sccsWithChange[i - 1],
        next: sccsWithChange[i],
      };
    }
  }
  // All sccs are filled
  return {
    prev: sccsWithChange[sccsWithChange.length - 1],
    next: undefined,
  };
}
export const isFoundation = (next: Pick<CompleteScc, 'name'>) => next.name.startsWith('NYBILDNING');
