import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  WritableSignal,
  signal,
} from '@angular/core';
import { ReactWrapperComponent } from '../react-wrapper/react-wrapper.component';
import { AddIdentity, AvailableInputStates } from './AddIdentity';
import { ReactI18n } from '../../injectables/react-i18n.service';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store/reducers';
import { selectCompanyId } from '../../store/selectors/companies.base';
import { isNil } from 'lodash-es';
import { distinctUntilChanged, filter } from 'rxjs';
import { DetailState } from './types';
import { GqlIdentityQuery } from '../../graphql/operations';
import { Identity, JuridicalKind } from '../../models/identity.model';
import { ComponentProps, ReactNode } from 'react';
import { Relations } from './InputSystem';
import { GqlCreateIdentityMutation } from '../../graphql/react-operations';

export interface AddIdentityDialogSettings {
  headline: ReactNode;
  juridicalKinds: JuridicalKind[];
  availableInputStates: AvailableInputStates;
  companyBoardOrgNumber?: string;
  identityExcludeList?: GqlIdentityQuery['identity'][] | Identity[];
  detailFieldsSettings?: {
    email?: DetailState;
    phone?: DetailState;
    address?: DetailState;
    countryCode?: DetailState;
  };
  relations?: Relations;
  handleCreate: (identity: GqlCreateIdentityMutation['createIdentity']) => Promise<unknown>;
}

@Component({
  standalone: true,
  imports: [CommonModule, ReactWrapperComponent],
  selector: 'app-add-identity-wrapper',
  template: `
    <app-react-wrapper
      *ngIf="openSignal()"
      [component]="AddIdentity"
      [props]="props()"
      [i18n]="i18n.i18n"
      [companyId]="companyId$ | async"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddIdentityWrapperComponent implements OnChanges {
  AddIdentity = AddIdentity;

  @Input() public openSignal: WritableSignal<boolean>;
  @Input() public settings: AddIdentityDialogSettings;

  props = signal<ComponentProps<typeof AddIdentity> | null>(null);

  companyId$ = this.store.pipe(
    select(selectCompanyId),
    filter(e => !isNil(e)),
    distinctUntilChanged(),
  );

  constructor(
    public i18n: ReactI18n,
    private store: Store<AppState>,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('settings' in changes) {
      this.props.set({
        ...this.settings,
        onClose: () => this.openSignal.set(false),
      });
    }
  }
}
