import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { JwtService } from '@startuptools/angular/auth';
import { Observable, of } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private jwtService: JwtService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if (this.jwtService.isValid()) {
      return of(true);
    }
    if (route.url.length >= 1) {
      this.snackBar.open($localize`Du har blivit utloggad på grund av inaktivitet`, undefined, { duration: 10000 });
    }
    return of(this.router.createUrlTree(['/auth'], { queryParams: route.queryParams }));
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    return this.canActivate(route);
  }
}
