import { Trans } from '@lingui/react';
import {
  Stack,
  Typography,
  CancelPresentationIcon,
  DoneAllIcon,
  ErrorIcon,
  HourglassEmptyIcon,
  ThumbDownIcon,
  TimerOffIcon,
} from '@startuptools/ui';
import { GqlScriveDocumentStatus, GqlZignedAgreementStatus } from '../../graphql/base-types.graphql';

const StatusIcon = ({ status }: { status: GqlScriveDocumentStatus | GqlZignedAgreementStatus }) => {
  switch (status) {
    case GqlScriveDocumentStatus.Pending:
    case GqlZignedAgreementStatus.Pending:
    case GqlZignedAgreementStatus.Generating:
    case GqlZignedAgreementStatus.Draft:
      return <HourglassEmptyIcon color="warning" />;
    case GqlScriveDocumentStatus.Closed:
    case GqlZignedAgreementStatus.Fulfilled:
      return <DoneAllIcon color="success" />;
    case GqlScriveDocumentStatus.Canceled:
    case GqlZignedAgreementStatus.FailedToGenerate:
      return <CancelPresentationIcon color="error" />;
    case GqlScriveDocumentStatus.Timedout:
      return <TimerOffIcon color="error" />;
    case GqlScriveDocumentStatus.Rejected:
    case GqlZignedAgreementStatus.Cancelled:
      return <ThumbDownIcon color="error" />;
    case GqlScriveDocumentStatus.DocumentError:
      return <ErrorIcon color="error" />;
    default:
      return null;
  }
};

const StatusText = ({ status }: { status: GqlScriveDocumentStatus | GqlZignedAgreementStatus }) => {
  switch (status) {
    case GqlScriveDocumentStatus.Preparation:
    case GqlZignedAgreementStatus.Draft:
    case GqlZignedAgreementStatus.Open:
      return <Trans id="Preparing" />;
    case GqlScriveDocumentStatus.Pending:
    case GqlZignedAgreementStatus.Pending:
      return <Trans id="Ongoing" />;
    case GqlScriveDocumentStatus.Closed:
    case GqlZignedAgreementStatus.Fulfilled:
      return <Trans id="Signed and ready" />;
    case GqlScriveDocumentStatus.Canceled:
    case GqlZignedAgreementStatus.Cancelled:
      return <Trans id="Cancelled" />;
    case GqlScriveDocumentStatus.Timedout:
      return <Trans id="One or more parties didn't sign the document in time" />;
    case GqlScriveDocumentStatus.Rejected:
      return <Trans id="one or more parties rejected the document" />;
    case GqlScriveDocumentStatus.DocumentError:
      return <Trans id="Invalid document" />;
    case GqlZignedAgreementStatus.Generating:
      return <Trans id="Finalizing" />;
    case GqlZignedAgreementStatus.FailedToGenerate:
      return <Trans id="Error, contact support" />;
    default:
      return null;
  }
};

export const SigningStatus = ({ status }: { status?: GqlScriveDocumentStatus | GqlZignedAgreementStatus }) => {
  if (!status) {
    return null;
  }
  return (
    <Stack direction="row">
      <StatusIcon status={status} />
      <Typography sx={{ whiteSpace: 'nowrap' }} fontWeight={600} fontSize={13} color="neutral">
        <Trans id="Signatures" />: <StatusText status={status} />
      </Typography>
    </Stack>
  );
};
