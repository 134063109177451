import lodash from 'lodash';

export function rangeCount(start: number | string, end: number | string): number {
  const startNumber = lodash.toNumber(start);
  const endNumber = lodash.toNumber(end);

  if (startNumber < 0 || endNumber < 0) {
    throw new Error('Only positive ranges allowed');
  }
  return endNumber - startNumber + 1;
}
