import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';
import { isNil, isNumber } from 'lodash-es';
import { ShareClass } from '@startuptools/common/transactions';

import { jsonParse, jsonStringify } from './utils';
import { BoardSettings } from '../company/board-meetings/utils';
import { GqlCompany } from '../graphql/operations';

interface IAddress {
  co: string;
  address: string;
  city: string;
  zip: string;
  email: string;
}

export type CompanyId = string;

export class Company
  implements
    Omit<
      GqlCompany,
      | 'meetings'
      | 'shareIssues'
      | 'warrantPrograms'
      | 'authorizationGroups'
      | 'articlesOfAssocs'
      | 'snrBonds'
      | 'snrCases'
      | 'snrAuthorizations'
      | 'companyUsers'
      | 'companyInvites'
      | 'capTables'
      | 'shareholders'
      | 'meetingAgendaTemplates'
      | 'subscriptions'
      | 'snrShareCapitalChanges'
    >
{
  id: CompanyId;

  organizationNumber: string;
  name: string;
  email?: string | null | undefined;
  web?: string;
  useGravatar?: boolean;

  @Transform(({ value }: { value: string }) => jsonParse(value), {
    toClassOnly: true,
  })
  @Transform(({ value }) => jsonStringify(value), { toPlainOnly: true })
  address: IAddress;

  get addressAsStr() {
    const { co, address, zip, city } = this.address;
    let addr = '';
    if (!isNil(co)) {
      addr += `${co}, `;
    }
    if (!isNil(address)) {
      addr += `${address}, `;
    }
    if (!isNil(zip)) {
      addr += `${zip} `;
    }
    if (!isNil(city)) {
      addr += `${city}`;
    }
    return addr || null;
  }

  currency: string;

  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  foundationDate: DateTime;
  bolagsverketsid: string;
  county: string;
  municipal: string;
  isPublic: boolean;
  shareCapital: string;
  shareCapitalMax?: string;
  shareCapitalMin?: string;
  sharesTotal: string;
  sharesMax?: string;
  sharesMin?: string;
  @Transform(({ value }: { value: string }) => jsonParse(value), {
    toClassOnly: true,
  })
  @Transform(({ value }) => jsonStringify(value), { toPlainOnly: true })
  shareClasses: ShareClass[];
  sharesAtFoundation?: string;
  authorizedSignatory: string;
  @Transform(({ value }: { value: string }) => jsonParse(value), {
    toClassOnly: true,
  })
  @Transform(({ value }) => jsonStringify(value), { toPlainOnly: true })
  boardSettings: BoardSettings;
  hasRelation: string;
  fiscalYear: string;
  shareholderMeetingHowtoCall: string;
  residenceMunicipal: string;
  reservationArbitrator: boolean;
  reservationConsent: boolean;
  reservationDescrepancy: boolean;
  reservationMajority: boolean;
  reservationOther: string;
  // reservationPrecedence: boolean;
  reservationRightOfFirstRefusal: boolean;
  reservationPreferential: boolean;
  reservationReconciliation: boolean;
  reservationReconciliationDate: string;
  // reservationRedemption: boolean;
  reservationPostTransferPurchaseRight: boolean;
  reservationVote: boolean;
  reservationNoAuditor: boolean;
  boardMembersMin: string;
  boardMembersMax: string;
  boardSubstitutesMin: string;
  boardSubstitutesMax: string;

  stripeCustomerId?: string;
  stripeInvitePromoCode?: string;
  isAntlerCompany: boolean;
  allowShortQeso: boolean;
  shareRegisterShareCount: number;

  @Transform(
    ({ value }: { value: string | number }) =>
      isNumber(value) ? DateTime.fromSeconds(value) : DateTime.fromISO(value),
    { toClassOnly: true },
  )
  readonly createdAt: DateTime;
  @Transform(
    ({ value }: { value: string | number }) =>
      isNumber(value) ? DateTime.fromSeconds(value) : DateTime.fromISO(value),
    { toClassOnly: true },
  )
  readonly updatedAt: DateTime;

  public get isDemo() {
    return this.organizationNumber.startsWith('0');
  }
}
