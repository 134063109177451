import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtService } from '@startuptools/angular/auth';
import { isNil, forEach, has, isEmpty, isArray, isString } from 'lodash-es';
import { host } from '../../environments/environment';
import { DocGenRelation2, DocGenSignature2 } from '../document-generation/types';
import { CdWithIncludes } from '../models/company-director.model';
import { MatSnackBar } from '@angular/material/snack-bar';

// Counts range inclusive

export function filterExcludeCompanyDirectors(cds: CdWithIncludes[], filterCDFunctions: string[] = []) {
  return cds.filter(cd => {
    if (filterCDFunctions.length > 0) {
      return !cd.model.functions.some(i => {
        return filterCDFunctions.some(j => i.code === j);
      });
    }
    return true;
  });
}

function buildJSONApiQuery(k: string, v: string | string[]): [string, string] {
  if (k === 'include' && isArray(v)) {
    return [k, v.join(',')];
  } else if (isString(v)) {
    return [k, v];
  }
  throw new Error('[buildJSONApiQuery] v is neither array nor string');
}

export function buildUrl(
  base: string,
  urlTemplate: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pathParams?: Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query?: Record<string, any>,
): string {
  // find all path params
  const baseUrl = new URL(base);
  const basePathAndTemplate = [...baseUrl.pathname.split('/'), ...urlTemplate.split('/')];
  let parts = basePathAndTemplate.map(part => {
    if (part.startsWith(':')) {
      let param = part.replace(/:/g, '');
      const isOptional = param.startsWith('?');
      param = param.replace(/\?/g, '');
      // @ts-expect-error TS18048
      if (!isOptional && !has(pathParams, param) && isEmpty(pathParams[param])) {
        throw new Error(`missing path param [${param}] for [${urlTemplate}]`);
        // @ts-expect-error TS18048
      } else if (isOptional && !has(pathParams, param) && isEmpty(pathParams[param])) {
        return '';
      }
      // @ts-expect-error TS18048
      return pathParams[param];
    }
    return part;
  });
  parts = parts.filter(p => !isNil(p) && p.length > 0);
  const url = new URL(parts.join('/'), baseUrl.origin);
  forEach(query, (v, k) => {
    const [newK, newV] = buildJSONApiQuery(k, v);
    if (!isNil(newV)) {
      url.searchParams.set(newK, newV);
    }
  });
  return url.toString();
}

export function uploadFile(
  http: HttpClient,
  router: Router,
  snackBar: MatSnackBar,
  jwtService: JwtService,
  companyId: string,
  file: File,
  relation?: DocGenRelation2,
  signatures?: DocGenSignature2,
  fileOptions: {
    name?: string;
    kind?: string;
    userTags?: string[];
  } = {},
) {
  const url = new URL(`/api/resource/documents/${companyId}/upload`, host.clientUrl).href;
  const body = new FormData();
  body.append('file', file, fileOptions.name);
  if (!isNil(relation)) {
    body.append('relation', JSON.stringify(relation));
  }
  if (!isNil(fileOptions.kind)) {
    body.append('kind', fileOptions.kind);
  }
  if (!isNil(fileOptions.userTags)) {
    body.append('userTags', JSON.stringify(fileOptions.userTags));
  }
  if (!isNil(signatures)) {
    body.append('signatures', JSON.stringify(signatures));
  }
  if (jwtService.isValid()) {
    return http.post<{ uploadedDocumentId: string; affectedDocumentIds?: string[] }>(url, body, {
      reportProgress: true,
      observe: 'events',
      headers: { Authorization: `Bearer ${jwtService.jwt}` },
    });
  }
  void router.navigate(['/auth']);
  // @ts-expect-error TS2345
  snackBar.open($localize`Du har blivit utloggad på grund av inaktivitet`, null, { duration: 10000 });
}
