import { isNil, reverse } from 'lodash';
import { DateTime } from 'luxon';
import { CompleteScc, completeSccs, SnrScc, StPlus } from '../snr-sccs';

function getRelevantScc(
  date: DateTime,
  shareCapitalChanges: SnrScc[],
  shareTransactions: StPlus[],
  companyFoundationDate?: DateTime,
): CompleteScc | undefined {
  if (isNil(shareCapitalChanges) || isNil(shareTransactions)) {
    throw new Error('missing sccs or sts');
  }
  const sccs = reverse(completeSccs(shareCapitalChanges, shareTransactions));
  return sccs.find(scc => {
    let transactionDate: DateTime | undefined | null;
    if (scc.name === 'NYBILDNING') {
      /* Often the companyFoundationDate is before the registrationDate, which means that if we check
      for shares on the foundationDate, we don't find anything. This code makes the foundation SCC cover
      all dates from the foundationDate and forward.  */
      transactionDate = scc.decisionDate || companyFoundationDate || scc.registrationDate;
    } else {
      transactionDate = scc.decisionDate || scc.registrationDate;
    }
    if (isNil(transactionDate)) {
      return false;
    }
    return transactionDate.startOf('day').toMillis() <= date.startOf('day').toMillis();
  });
}

/**
 * @summary Returns the number of shares of the SNR share capital changes at a given time.
 */
export function snrNumberOfSharesAtDate(
  date: DateTime,
  shareCapitalChanges: SnrScc[],
  shareTransactions: StPlus[],
  companyFoundationDate?: DateTime,
): number {
  const relevantScc = getRelevantScc(date, shareCapitalChanges, shareTransactions, companyFoundationDate);
  if (isNil(relevantScc) || isNil(relevantScc.numberOfShares)) {
    throw new Error('[snrNumberOfSharesAtDate] Missing relevant scc');
  }
  return relevantScc.numberOfShares;
}

export function snrQuotaValueAtDate(
  date: DateTime,
  shareCapitalChanges: SnrScc[],
  shareTransactions: StPlus[],
  companyFoundationDate?: DateTime,
): null | number | undefined {
  const relevantScc = getRelevantScc(date, shareCapitalChanges, shareTransactions, companyFoundationDate);
  if (isNil(relevantScc)) {
    throw new Error('[snrQuotaValueAtDate] Missing relevant scc');
  }
  return relevantScc.sharePriceFactor;
}

export function snrShareCapitalAtDate(
  date: DateTime,
  shareCapitalChanges: SnrScc[],
  shareTransactions: StPlus[],
): number {
  const relevantScc = getRelevantScc(date, shareCapitalChanges, shareTransactions);
  if (isNil(relevantScc) || isNil(relevantScc.shareCapitalAfter)) {
    throw new Error('[snrSharecapitalAtDate] Missing relevant scc');
  }
  return relevantScc.shareCapitalAfter;
}
