import { VestingFrequency, VestingKind } from '@startuptools/common/stock-options';
import { PaymentKind, PaymentStatus } from '@startuptools/common/participants';
import { Transform, plainToInstance } from 'class-transformer';
import { ShareClass } from '@startuptools/common/transactions';

import { jsonParse } from './utils';
import { Identity } from './identity.model';
import { GqlWarrantParticipant } from '../graphql/operations';
import { AutoFields } from '../models';
import { OptionsRegistrationId } from './options-registration.model';
import { OptionsSubscriberRepresentativeWithIncludes } from './options-subscriber-representatives.model';
import { GqlDocument } from '../graphql/react-operations';

export interface WppWithIncludes {
  model: WarrantProgramParticipant;
  identity?: Identity;
  // proxy?: Identity;
  representatives: OptionsSubscriberRepresentativeWithIncludes[];
}

export interface WppWithProperties {
  wpp: WppWithIncludes;
  share: number;
  numberOfOptions: { quantity: string };
  expendedVesting?: number;
  unconsumedVesting?: number;
  totalWarrantPrice: number;
  totalSharePrice: number;
}

export type WarrantProgramParticipantId = string;

export class WarrantProgramParticipant
  implements
    Omit<
      GqlWarrantParticipant,
      | 'identity'
      | 'optionsRegistration'
      | 'representatives'
      | 'vestedQuantity'
      | 'vestedPercentage'
      | 'vestingOccasions'
    >
{
  id: WarrantProgramParticipantId;
  latestAgreementDoc: GqlDocument | null;
  quantity: string | null | undefined;
  claim?: string;
  @Transform(({ value }) => plainToInstance(ShareClass, jsonParse(value as string)), { toClassOnly: true })
  shareClass: ShareClass;
  vestingKind: VestingKind;
  vestingDuration: number;
  vestingAcceleration: boolean;
  vestingCliffDuration: number;
  vestingFrequency: VestingFrequency;
  forcedRedemption: boolean;
  forcedSaleAtIpo: boolean;
  identityId: string;
  paymentKind: PaymentKind;
  paymentStatus: PaymentStatus;
  repurchaseVested: boolean;
  leaveOfAbsenceDays: number;
  optionsRegistrationId: OptionsRegistrationId;
  splitFactor: string;
  customVestingFullyConfigured: boolean;

  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(seed?: Omit<WarrantProgramParticipant, AutoFields | 'identity'>) {
    Object.assign(this, seed);
  }
}
