import { createFeatureSelector, createSelector } from '@ngrx/store';
import { compact, filter } from 'lodash-es';
import { SnrShareCapitalChangesState } from '../reducers/snr-share-capital-changes.reducers';
import { selectCompanyId } from './companies.base';

export const selectSnrShareCapitalChangesState =
  createFeatureSelector<SnrShareCapitalChangesState>('snrShareCapitalChanges');
export const selectSnrShareCapitalChanges = createSelector(
  selectSnrShareCapitalChangesState,
  selectCompanyId,
  (state, companyId) => {
    return compact(filter(state.entities, e => e?.companyId === companyId));
  },
);
