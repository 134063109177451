import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';
import { isNil } from 'lodash-es';
import { TransactionKind } from '@startuptools/common/transactions';

import { GqlShareTransactionGroup } from '../graphql/operations';
import { ShareIssue } from '../models/share-issue.model';
import { WarrantProgram } from '../models/warrant-program.model';
import { SnrShareCapitalChange } from '../models/snr-share-capital-change.model';
import { AutoFields } from '.';

export interface StgWithIncludes {
  model: ShareTransactionGroup;
  snrScc?: SnrShareCapitalChange;
  shareIssue?: ShareIssue;
  warrantProgram?: WarrantProgram;
}

export class ShareTransactionGroup implements Omit<GqlShareTransactionGroup, 'shareTransactions'> {
  id: string;

  kind: TransactionKind;

  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  transactionDate: DateTime;

  sortOrder: number;
  companyId: string;
  snrShareCapitalChangeId?: string;
  shareIssueId?: string;
  warrantProgramId?: string;
  splitFactor?: string;

  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  readonly createdAt: DateTime;

  @Transform(({ value }: { value: string }) => (!isNil(value) ? DateTime.fromISO(value) : null), { toClassOnly: true })
  readonly updatedAt: DateTime;

  constructor(seed: Omit<ShareTransactionGroup, AutoFields>) {
    Object.assign(this, seed);
  }
}
