export enum TransactionKind {
  Issue,
  Transfer,
  Unissue,
  Split,
  Merge,
  ShareClassConvert,
  BlockPartition,
  CurrencyChange,
}

export class ShareClass {
  name: string;
  quantity?: string;
  vote_weight: string;
  min?: string;
  max?: string;
}

export interface ShSellableShares {
  shares: Record<string, number>;
  shareClasses: ShareClass[];
}

export interface SellableShares extends ShSellableShares {
  shareholderId: string;
}
