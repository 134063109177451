import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { shareTransactionsActions } from '../actions/share-transactions.actions';
import { ShareTransaction } from '../../models/share-transaction.model';

export interface ShareTransactionsState extends EntityState<ShareTransaction> {
  currentUserId: string;
}

export const adapter = createEntityAdapter<ShareTransaction>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(shareTransactionsActions.manyLoaded, (state, { payload }) =>
    adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    ),
  ),
  on(shareTransactionsActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function shareTransactionsReducer(state: EntityState<ShareTransaction> | undefined, action: Action) {
  return reducer(state, action);
}
