import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactWrapperComponent } from '../react-wrapper/react-wrapper.component';
import { Identity } from '../../components/identity/Identity';
import { ReactI18n } from '../../injectables/react-i18n.service';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store/reducers';
import { selectCompanyId } from '../../store/selectors/companies.base';
import { GqlCreateIdentityInput, GqlIdentity } from '../../graphql/operations';
import { filter } from 'rxjs';
import { isNil } from 'lodash-es';

@Component({
  standalone: true,
  imports: [CommonModule, ReactWrapperComponent],
  selector: 'app-identity-wrapper',
  template: `
    <app-react-wrapper
      *ngIf="identity"
      [component]="Identity"
      [props]="{ identity, showAvatar, editable, layout }"
      [i18n]="i18n.i18n"
      [companyId]="companyId$ | async"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentityWrapperComponent {
  @Input() identity: GqlIdentity | GqlCreateIdentityInput;
  @Input() showAvatar = false;
  @Input() editable = true;
  @Input() layout: 'row' | 'column' = 'column';
  Identity = Identity;

  companyId$ = this.store.pipe(
    select(selectCompanyId),
    filter(e => !isNil(e)),
  );

  constructor(
    public i18n: ReactI18n,
    private store: Store<AppState>,
  ) {}
}
