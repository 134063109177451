import { createFeatureSelector, createSelector } from '@ngrx/store';
import { filter, get } from 'lodash-es';
import { ShareholdersState } from '../reducers/shareholders.reducers';
import { selectCompanyId } from './companies.base';
import { ShWithIncludes } from '../../models/shareholder.model';
import { selectIdentitiesState } from './identities.base';
import { selectUsersState } from './user.selectors';

export const selectShareholdersState = createFeatureSelector<ShareholdersState>('shareholders');
export const selectShareholders = createSelector(selectShareholdersState, selectCompanyId, (state, companyId) => {
  // @ts-expect-error TS18048
  return filter(state.entities, e => e.companyId === companyId);
});
export const selectShsWithIncludes = createSelector(
  selectShareholders,
  selectIdentitiesState,
  selectUsersState,
  (shs, iState, uState) => {
    return shs.map(sh => {
      // @ts-expect-error TS18048
      const identity = get(iState.entities, sh.identityId);
      return {
        // @ts-expect-error TS2322
        model: sh,
        // @ts-expect-error TS2322
        identity,
        // @ts-expect-error TS18048
        user: get(uState.entities, identity.userId),
      } satisfies ShWithIncludes;
    });
  },
);

export const selectShareholder = (id: string) =>
  createSelector(selectShareholdersState, (state: ShareholdersState) => {
    return state.entities[id];
  });

export const selectShWithIncludes = (id: string) =>
  createSelector(selectShareholder(id), selectIdentitiesState, selectUsersState, (shareholder, iState, uState) => {
    // @ts-expect-error TS18048
    const identity = get(iState.entities, shareholder.identityId);
    return {
      // @ts-expect-error TS2322
      model: shareholder,
      // @ts-expect-error TS2322
      identity,
      // @ts-expect-error TS18048
      user: get(uState.entities, identity.userId),
    } satisfies ShWithIncludes;
  });
