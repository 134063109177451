import type * as Types from '../base-types.graphql';

import { gql } from '@apollo/client';
export type GqlCompanyFieldsFragment = { id: string, name: string, organizationNumber: string, email?: string | null, web?: string | null, useGravatar?: boolean | null, address?: any | null, currency: string, foundationDate: string, bolagsverketsid?: string | null, county: string, municipal: string, isPublic: boolean, shareCapital: string, shareCapitalMax?: string | null, shareCapitalMin?: string | null, sharesTotal: string, sharesMax?: string | null, sharesMin?: string | null, shareClasses?: any | null, sharesAtFoundation?: string | null, authorizedSignatory: string, boardSettings?: any | null, fiscalYear: string, shareholderMeetingHowtoCall: string, residenceMunicipal: string, reservationArbitrator: boolean, reservationConsent: boolean, reservationDescrepancy: boolean, reservationMajority: boolean, reservationOther: string, reservationRightOfFirstRefusal: boolean, reservationPreferential: boolean, reservationReconciliation: boolean, reservationReconciliationDate?: string | null, reservationPostTransferPurchaseRight: boolean, reservationVote: boolean, reservationNoAuditor: boolean, boardMembersMin: string, boardMembersMax: string, boardSubstitutesMin: string, boardSubstitutesMax: string, stripeCustomerId?: string | null, stripeInvitePromoCode?: string | null, isAntlerCompany: boolean, allowShortQeso: boolean, hubspotObjectId?: string | null, createdAt: string, updatedAt: string, capTableTrialExpires?: string | null };

export const CompanyFieldsFragmentDoc = gql`
    fragment CompanyFields on Company {
  id
  name
  organizationNumber
  email
  web
  useGravatar
  address
  currency
  foundationDate
  bolagsverketsid
  county
  municipal
  isPublic
  shareCapital
  shareCapitalMax
  shareCapitalMin
  sharesTotal
  sharesMax
  sharesMin
  shareClasses
  sharesAtFoundation
  authorizedSignatory
  boardSettings
  fiscalYear
  shareholderMeetingHowtoCall
  residenceMunicipal
  reservationArbitrator
  reservationConsent
  reservationDescrepancy
  reservationMajority
  reservationOther
  reservationRightOfFirstRefusal
  reservationPreferential
  reservationReconciliation
  reservationReconciliationDate
  reservationPostTransferPurchaseRight
  reservationVote
  reservationNoAuditor
  boardMembersMin
  boardMembersMax
  boardSubstitutesMin
  boardSubstitutesMax
  stripeCustomerId
  stripeInvitePromoCode
  isAntlerCompany
  allowShortQeso
  hubspotObjectId
  createdAt
  updatedAt
  capTableTrialExpires
}
    `;