import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { map } from 'lodash-es';

import { shareTransactionGroupsActions } from '../actions/share-transaction-groups.actions';
import { ShareTransactionGroup } from '../../models/share-transaction-group.model';

export interface ShareTransactionGroupsState extends EntityState<ShareTransactionGroup> {
  currentUserId: string;
}

export const adapter = createEntityAdapter<ShareTransactionGroup>();
export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(shareTransactionGroupsActions.manyLoaded, (state, { payload }) => {
    return adapter.addMany(
      payload,
      adapter.removeMany(
        map(payload, p => p.id),
        state,
      ),
    );
  }),
  on(shareTransactionGroupsActions.manyDeleted, (state, { payload }) => adapter.removeMany(payload, state)),
);

export function shareTransactionGroupsReducer(state: EntityState<ShareTransactionGroup> | undefined, action: Action) {
  return reducer(state, action);
}
