import { isNil, toNumber, orderBy } from 'lodash';

interface PartialSnrScc {
  sortOrder?: number | null | undefined;
  sortOrderOverride?: number | null | undefined;
  sortOrderSnr?: number | null | undefined;
  filingYear: string;
  filingNumber: string;
  serialNumber: string;
}

export function sortSnrSccs<T extends PartialSnrScc>(sccs: T[], ord: 'asc' | 'desc' = 'asc'): T[] {
  if (sccs.some(scc => isNil(scc.sortOrder) && isNil(scc.sortOrderSnr) && isNil(scc.sortOrderOverride))) {
    return orderBy(
      sccs,
      [scc => toNumber(scc.filingYear), scc => toNumber(scc.filingNumber), scc => toNumber(scc.serialNumber)],
      [ord, ord, ord],
    );
  }
  return orderBy(sccs, scc => scc.sortOrder || scc.sortOrderOverride || scc.sortOrderSnr, ord);
}
