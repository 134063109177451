import {
  Component,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  OnInit,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, UntypedFormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store, select } from '@ngrx/store';
import { first as _first, isNil, includes, uniq } from 'lodash-es';
import { combineLatest, firstValueFrom, forkJoin, of, throwError } from 'rxjs';
import { map, startWith, concatMap, first, finalize, tap, catchError } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { plainToInstance } from 'class-transformer';
import { JwtService } from '@startuptools/angular/auth';
import { LoadingDirective } from '@startuptools/angular/loading';
import {
  DocGenRelation2,
  DocGenSignature2,
  DocGenSignatureParty2,
  DocGenRelationName,
} from '../../document-generation/types';
import { Document } from '../../models/document.model';
import { DocumentSignature } from '../../models/document-signature.model';
import { InputState } from '../../components/add-identity-dialog/types';
import { DetailState } from '../../components/add-identity-dialog/types';
import { isSwedish, Identity, IDKind } from '../../models/identity.model';
import { ScriveDeliveryMethod } from '../../company/company.component';
import { JuridicalKind } from '../../models/identity.model';
import { PromptDialogComponent } from '../simple-dialogs/prompt-dialog.component';
import { uploadFile } from '../../helpers/utils';
import { AppState } from '../../store/reducers';
import { DocumentDialogComponent } from '../document-dialog/document-dialog.component';
import { selectCurrentUser } from '../../store/selectors/user.selectors';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Company } from '../../models/company.model';
import { AlertDialogComponent } from '../simple-dialogs/alert-dialog.component';
import {
  GqlArticlesOfAssocDocumentService,
  GqlAuthorizationGroupDocumentService,
  GqlDocSystemTagKind,
  GqlDocumentsService,
  GqlDocumentUserTagsService,
  GqlIdentitySetEmailInput,
  GqlMeetingDocumentService,
  GqlRatsitPeopleService,
  GqlSetIdentityEmailService,
  GqlShareIssueDocumentService,
  GqlWarrantDocumentService,
} from '../../graphql/operations';
import {
  DocumentUploadDialogStore,
  SignatoryRow,
} from '../../components/document-upload-dialog/document-upload-dialog.store';
import { selectIdentityById } from '../../store/selectors/identities.base';
import { GqlDispatchService } from '../../injectables/gql-dispatch.service';
import { GqlCreateIdentityService, GqlUserIdentityService } from '../../graphql/operations';
import { identityToInput } from '../../helpers/identity-to-input';
import { AddIdentityDialogSettings } from '../../components/add-identity-dialog/add-identity-wrapper.component';
import { GqlDocumentSignatureMethod, GqlScriveAuthMethod } from '../../graphql/base-types.graphql';

interface DocumentDialogData {
  company: Company;
  shouldSign?: boolean;
  relation?: DocGenRelation2;
  showTags?: boolean;
  kind?: GqlDocSystemTagKind;
  showDocumentAfter?: boolean;
}

const fileLimit = 8_388_608; // 8MiB
const unsignedFileLimit = 33_554_432; // 32MiB

@Component({
  selector: 'app-document-upload-dialog',
  templateUrl: './document-upload-dialog.component.html',
  styleUrls: ['./document-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DocumentUploadDialogStore],
})
export class DocumentUploadDialogComponent implements OnInit, OnDestroy {
  JuridicalKind = JuridicalKind;
  @ViewChild('qLoading') qLoading: LoadingDirective;
  @ViewChild('tagInput', { static: false })
  tagInput: ElementRef<HTMLInputElement>;
  isNil = isNil;

  subs = new SubSink();
  readonly docUploadIndicator = 'docUploadIndicator';
  readonly docProcessIndicator = 'docProcessIndicator';

  separatorKeysCodes: number[] = [ENTER, COMMA];
  userTagCtrl = new UntypedFormControl();
  userTags: string[] = [];
  suggestedUserTags: string[] = [
    'Anställningsavtal',
    'Aktieägaravtal',
    'NDA',
    'Avtal med leverantör',
    'Avtal med partner',
    'Överlåtelse av aktier',
  ];
  displayedColumns: string[] = ['signatory', 'proxy', 'email', 'action'];

  signatories$ = this.componentStore.state$.pipe(map(state => state.signatories));
  files$ = this.componentStore.state$.pipe(map(state => state.files));

  user$ = this.store.pipe(select(selectCurrentUser));

  canAddSelf$ = combineLatest([this.signatories$, this.user$]).pipe(
    map(([ds, user]) => {
      // @ts-expect-error TS18048
      return !ds.some(e => e.identity.userId === user.id);
    }),
  );

  canAddCurrentCompany$ = this.signatories$.pipe(
    map(ds => {
      return !ds.some(e => e.identity.identityNumber === this.data.company.organizationNumber);
    }),
  );

  userTags$ = this.gqlDocumentUserTags
    .fetch({ companyId: this.data.company.id }, { fetchPolicy: 'no-cache' })
    .pipe(map(res => res.data.documentUserTags.tags));

  filteredUserTags$ = combineLatest([
    // @ts-expect-error TS2352
    this.userTagCtrl.valueChanges.pipe(startWith(null as string)),
    this.userTags$,
  ]).pipe(
    map(([ctrlInput, uTags]) => {
      const docTags = uniq([...this.suggestedUserTags, ...uTags]);
      const unUsed = docTags.filter(f => !includes(this.userTags, f)).sort();
      if (!isNil(ctrlInput)) {
        return unUsed.filter(u => u.toLowerCase().startsWith(ctrlInput.toLowerCase()));
      }
      return unUsed;
    }),
  );

  canStartSigning$ = this.signatories$.pipe(
    map(ds => {
      if (ds.length < 1) {
        return false;
      }
      if (ds.some(d => isNil(d.documentSignature.signatoryId))) {
        return false;
      }
      if (ds.some(d => isNil(d.documentSignature.email) || d.documentSignature.email === '')) {
        return false;
      }
      return true;
    }),
  );

  public open = signal(false);
  public settings = signal<AddIdentityDialogSettings | null>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DocumentDialogData,
    private dialogRef: MatDialogRef<DocumentUploadDialogComponent>,
    private dialog: MatDialog,
    private router: Router,
    private jwtService: JwtService,
    private http: HttpClient,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    public componentStore: DocumentUploadDialogStore,
    private gqlRatsitPeople: GqlRatsitPeopleService,
    private gqlDispatch: GqlDispatchService,
    private gqlCreateIdentity: GqlCreateIdentityService,
    private gqlUserIdentity: GqlUserIdentityService,
    private gqlSetIdentityEmail: GqlSetIdentityEmailService,
    private gqlDocumentUserTags: GqlDocumentUserTagsService,
    private gqlAoaDocument: GqlArticlesOfAssocDocumentService,
    private gqlAuthorizationGroupDocument: GqlAuthorizationGroupDocumentService,
    private gqlMeetingDocument: GqlMeetingDocumentService,
    private gqlShareIssueDocument: GqlShareIssueDocumentService,
    private gqlWarrantDocument: GqlWarrantDocumentService,
    private gqlDocument: GqlDocumentsService,
  ) {
    if (isNil(data.kind)) {
      data.kind = GqlDocSystemTagKind.Other;
    }
    if (isNil(data.showDocumentAfter)) {
      data.showDocumentAfter = true;
    }
  }

  static open(dialog: MatDialog, data: DocumentDialogData): MatDialogRef<DocumentUploadDialogComponent, Document> {
    return dialog.open(DocumentUploadDialogComponent, {
      minWidth: '1100px',
      maxWidth: '1400px',
      width: '100%',
      maxHeight: '90%',
      disableClose: true,
      autoFocus: false,
      data,
    });
  }

  ngOnInit() {
    this.componentStore.setState({ files: [], signatories: [] });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  addUserTag() {
    const value = (this.userTagCtrl.value || '').trim();
    if (value.length > 0) {
      this.userTags.push(value);
    }
    this.tagInput.nativeElement.value = '';
    this.userTagCtrl.setValue(null);
  }

  selectUserTag(event: MatAutocompleteSelectedEvent) {
    this.userTags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.userTagCtrl.setValue(null);
  }

  remove(tag: string) {
    const index = this.userTags.indexOf(tag);

    if (index >= 0) {
      this.userTags.splice(index, 1);
    }
    this.userTagCtrl.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }

  addSignee() {
    this.signatories$.pipe(first()).subscribe(signatories => {
      this.settings.set({
        headline: $localize`Välj en person eller företag`,
        juridicalKinds: [JuridicalKind.Person, JuridicalKind.Company],
        detailFieldsSettings: {
          email: DetailState.Required,
        },
        identityExcludeList: signatories.map(d => d.identity),
        availableInputStates: {
          [InputState.System]: true,
          [InputState.CompanyBoard]: false,
          [InputState.Swedish]: true,
          [InputState.Foreign]: true,
        },
        handleCreate: identity => {
          if (isNil(identity)) {
            this.open.set(false);
            // @ts-expect-error TS2352
            return Promise.resolve(null as Identity);
          }
          const alreadyAdded = signatories.find(
            e => e.identity.juridicalKind === JuridicalKind.Person && e.identity.id === identity.id,
          );
          if (!isNil(alreadyAdded)) {
            this.snackBar.open($localize`Signatören finns inlagd sedan tidigare`);
            this.open.set(false);
            // @ts-expect-error TS2352
            return Promise.resolve(null as Identity);
          }
          return firstValueFrom(
            this.gqlDispatch
              .mutate(this.gqlCreateIdentity, {
                companyId: this.data.company.id,
                input: identityToInput(identity),
              })
              .pipe(
                // @ts-expect-error TS18049
                concatMap(res => this.store.pipe(select(selectIdentityById(res.data.createIdentity.id)))),
                tap(savedIdentity => {
                  if (savedIdentity instanceof Identity) {
                    let row: SignatoryRow;
                    if (savedIdentity.juridicalKind === JuridicalKind.Person) {
                      row = {
                        documentSignature: this.createDocumentSignature(savedIdentity, undefined),
                        identity: savedIdentity,
                        proxy: undefined,
                      };
                    } else {
                      row = {
                        documentSignature: new DocumentSignature({
                          email: savedIdentity.email,
                        }),
                        identity: savedIdentity,
                        proxy: undefined,
                      };
                    }
                    this.componentStore.updateSignatories([row, ...signatories]);
                  }
                }),
                tap(() => this.open.set(false)),
              ),
          );
        },
      });
      this.open.set(true);
    });
  }

  createDocumentSignature(identity: Identity, proxy: Identity | undefined): DocumentSignature {
    const signatory = proxy ?? identity;
    const dSignature = new DocumentSignature({
      signatoryId: signatory.id,
      proxyId: proxy?.id,
      authMethodToSign: isSwedish(identity) ? GqlScriveAuthMethod.SeBankid : GqlScriveAuthMethod.Standard,
      authMethodToView: GqlScriveAuthMethod.Standard,
      deliveryMethod: ScriveDeliveryMethod.Email,
      email: signatory.email,
    });
    return dSignature;
  }

  establishUserIdentity() {
    return this.user$.pipe(
      concatMap(user => {
        if (isNil(user)) {
          return throwError(() => new Error('user has is missing'));
        }
        return this.gqlUserIdentity
          .fetch({ companyId: this.data.company.id, userId: user.id }, { fetchPolicy: 'no-cache' })
          .pipe(
            map(res => plainToInstance(Identity, res.data.userIdentity)),
            catchError(err => {
              if (err.message !== 'no identity with that user id') {
                return throwError(() => err);
              }
              if (isNil(user) || isNil(user.personalId)) {
                return of(undefined);
              }
              // Try to establish a new identity
              return this.gqlRatsitPeople.fetch({ who: user.personalId }).pipe(
                catchError(err => {
                  return throwError(() => err);
                }),
                map(ratsitResult => {
                  const ratsitIdentity = ratsitResult.data.ratsitPeople.pop();
                  if (ratsitIdentity) {
                    return ratsitIdentity;
                  }
                  return Identity.fromUser(user, this.data.company.id);
                }),
                concatMap(identity => {
                  const input = identityToInput(identity, user.email, user.phone);
                  return (
                    this.gqlDispatch
                      .mutate(this.gqlCreateIdentity, {
                        companyId: this.data.company.id,
                        input,
                      })
                      // @ts-expect-error TS18049
                      .pipe(map(res => plainToInstance(Identity, res.data.createIdentity)))
                  );
                }),
              );
            }),
          );
      }),
    );
  }

  addCurrentCompany() {
    const identity = Identity.fromCompany(this.data.company);
    combineLatest({
      companyIdentity: this.gqlCreateIdentity
        .mutate({ companyId: this.data.company.id, input: identityToInput(identity) }, { fetchPolicy: 'no-cache' })
        // @ts-expect-error TS18049
        .pipe(map(res => plainToInstance(Identity, res.data.createIdentity))),
      userIdentity: this.establishUserIdentity(),
    })
      .pipe(first())
      .subscribe(({ companyIdentity, userIdentity }) => {
        if (!isNil(companyIdentity)) {
          const row: SignatoryRow = {
            documentSignature: this.createDocumentSignature(companyIdentity, userIdentity),
            identity: companyIdentity,
            proxy: userIdentity,
          };
          this.componentStore.addSignatories(row);
        }
      });
  }

  openProxyDialog(row: SignatoryRow) {
    this.settings.set({
      headline: $localize`Välj företrädare`,
      juridicalKinds: [JuridicalKind.Person],
      detailFieldsSettings: {
        email: DetailState.Required,
      },
      availableInputStates: {
        [InputState.System]: false,
        [InputState.CompanyBoard]: row.identity.identityNumberKind === IDKind.SwedishOrgNumber,
        [InputState.Swedish]: true,
        [InputState.Foreign]: true,
      },
      companyBoardOrgNumber: row.identity.identityNumber,
      handleCreate: identity => {
        return firstValueFrom(
          this.gqlDispatch
            .mutate(this.gqlCreateIdentity, {
              companyId: this.data.company.id,
              input: identityToInput(identity),
            })
            .pipe(
              // @ts-expect-error TS18049
              concatMap(res => this.store.pipe(select(selectIdentityById(res.data.createIdentity.id)))),
              tap(savedIdentity => {
                if (savedIdentity instanceof Identity) {
                  row.documentSignature.authMethodToSign = isSwedish(savedIdentity)
                    ? GqlScriveAuthMethod.SeBankid
                    : GqlScriveAuthMethod.Standard;
                  row.documentSignature.authMethodToView = GqlScriveAuthMethod.Standard;
                  row.documentSignature.deliveryMethod = ScriveDeliveryMethod.Email;
                  row.documentSignature.email = savedIdentity.email;
                  row.documentSignature.proxyId = savedIdentity.id;
                  row.documentSignature.signatoryId = row.identity.id;
                  row.proxy = savedIdentity;
                  this.updateRow(row);
                }
              }),
              tap(() => this.open.set(false)),
            ),
        );
      },
    });
    this.open.set(true);
  }

  removeIdentity(row: SignatoryRow) {
    this.signatories$.pipe(first()).subscribe(signatories => {
      this.componentStore.updateSignatories(signatories.filter(s => s.identity.id !== row.identity.id));
    });
  }

  removeProxy(row: SignatoryRow) {
    // @ts-expect-error TS2322
    row.proxy = null;
    row.documentSignature = this.createDocumentSignature(row.identity, undefined);
    this.updateRow(row);
  }

  changeSignatureEmail(row: SignatoryRow) {
    const updIdentity = row?.identity.juridicalKind === JuridicalKind.Person ? row?.identity.id : row?.proxy?.id;
    if (isNil(updIdentity)) {
      throw new Error('could not determine identity id');
    }
    PromptDialogComponent.open(this.dialog, {
      title: $localize`Ändra epost`,
      ok: $localize`Spara`,
      cancel: $localize`Avbryt`,
      modelValue: row.documentSignature.email,
      type: 'email',
      validators: [Validators.email],
      errors: { email: 'Ogiltig epostadress' },
      save: result => {
        row.documentSignature.email = result.input;
        return this.store
          .pipe(select(selectIdentityById(updIdentity)))
          .pipe(
            concatMap(identity => {
              const input: GqlIdentitySetEmailInput = {
                // @ts-expect-error TS18048
                id: identity.id,
                // @ts-expect-error TS2322
                email: result.input,
              };
              return this.gqlDispatch.mutate(this.gqlSetIdentityEmail, {
                // @ts-expect-error TS18048
                companyId: identity.companyId,
                input,
              });
            }),
          )
          .pipe(finalize(() => this.updateRow(row)));
      },
    });
  }

  updateRow(row: SignatoryRow) {
    this.signatories$.pipe(first()).subscribe(signatories => {
      const idx = signatories.findIndex(s => s.identity.id === row.identity.id);
      signatories.splice(idx, 1, row);
      this.componentStore.updateSignatories(signatories);
    });
  }

  startUpload() {
    if (!isNil(this.tagInput)) {
      this.addUserTag(); // if there is a lingering user tag text, create the tag before starting upload
    }

    combineLatest([this.files$, this.signatories$])
      .pipe(first())
      .subscribe(([files, signatories]) => {
        const file = _first(files);
        if (isNil(file)) {
          return;
        }
        if (this.data.shouldSign && file.size > fileLimit) {
          AlertDialogComponent.open(this.dialog, {
            title: $localize`Filen är för stor`,
            text: $localize`Gränsen för uppladdning av filer är på 8 MB`,
            ok: 'OK',
          });
          return;
        }
        if (!this.data.shouldSign && file.size > unsignedFileLimit) {
          AlertDialogComponent.open(this.dialog, {
            title: $localize`Filen är för stor`,
            text: $localize`Gränsen för uppladdning av filer är på 32 MB`,
            ok: 'OK',
          });
          return;
        }
        this.qLoading.setMode('determinate');
        this.qLoading.setProgress(0);
        this.qLoading.start();
        let docSignature: DocGenSignature2;
        if (this.data.shouldSign) {
          const signatureParties = signatories.map(row => {
            return {
              signatory_id: row.identity.id,
              proxy_id: row.documentSignature.proxyId,
              // @ts-expect-error TS2322
              delivery_method: row.documentSignature.deliveryMethod,
              // @ts-expect-error TS2322
              auth_method_to_sign: row.documentSignature.authMethodToSign,
              // @ts-expect-error TS2322
              auth_method_to_view: row.documentSignature.authMethodToView,
              // @ts-expect-error TS2322
              email: row.documentSignature.email,
            } satisfies DocGenSignatureParty2;
          });
          docSignature = {
            method: GqlDocumentSignatureMethod.Digital,
            // @ts-expect-error TS2322
            parties: signatureParties,
          };
        }
        // @ts-expect-error TS2532
        uploadFile(
          this.http,
          this.router,
          this.snackBar,
          this.jwtService,
          this.data.company.id,
          file,
          this.data.relation,
          // @ts-expect-error TS2454
          docSignature,
          {
            name: encodeURIComponent(file.name),
            kind: this.data.kind,
            userTags: this.userTags,
          },
        )
          .pipe(
            finalize(() => {
              this.qLoading.stop();
              this.qLoading.setProgress(0);
            }),
          )
          .subscribe({
            next: event => {
              switch (event.type) {
                case HttpEventType.UploadProgress: {
                  // @ts-expect-error TS18048
                  const progress = Math.round((event.loaded / event.total) * 100);
                  this.qLoading.setProgress(progress);
                  if (event.loaded === event.total) {
                    this.qLoading.setMode('indeterminate');
                  }
                  break;
                }
                case HttpEventType.Response: {
                  const queryFunctions = {
                    [DocGenRelationName.ArticlesOfAssocId]: this.gqlAoaDocument,
                    [DocGenRelationName.AuthorizationGroupId]: this.gqlAuthorizationGroupDocument,
                    [DocGenRelationName.MeetingId]: this.gqlMeetingDocument,
                    [DocGenRelationName.ShareIssueId]: this.gqlShareIssueDocument,
                    [DocGenRelationName.WarrantProgramId]: this.gqlWarrantDocument,
                    undefined: this.gqlDocument,
                  };
                  // @ts-expect-error TS2538
                  const queryFunction = queryFunctions[this.data.relation?.relation_name];
                  const refreshCalls = [
                    this.gqlDispatch
                      .query(queryFunction, {
                        companyId: this.data.company.id,
                        // @ts-expect-error TS18047
                        documentId: event.body.uploadedDocumentId,
                      })
                      .pipe(
                        tap(() => {
                          if (this.data.showDocumentAfter) {
                            DocumentDialogComponent.open(this.dialog, {
                              // @ts-expect-error TS18047
                              documentId: event.body.uploadedDocumentId,
                            });
                          }
                          this.dialogRef.close();
                        }),
                      ),
                  ];
                  // @ts-expect-error TS18047
                  if (event.body.affectedDocumentIds?.length > 0) {
                    // @ts-expect-error TS18047
                    const queries = event.body.affectedDocumentIds.map(id =>
                      this.gqlDispatch.query(queryFunction, { companyId: this.data.company.id, documentId: id }),
                    );
                    refreshCalls.push(...queries);
                  }
                  forkJoin(refreshCalls).subscribe();
                  break;
                }
                default: {
                  break;
                }
              }
            },
            error: err => {
              console.error(err);
            },
          });
      });
  }

  public dropped(files: NgxFileDropEntry[]) {
    const droppedFile = _first(files);
    if (isNil(droppedFile) || !droppedFile.fileEntry.isFile) {
      return;
    }
    (droppedFile.fileEntry as FileSystemFileEntry).file(file => {
      if (file.type !== 'application/pdf') {
        return;
      }
      this.componentStore.updateFiles([file]);
    });
  }
}
