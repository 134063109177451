import { Trans, useLingui } from '@lingui/react';
import {
  Alert,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
  DialogActions,
  DialogContent,
  WarningIcon,
} from '@startuptools/ui';
import { GqlSeverity, GqlValidatorStatus } from '../graphql/base-types.graphql';
import { orderBy } from 'lodash-es';
import { DangerousOutlined } from '@mui/icons-material';

export const ValidatorErrorDialog = ({
  errors,
  onClose,
  preview,
}: {
  errors: GqlValidatorStatus[];
  onClose: () => void;
  preview: () => void;
}) => {
  const { i18n } = useLingui();

  const shownErrors = orderBy(
    errors.filter(e => e.hasError),
    ['severity'],
  );

  const hasFatalErrors = shownErrors.some(e => e.severity === GqlSeverity.Error);

  return (
    <Modal open onClose={onClose}>
      <ModalDialog>
        <ModalClose />
        <DialogContent>
          <Typography level="h4">
            <Trans id="Errors were detected" />
          </Typography>
          <Alert>
            <Trans id="There are one or multiple problems that needs resolving before generating the document." />
            {' ' /* here on purpose*/}
            {!hasFatalErrors && <Trans id="You can view a Preview of the document, but it will be incomplete." />}
          </Alert>

          {shownErrors.map(e => {
            const icon = e.severity === GqlSeverity.Warning ? <WarningIcon /> : <DangerousOutlined />;
            const color = e.severity === GqlSeverity.Warning ? 'warning' : 'danger';
            return (
              <Alert
                key={e.error?.en.short}
                sx={{ alignItems: 'flex-start' }}
                startDecorator={icon}
                variant="soft"
                color={color}
              >
                <div>
                  <div>{e.error?.[i18n.locale as 'sv' | 'en']?.short}</div>
                  <Typography level="body-sm" color={color}>
                    {e.error?.[i18n.locale as 'sv' | 'en']?.long}
                  </Typography>
                </div>
              </Alert>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            <Trans id="Cancel" />
          </Button>
          {!hasFatalErrors && (
            <Button onClick={preview}>
              <Trans id="Preview" />
            </Button>
          )}
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
