import { DateTime } from 'luxon';
import { isNil, keyBy, orderBy, toNumber } from 'lodash-es';
import { BigNumber } from 'bignumber.js';
import { plainToInstance } from 'class-transformer';
import { TransactionKind } from '@startuptools/common/transactions';
import { ShareTransaction, StWithIncludes } from '../models/share-transaction.model';
import { StgWithIncludes } from '../models/share-transaction-group.model';
import { ShWithIncludes } from '../models/shareholder.model';
import { GqlShareTransactionGroupsQuery } from '../graphql/react-operations';

export interface ShareTransactionPlus extends Omit<ShareTransaction, 'blockStart' | 'blockEnd'> {
  date: DateTime;
  kind: TransactionKind;
  sortOrder: number;
  splitFactor?: BigNumber;
  blockStart: number;
  blockEnd: number;
  snrShareCapitalChangeId: string | undefined;
  seller?: ShWithIncludes;
  buyer?: ShWithIncludes;
}

export function shareTransactionsPlus(sts: StWithIncludes[], stgs: StgWithIncludes[]): ShareTransactionPlus[] {
  const groupedStgs = keyBy(stgs, stg => stg.model.id);
  return (
    sts
      // eslint-disable-next-line array-callback-return
      .map(st => {
        const stg = groupedStgs[st.model.shareTransactionGroupId];
        if (!isNil(stg)) {
          return {
            ...st.model,
            date: stg.model.transactionDate,
            kind: stg.model.kind,
            sortOrder: stg.model.sortOrder,
            splitFactor: stg.model.splitFactor ? new BigNumber(stg.model.splitFactor) : undefined,
            blockStart: toNumber(st.model.blockStart),
            blockEnd: toNumber(st.model.blockEnd),
            snrShareCapitalChangeId: stg.model.snrShareCapitalChangeId,
            seller: st.seller,
            buyer: st.buyer,
          } satisfies ShareTransactionPlus;
        }
      })
      .filter(st => !isNil(st))
  );
}

export interface GqlShareTransactionPlus extends Omit<ShareTransaction, 'blockStart' | 'blockEnd'> {
  date: DateTime;
  kind: TransactionKind;
  sortOrder: number;
  splitFactor?: BigNumber;
  blockStart: number;
  blockEnd: number;
  snrShareCapitalChangeId: string;
  buyerIdentityId: string;
  sellerIdentityid: string;
}

export function stgsToStsPlus(
  stgs: GqlShareTransactionGroupsQuery['shareTransactionGroups'],
): GqlShareTransactionPlus[] {
  const sortedStgs = orderBy(stgs, [stg => stg.transactionDate, stg => stg.sortOrder], ['asc', 'asc']);
  // @ts-expect-error TS2322
  return sortedStgs.flatMap(stg => {
    return stg.shareTransactions.map(st => {
      const stClass = plainToInstance(ShareTransaction, st);
      return {
        ...stClass,
        // @ts-expect-error TS2322
        snrShareCapitalChangeId: stg.snrShareCapitalChangeId,
        date: DateTime.fromISO(stg.transactionDate),
        kind: stg.kind,
        sortOrder: stg.sortOrder,
        splitFactor: stg.splitFactor ? new BigNumber(stg.splitFactor) : undefined,
        blockStart: toNumber(st.blockStart),
        blockEnd: toNumber(st.blockEnd),
        // @ts-expect-error TS2322
        buyerIdentityId: st.buyer?.identityId,
        // @ts-expect-error TS2322
        sellerIdentityid: st.seller?.identityId,
      } satisfies GqlShareTransactionPlus;
    });
  });
}
