import { PropsWithChildren } from 'react';
import * as Sentry from '@sentry/react';
import { Stack } from '../';
import { NetworkError, GenericError } from './Error';
import { Dialog, DialogContent, DialogTitle } from './Dialog';
import { Trans } from '@lingui/react';

const defaultFallback: Sentry.FallbackRender = ({ error, resetError }) => {
  return (
    <Stack>
      {(error as Error)?.message === 'Failed to fetch' ? <NetworkError resetError={resetError} /> : <GenericError />}
    </Stack>
  );
};

const ErrorBoundary = ({
  children,
  fallback = defaultFallback,
  showModal,
  ...props
}: PropsWithChildren<
  {
    showModal?: boolean;
  } & Sentry.ErrorBoundaryProps
>) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError, ...rest }) => {
        const rendered = typeof fallback === 'function' ? fallback({ ...rest, resetError }) : fallback;
        if (showModal) {
          return (
            <>
              <Dialog onClose={resetError}>
                <DialogTitle onClose={resetError}>
                  <Trans id="An error occurred" />
                </DialogTitle>
                <DialogContent>{rendered}</DialogContent>
              </Dialog>
            </>
          );
        }
        return rendered;
      }}
      {...props}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
