import { createFeatureSelector, createSelector } from '@ngrx/store';
import { compact, filter, get, isNil } from 'lodash-es';
import { ShareTransactionsState } from '../reducers/share-transactions.reducers';
import { StWithIncludes } from '../../models/share-transaction.model';
import { selectShareholdersState } from '../../store/selectors/shareholders.selectors';
import { selectShareTransactionGroups } from '../../store/selectors/share-transaction-groups.selectors';
import { selectIdentitiesState } from '../../store/selectors/identities.base';
import { Dictionary } from '@ngrx/entity';
import { Identity } from '../../models/identity.model';

export const selectShareTransactionsState = createFeatureSelector<ShareTransactionsState>('shareTransactions');

export const selectShareTransactions = (stgId?: string) =>
  createSelector(selectShareTransactionsState, selectShareTransactionGroups, (state, stgs) => {
    if (!isNil(stgId)) {
      return filter(state.entities, e => e?.shareTransactionGroupId === stgId);
    }
    const stgIds = compact(stgs.map(stg => stg?.id));
    return filter(state.entities, e => stgIds.includes(e?.shareTransactionGroupId ?? ''));
  });

const getShIdentity = (iState: Dictionary<Identity>, id: string): Identity => {
  const identity = iState[id];
  if (isNil(identity)) {
    throw new Error('could not find identity for sh');
  }
  return identity;
};

export const selectStsWithIncludes = (stgId?: string) =>
  createSelector(
    selectShareTransactions(stgId),
    selectShareholdersState,
    selectIdentitiesState,
    (sts, shState, iState) => {
      return sts.map(st => {
        if (isNil(st)) {
          throw new Error('st is undefined');
        }
        const buyer = !isNil(st.buyerId) ? get(shState.entities, st.buyerId) : undefined;
        const seller = !isNil(st.sellerId) ? get(shState.entities, st.sellerId) : undefined;
        return {
          model: st,
          buyer: !isNil(buyer)
            ? {
                model: buyer,
                identity: getShIdentity(iState.entities, buyer.identityId),
              }
            : undefined,
          seller: !isNil(seller)
            ? {
                model: seller,
                identity: getShIdentity(iState.entities, seller.identityId),
              }
            : undefined,
        } satisfies StWithIncludes;
      });
    },
  );
