import React from 'react';
import { BigNumber } from 'bignumber.js';
import { isNil } from 'lodash-es';

interface Props extends Pick<Intl.NumberFormatOptions, 'maximumFractionDigits' | 'minimumFractionDigits'> {
  locale: 'sv' | 'en' | string;
  variant?: 'number' | 'percentage' | 'shares';
  children: string | number | BigNumber | null | undefined;
  currency?: never;
  renderZero?: boolean;
}

interface PropsCurrency extends Omit<Props, 'variant' | 'currency'> {
  variant: 'currency';
  currency: NonNullable<Intl.NumberFormatOptions['currency']>;
}

export const NumberFormat = ({
  variant = 'number',
  locale,
  children,
  minimumFractionDigits,
  maximumFractionDigits,
  currency,
  renderZero = true,
}: Props | PropsCurrency) => {
  let num!: number;

  if (typeof children === 'string') {
    num = Number(children);
  } else if (typeof children === 'number') {
    num = children;
  } else if (children instanceof BigNumber) {
    num = children.toNumber();
  } else if (children !== undefined && children !== null) {
    throw new Error('child was not string | number | BigNumber | null | undefined');
  }

  const formatted = React.useMemo(() => {
    if (num === 0) {
      return '0';
    }

    const options: Intl.NumberFormatOptions = {};

    if (variant === 'percentage') {
      if (Math.sign(num) > -1 && num < 0.01) {
        options.minimumFractionDigits = Math.max(0, 1 - Math.floor(Math.log10(num * 100)));
        options.maximumFractionDigits = 1 - Math.floor(Math.log10(num * 100));
      } else {
        options.minimumFractionDigits = minimumFractionDigits ?? 0;
        options.maximumFractionDigits = maximumFractionDigits ?? 2;
      }
    } else if (variant === 'currency') {
      options.minimumFractionDigits = minimumFractionDigits ?? 2;
      options.maximumFractionDigits = maximumFractionDigits ?? minimumFractionDigits ?? 2;
      options.currency = currency;
      options.style = 'currency';
    } else if (variant === 'shares') {
      options.minimumFractionDigits ??= 0;
      options.maximumFractionDigits ??= 0;
    } else {
      if (num % 1 !== 0) {
        options.minimumFractionDigits = minimumFractionDigits ?? 2;
      }
      options.minimumFractionDigits =
        maximumFractionDigits === 0 ? 0 : isNil(minimumFractionDigits) ? 2 : minimumFractionDigits;
      options.maximumFractionDigits = maximumFractionDigits ?? 6;
    }

    const formattedNumber = Intl.NumberFormat(locale, options).format(variant === 'percentage' ? num * 100 : num);
    return formattedNumber;
  }, [num, variant, locale, minimumFractionDigits, maximumFractionDigits, currency]);

  if (children === null || children === undefined) {
    return null;
  }

  if (formatted === '0' && !renderZero) {
    return null;
  }

  if (variant === 'percentage') {
    return `${formatted} %`;
  }

  return formatted;
};
