import { Type } from '@angular/core';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { LoadingDirective, LoadingComponent } from './loading';

const exported: Type<unknown>[] = [LoadingDirective];

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, MatProgressBarModule],
  declarations: [LoadingComponent, ...exported],
  exports: exported,
})
export class LoadingModule {}
