import { useCompanyId } from '@startuptools/common/react-wrapper';
import {
  DocumentSignaturesDocument,
  GqlDocumentSignaturesQuery,
  useCreateDocumentSignatureMutation,
  useDeleteDocumentSignatureMutation,
  useDocSignRefreshMutation,
  useDocumentSignaturesSuspenseQuery,
} from './DocumentSignatures.graphql';
import { DocumentSignaturesTable } from './DocumentSignaturesTable';
import {
  DocumentDocument,
  GqlSignAllowedAction,
  useDocSendSignReminderMutation,
  useDsSetAuthMethodToSignMutation,
  useDsSetEmailAndMobileMutation,
  useDsSetProxyMutation,
} from '../../graphql/react-operations';
import { ComponentProps, Suspense, useMemo, useState } from 'react';
import { AddIdentity } from '../add-identity-dialog/AddIdentity';
import { Trans } from '@lingui/react';
import { JuridicalKind, isSwedish } from '../../models/identity.model';
import { InputState } from '../add-identity-dialog/types';
import { identityToInput } from '../../helpers/identity-to-input';
import { isEmpty, isNil } from 'lodash-es';
import {
  GqlScriveDocumentStatus,
  useDocSignStartMutation,
  useDocSignStopMutation,
} from '../../graphql/react-operations';
import { ArrayElement } from '@startuptools/common/common';
import {
  FingerprintIcon,
  MailIcon,
  RefreshIcon,
  StopIcon,
  Alert,
  Button,
  Stack,
  Typography,
  Tooltip,
  Box,
  Snackbar,
} from '@startuptools/ui';
import { GqlZignedAgreementStatus } from '../../graphql/base-types.graphql';
import { captureException } from '@sentry/browser';
import React from 'react';

export const DocumentSignaturesInner = ({
  documentId,
  scriveStatus,
  zignedAgreementStatus,
  signAllowedAction,
  refetchDocument,
}: {
  documentId: string;
  scriveStatus: GqlScriveDocumentStatus | null | undefined;
  zignedAgreementStatus: GqlZignedAgreementStatus | null | undefined;
  signAllowedAction: GqlSignAllowedAction;
  refetchDocument: () => Promise<unknown>;
}) => {
  const companyId = useCompanyId();
  const [loadingState, setLoadingState] = useState(false);
  const [showReminderSentSnackbar, setShowReminderSentSnackbar] = useState(false);
  const [showReminderFailedSnackbar, setShowReminderFailedSnackbar] = useState(false);
  const {
    data: { documentSignatures },
  } = useDocumentSignaturesSuspenseQuery({ variables: { companyId, documentId } });

  const [setProxy] = useDsSetProxyMutation();
  const [setSignatoryMethod] = useDsSetAuthMethodToSignMutation();
  const [startProcess, { loading: startProcessLoading, error: startProcessError, reset }] = useDocSignStartMutation({
    refetchQueries: [DocumentDocument],
  });
  const [stopProcess, { loading: stopProcessLoading }] = useDocSignStopMutation({ refetchQueries: [DocumentDocument] });
  const [sendReminder, { loading: sendReminderLoading }] = useDocSendSignReminderMutation();
  const [doCreateSignatory, { loading: createSigLoading }] = useCreateDocumentSignatureMutation({
    refetchQueries: [DocumentSignaturesDocument],
  });
  const [doDeleteSignatory, { loading: deleteSigLoading }] = useDeleteDocumentSignatureMutation({
    refetchQueries: [DocumentSignaturesDocument],
  });
  const [doRefresh, { loading: refreshLoading }] = useDocSignRefreshMutation({
    variables: { companyId, id: documentId },
  });
  const [setEmail] = useDsSetEmailAndMobileMutation();

  const everyoneIsPrepared = useMemo(() => {
    return documentSignatures.every(ds => !isEmpty(ds.email) && !isNil(ds.authMethodToSign));
  }, [documentSignatures]);

  const everyoneHasRepresentative = useMemo(() => {
    return documentSignatures.every(ds => ds.signatory.juridicalKind === JuridicalKind.Person || !isNil(ds.proxyId));
  }, [documentSignatures]);

  const [selectProxy, setSelectProxy] = useState<ArrayElement<GqlDocumentSignaturesQuery['documentSignatures']> | null>(
    null,
  );
  const [showAddSignatory, setShowAddSignatory] = useState<boolean>(false);

  React.useEffect(() => {
    if (startProcessError) {
      const id = setTimeout(() => {
        reset();
      }, 3000);
      return () => clearTimeout(id);
    }
  }, [reset, startProcessError]);

  const canAddSignatory = useMemo(() => {
    return [GqlSignAllowedAction.Start, GqlSignAllowedAction.Restart].includes(signAllowedAction);
  }, [signAllowedAction]);

  const handleStartProcess = async () => {
    setLoadingState(true);
    try {
      await startProcess({ variables: { companyId, input: { id: documentId } } });
    } finally {
      setLoadingState(false);
    }
  };

  const handleStopProcess = async () => {
    try {
      await stopProcess({ variables: { companyId, input: { id: documentId } } });
    } finally {
      setLoadingState(false);
    }
  };

  const handleSendReminder = async () => {
    try {
      await sendReminder({ variables: { companyId, input: { id: documentId } } });
      setShowReminderSentSnackbar(true);
    } catch (e) {
      captureException(e);
      setShowReminderFailedSnackbar(true);
    }
  };

  const handleRefresh = async () => {
    // Refresh Scrive/Zigned;
    await doRefresh();
    // Refetch the actual document
    await refetchDocument();
  };

  const loading =
    loadingState ||
    startProcessLoading ||
    stopProcessLoading ||
    sendReminderLoading ||
    createSigLoading ||
    deleteSigLoading ||
    refreshLoading;

  const fullySigned =
    scriveStatus === GqlScriveDocumentStatus.Closed || zignedAgreementStatus === GqlZignedAgreementStatus.Fulfilled;

  const reasonForDisabled = !everyoneHasRepresentative ? (
    <Trans id="Make sure that every company has a representative" />
  ) : !everyoneIsPrepared ? (
    <Trans id="Make sure that everyone has an email set" />
  ) : null;

  return (
    <>
      <Stack gap={2} paddingX={1} maxWidth="100%">
        <Stack direction="row" justifyContent="space-between" alignItems="center" justifyItems="center">
          <Typography level="h4">
            <Trans id="Signatures" />
          </Typography>
          {reasonForDisabled && <Alert>{reasonForDisabled}</Alert>}

          <Stack direction="row" gap={2}>
            {canAddSignatory && (
              <Button variant="outlined" color="neutral" onClick={() => setShowAddSignatory(true)} disabled={loading}>
                <Trans id="Add signatory" />
              </Button>
            )}
            {fullySigned && (
              <Alert color="success" variant="soft">
                <Trans id="Document is fully signed!" />
              </Alert>
            )}
            {signAllowedAction === GqlSignAllowedAction.Start && (
              <Tooltip title={reasonForDisabled}>
                <Box>
                  <Button
                    sx={{
                      transition: 'background-color 100ms linear',
                    }}
                    variant="solid"
                    color={startProcessError ? 'danger' : 'primary'}
                    startDecorator={<FingerprintIcon />}
                    disabled={loading || Boolean(reasonForDisabled)}
                    loading={startProcessLoading}
                    onClick={handleStartProcess}
                  >
                    <Trans id="Start signature process" />
                  </Button>
                </Box>
              </Tooltip>
            )}
            {signAllowedAction === GqlSignAllowedAction.Restart && (
              <Button
                variant="solid"
                color="primary"
                startDecorator={<FingerprintIcon />}
                disabled={loading}
                loading={startProcessLoading}
                onClick={handleStartProcess}
              >
                <Trans id="Restart signature process" />
              </Button>
            )}
            {!fullySigned &&
              (signAllowedAction === GqlSignAllowedAction.Stop || signAllowedAction === GqlSignAllowedAction.None) && (
                <Button
                  variant="plain"
                  startDecorator={<RefreshIcon />}
                  onClick={handleRefresh}
                  color="neutral"
                  disabled={loading}
                  loading={refreshLoading}
                >
                  <Trans id="Refresh status" />
                </Button>
              )}
            {signAllowedAction === GqlSignAllowedAction.Stop && (
              <>
                <Button
                  variant="solid"
                  color="danger"
                  startDecorator={<StopIcon />}
                  disabled={loading}
                  loading={stopProcessLoading}
                  onClick={handleStopProcess}
                >
                  <Trans id="Stop signature process" />
                </Button>
                <Button
                  variant="outlined"
                  color="neutral"
                  startDecorator={<MailIcon />}
                  disabled={loading}
                  loading={sendReminderLoading}
                  onClick={handleSendReminder}
                >
                  <Trans id="Send reminder" />
                </Button>
              </>
            )}
          </Stack>
        </Stack>
        <DocumentSignaturesTable
          signAllowedAction={signAllowedAction}
          scriveStatus={scriveStatus}
          zignedAgreementStatus={zignedAgreementStatus}
          documentLoading={loading}
          documentSignatures={documentSignatures}
          setProxy={ds => setSelectProxy(ds)}
          setSignatoryMethod={(id, method) =>
            setSignatoryMethod({ variables: { companyId, input: { id, authMethodToSign: method } } })
          }
          setEmail={(id, email) => setEmail({ variables: { companyId, input: { id, email } } })}
          onDelete={id => doDeleteSignatory({ variables: { companyId, id } })}
        />
      </Stack>

      {selectProxy && (
        <AddIdentity
          headline={<Trans id="Select signatory representative" />}
          juridicalKinds={[JuridicalKind.Person]}
          availableInputStates={{
            [InputState.System]: false,
            [InputState.CompanyBoard]:
              selectProxy.signatory.juridicalKind === JuridicalKind.Company && isSwedish(selectProxy.signatory),
            [InputState.Swedish]: true,
            [InputState.Foreign]: true,
          }}
          companyBoardOrgNumber={selectProxy.signatory.identityNumber}
          defaultInputState={InputState.Swedish}
          identityExcludeList={selectProxy.proxy ? [selectProxy.proxy] : []}
          detailFieldsSettings={{}}
          handleCreate={async identity => {
            return setProxy({
              variables: { companyId, input: { id: selectProxy.id, proxyInput: identityToInput(identity) } },
            }).then(() => setSelectProxy(null));
          }}
          onClose={() => {
            setSelectProxy(null);
          }}
        />
      )}

      {showAddSignatory && (
        <AddIdentity
          headline={<Trans id="Select a person or company" />}
          juridicalKinds={[JuridicalKind.Person, JuridicalKind.Company]}
          availableInputStates={{
            [InputState.System]: true,
            [InputState.CompanyBoard]: false,
            [InputState.Swedish]: true,
            [InputState.Foreign]: true,
          }}
          defaultInputState={InputState.System}
          detailFieldsSettings={{}}
          identityExcludeList={documentSignatures
            .filter(ds => ds.signatory.juridicalKind === JuridicalKind.Person)
            .map(ds => ds.signatory)}
          handleCreate={async identity => {
            return doCreateSignatory({
              variables: {
                companyId,
                input: { documentId, signatoryInput: identityToInput(identity) },
              },
            }).then(() => setShowAddSignatory(false));
          }}
          onClose={() => {
            setShowAddSignatory(false);
          }}
        />
      )}
      <Snackbar
        open={showReminderSentSnackbar}
        color="success"
        variant="soft"
        onClose={() => setShowReminderSentSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Trans id="A reminder to sign was sent to all pending signees" />
      </Snackbar>
      <Snackbar
        open={showReminderFailedSnackbar}
        color="danger"
        variant="soft"
        onClose={() => setShowReminderFailedSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Trans id="Failed to send out reminder, try again! Contact support if problem persist" />
      </Snackbar>
    </>
  );
};

export const DocumentSignatures = (props: ComponentProps<typeof DocumentSignaturesInner>) => {
  if (!props.documentId) {
    return;
  }
  return (
    <Suspense>
      <DocumentSignaturesInner {...props} />
    </Suspense>
  );
};
