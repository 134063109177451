import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidatorFn, FormControl, FormGroup } from '@angular/forms';
import { Observable, first, finalize } from 'rxjs';
import { find, isNil } from 'lodash-es';
import { SubSink } from 'subsink';
import { DateTime } from 'luxon';
import { LoadingDirective } from '@startuptools/angular/loading';

enum AutoCompleteState {
  On = 'on',
  Off = 'off',
}

export enum PromptInputKind {
  Input,
  InputNumerical,
  TextArea,
  Date,
  RichText,
}

export interface PromptDateRange {
  min?: DateTime;
  max?: DateTime;
}

export interface Data<T, R> {
  // autoFocus?: boolean;
  cancel: string;
  // minWidth?: number;
  modelValue: T;
  modelValueSl?: T;
  showToggle?: boolean;
  toggleState?: boolean;
  title: string;
  text?: string;
  sublabel?: string;
  inputLabel?: string;
  inputLabelSl?: string;
  placeholder?: string;
  placeholderSl?: string;
  primaryLang?: boolean;
  secondaryLang?: boolean;
  ok: string;
  confirmBtnClass?: string;
  type: string;
  validators: ValidatorFn[];
  integerOnly?: boolean;
  inputKind?: PromptInputKind;
  errors?: Record<string, string>;
  dateRange?: PromptDateRange;
  suffix?: string;
  save: (result: { input?: T; inputSl?: T; toggleState?: boolean }) => Observable<R>;
}

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
})
export class PromptDialogComponent<T, R> implements OnDestroy {
  PromptInputKind = PromptInputKind;
  @ViewChild('qLoading') qLoading: LoadingDirective;

  subs = new SubSink();
  formControls = {
    // @ts-expect-error TS2345
    input: new FormControl<T>(null),
    // @ts-expect-error TS2345
    inputSl: new FormControl<T>(null),
    toggle: new FormControl<boolean>(false),
  };
  formGroup = new FormGroup(this.formControls);
  autoCompleteState = this.data.type === 'number' ? AutoCompleteState.Off : AutoCompleteState.On;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data<T, R>,
    public dialogRef: MatDialogRef<PromptDialogComponent<T, R>>,
  ) {
    if (!data.primaryLang && !data.secondaryLang) {
      // @ts-expect-error TS2367
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      data.primaryLang === true;
    }
    this.formControls.input.setValue(data.modelValue);
    // @ts-expect-error TS2345
    this.formControls.inputSl.setValue(data.modelValueSl);
    this.formControls.toggle.setValue(data.toggleState ?? false);
    this.formControls.input.setValidators(data.primaryLang ? data.validators : []);
    this.formControls.inputSl.setValidators(data.secondaryLang ? data.validators : []);
  }

  static open<T, R>(dialog: MatDialog, data: Data<T, R>, config?: MatDialogConfig<Data<T, R>>) {
    return dialog.open<PromptDialogComponent<T, R>, Data<T, R>>(PromptDialogComponent, {
      minWidth: 700,
      ...(!isNil(config) ? config : {}),
      disableClose: true,
      data: {
        inputKind: PromptInputKind.Input,
        primaryLang: true,
        confirmBtnClass: 'btn',
        ...data,
      },
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  submit() {
    if (this.formGroup.invalid) {
      return;
    }
    if (!isNil(this.data.save)) {
      this.qLoading.start();
      this.data
        .save({
          input: this.formControls.input.value,
          inputSl: this.formControls.inputSl.value,
          // @ts-expect-error TS2322
          toggleState: this.formControls.toggle.value,
        })
        .pipe(
          first(),
          finalize(() => this.qLoading.stop()),
        )
        .subscribe(res => this.dialogRef.close(res));
      return;
    }
    this.dialogRef.close();
  }

  getErrorMsg() {
    return find(this.data.errors, (_err, key) => {
      return this.formControls.input.hasError(key);
    });
  }
}
