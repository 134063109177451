import { Stack, Typography, TypographyProps } from '@mui/joy';

export const LabeledText = ({
  label,
  children,
  textColor,
}: {
  label: React.ReactNode;
  children: React.ReactNode;
  textColor?: TypographyProps['textColor'];
}) => {
  return (
    <Stack>
      <Typography fontSize="10px" textColor={textColor}>
        {label}
      </Typography>
      <Typography textColor={textColor}>{children}</Typography>
    </Stack>
  );
};
