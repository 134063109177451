import { DateTime } from 'luxon';
import { groupBy, isNil, keys, orderBy, reduce, toNumber } from 'lodash';
import { BigNumber } from 'bignumber.js';

import { rangeCount, ShareClass, TransactionKind } from '../transactions';
import { DATE_FORMAT_LUXON, toDateTime } from '../common';
import { sortSnrSccs } from './sort-snr-sccs';

export interface SnrScc {
  id: string;
  decisionDate?: DateTime | Date | string | null;
  registrationDate: DateTime | Date | string | null;
  name: string;
  currency?: string | null | undefined;
  sharesChange?: string | null;
  sharesTotal?: string | null;
  shareValue?: string | null;
  sharesChangeSplitAdjusted?: string | null;
  sharesTotalSplitAdjusted?: string | null;
  companyValue?: string | null;
  companyValuePreMoney?: string | null;
  transactionValue?: string | null;
  foundersShareFraction?: string | null;
  amountTotalBeforeChange: string;
  amountChange: string;
  amountTotal: string;
  sharePriceFactor?: string | null;
  splitSharesChange: string;
  splitFactor: string;
  sortOrder?: number | null | undefined;
  filingNumber: string;
  filingYear: string;
  serialNumber: string;
}

export interface StPlus {
  id: string;
  date: DateTime;
  kind: TransactionKind;
  sortOrder: number;
  blockStart: string | number;
  blockEnd: string | number;
  snrShareCapitalChangeId?: string;
  sellerId?: string;
  buyerId?: string;
  sellerIdentityId?: string;
  buyerIdentityId?: string;
  pricePerShare?: string | null;
  shareClassFrom?: ShareClass;
  shareClassTo?: ShareClass;
  splitFactor?: BigNumber;
  shareTransactionGroupId: string;
  createdAt: DateTime | Date;
  shareCertificateDate?: DateTime | Date;
}

export interface CompleteScc {
  id: string;
  sccId: string;
  kind: TransactionKind;
  decisionDate?: DateTime | undefined | null;
  registrationDate: DateTime | null;
  name: string;
  changedNumberOfShares?: number | undefined | null;
  numberOfShares?: number | undefined | null;
  pricePerShare?: number | undefined | null;
  currency: string | null | undefined;
  changedNumberOfSharesSplitAdjusted: number | undefined | null;
  numberOfSharesSplitAdjusted: number | undefined | null;
  pricePerShareSplitAdjusted?: number | null;
  preMoney: number | undefined | null;
  paidAmount: number | undefined | null;
  postMoney: number | undefined | null;
  foundersShareFraction: number | undefined | null;
  shareCapitalBefore: number | null;
  shareCapitalChanged: number | null;
  shareCapitalAfter: number | null;
  sharePriceFactor?: number | null | undefined;
  splitFactor?: number | undefined | null;
  sortOrder: number | null | undefined;
  caseNumber: string | null;
  filingYear: string;
  filingNumber: string;
}

function findRelevantSplitTransactions(shareTransactions: StPlus[], scc: SnrScc) {
  return groupBy(
    shareTransactions.filter(
      st =>
        (st.kind === TransactionKind.Split || st.kind === TransactionKind.Merge) &&
        st.snrShareCapitalChangeId === scc.id,
    ),
    s => s.date.toFormat(DATE_FORMAT_LUXON),
  );
}

const parseMaybeInt = (val: string | null | undefined) => {
  return val ? parseInt(val, 10) : undefined;
};

const parseMaybeFloat = (val: string | null | undefined) => {
  return val ? parseFloat(val) : undefined;
};

export function completeSccs(sccs: SnrScc[], sts: StPlus[], uniqueIds = false): CompleteScc[] {
  const orderedSts = orderBy(sts, [st => st.date, st => st.sortOrder, st => st.blockStart], ['desc', 'desc', 'desc']);
  const orderedSccs = sortSnrSccs(sccs, 'asc');
  const retCompleteSccs: CompleteScc[] = [];

  for (const scc of orderedSccs) {
    let uniqueIdSuffix = 1;
    const kind =
      scc.name === 'VALUTA OMVL'
        ? TransactionKind.CurrencyChange
        : toNumber(scc.sharesChange || '0') >= 0
          ? TransactionKind.Issue
          : TransactionKind.Unissue;
    retCompleteSccs.push({
      id: scc.id,
      sccId: scc.id,
      kind,
      decisionDate: isNil(scc.decisionDate)
        ? null
        : typeof scc.decisionDate === 'string'
          ? DateTime.fromISO(scc.decisionDate)
          : toDateTime(scc.decisionDate),
      registrationDate: isNil(scc.registrationDate)
        ? null
        : typeof scc.registrationDate === 'string'
          ? DateTime.fromISO(scc.registrationDate)
          : toDateTime(scc.registrationDate),
      name: scc.name,
      changedNumberOfShares: !isNil(scc.sharePriceFactor) ? parseMaybeInt(scc.sharesChange) : undefined,
      numberOfShares: parseMaybeInt(scc.sharesTotal),
      pricePerShare: parseMaybeFloat(scc.shareValue),
      currency: scc.currency,
      changedNumberOfSharesSplitAdjusted: parseMaybeInt(scc.sharesChangeSplitAdjusted),

      numberOfSharesSplitAdjusted: parseMaybeInt(scc.sharesTotalSplitAdjusted),

      pricePerShareSplitAdjusted:
        scc.shareValue && scc.companyValue && scc.sharesTotalSplitAdjusted
          ? parseFloat(scc.companyValue) / parseFloat(scc.sharesTotalSplitAdjusted)
          : null,
      preMoney: parseMaybeFloat(scc.companyValuePreMoney),
      paidAmount: parseMaybeFloat(scc.transactionValue),
      postMoney: parseMaybeFloat(scc.companyValue),
      foundersShareFraction: parseMaybeFloat(scc.foundersShareFraction),
      shareCapitalBefore: parseFloat(scc.amountTotalBeforeChange),
      shareCapitalChanged: parseFloat(scc.amountChange),
      shareCapitalAfter: parseFloat(scc.amountTotal),
      sharePriceFactor: parseMaybeFloat(scc.sharePriceFactor),
      caseNumber: `${parseInt(scc.filingNumber, 10)}/${parseInt(scc.filingYear, 10)}`,
      filingYear: scc.filingYear,
      filingNumber: scc.filingNumber,
      sortOrder: scc.sortOrder,
      splitFactor: undefined,
    });

    const splitSharesChange = parseFloat(scc.splitSharesChange);
    if (!isNaN(splitSharesChange) && splitSharesChange !== 0) {
      const splitGroups = findRelevantSplitTransactions(orderedSts, scc);
      uniqueIdSuffix = (Object.keys(splitGroups)?.length ?? 0) + 1;
      const changedRegistered = reduce(
        splitGroups,
        (sum, splits) => {
          return (
            sum +
            reduce(
              splits,
              (sum2, s) => {
                return sum2 + rangeCount(toNumber(s.blockStart), toNumber(s.blockEnd));
              },
              0,
            )
          );
        },
        0,
      );
      if (splitSharesChange === changedRegistered) {
        let localTotalNumberOfShares = parseMaybeInt(scc.sharesTotal) ?? 0;
        const dates = orderBy(keys(splitGroups), date => date, ['asc']);
        const splitSccs: CompleteScc[] = [];
        dates.forEach(date => {
          const numberOfShares = reduce(
            splitGroups[date],
            (sum, split) => {
              return sum + rangeCount(toNumber(split.blockStart), toNumber(split.blockEnd));
            },
            0,
          );
          const localSplitFactor = (localTotalNumberOfShares + numberOfShares) / localTotalNumberOfShares;
          localTotalNumberOfShares = localTotalNumberOfShares + numberOfShares;
          const id = uniqueIds ? `${scc.id}-split-${uniqueIdSuffix++}` : scc.id;
          splitSccs.push({
            id,
            sccId: scc.id,
            decisionDate: null,
            kind: TransactionKind.Split,
            registrationDate: DateTime.fromFormat(date, DATE_FORMAT_LUXON),
            name: `SPLIT (${localSplitFactor}:1)`,
            splitFactor: localSplitFactor,
            changedNumberOfShares: numberOfShares,
            changedNumberOfSharesSplitAdjusted: null,
            pricePerShare: null,
            currency: null,
            numberOfShares: localTotalNumberOfShares,
            numberOfSharesSplitAdjusted: null,
            pricePerShareSplitAdjusted: null,
            preMoney: null,
            paidAmount: null,
            postMoney: null,
            foundersShareFraction: parseMaybeFloat(scc.foundersShareFraction),
            shareCapitalBefore: null,
            shareCapitalChanged: null,
            shareCapitalAfter: null,
            sharePriceFactor: new BigNumber(scc.amountTotal).dividedBy(localTotalNumberOfShares).toNumber(),
            sortOrder: scc.sortOrder,
            caseNumber: null,
            filingYear: scc.filingYear,
            filingNumber: scc.filingNumber,
          });
        });
        // splitSccs.reverse();
        retCompleteSccs.push(...splitSccs);
      } else if (Math.abs(splitSharesChange) === changedRegistered) {
        // MERGE
        let localTotalNumberOfShares = parseMaybeInt(scc.sharesTotal) ?? 0;
        const dates = orderBy(keys(splitGroups), date => date, ['asc']);
        const splitSccs: CompleteScc[] = [];
        dates.forEach(date => {
          const numberOfShares = reduce(
            splitGroups[date],
            (sum, split) => {
              return sum + rangeCount(toNumber(split.blockStart), toNumber(split.blockEnd));
            },
            0,
          );
          const localSplitFactor = localTotalNumberOfShares / (localTotalNumberOfShares - numberOfShares);
          localTotalNumberOfShares = localTotalNumberOfShares - numberOfShares;
          const id = uniqueIds ? `${scc.id}-split-${uniqueIdSuffix++}` : scc.id;
          splitSccs.push({
            id,
            sccId: scc.id,
            kind: TransactionKind.Merge,
            decisionDate: null,
            registrationDate: DateTime.fromFormat(date, DATE_FORMAT_LUXON),
            name: `SPLIT (1:${localSplitFactor})`,
            splitFactor: localSplitFactor,
            changedNumberOfShares: -numberOfShares,
            changedNumberOfSharesSplitAdjusted: null,
            pricePerShare: null,
            currency: null,
            numberOfShares: localTotalNumberOfShares,
            numberOfSharesSplitAdjusted: null,
            pricePerShareSplitAdjusted: null,
            preMoney: null,
            paidAmount: null,
            postMoney: null,
            foundersShareFraction: parseMaybeFloat(scc.foundersShareFraction),
            shareCapitalBefore: null,
            shareCapitalChanged: null,
            shareCapitalAfter: null,
            sharePriceFactor: parseFloat(scc.amountTotal) / localTotalNumberOfShares,
            sortOrder: scc.sortOrder,
            caseNumber: null,
            filingYear: scc.filingYear,
            filingNumber: scc.filingNumber,
          });
        });
        // splitSccs.reverse();
        retCompleteSccs.push(...splitSccs);
      } else {
        // A split has happened
        const splitFactor = parseFloat(scc.splitFactor);
        let name: string;
        if (splitFactor > 1) {
          name = `SPLIT (${new BigNumber(scc.splitFactor)}:1)`;
        } else {
          const fraction = new BigNumber(scc.splitFactor).toFraction();
          name = `SAMMANSLAGNING (${fraction[0].toFixed()}:${fraction[1].toFixed()})`;
        }
        const id = uniqueIds ? `${scc.id}-split-${uniqueIdSuffix++}` : scc.id;
        retCompleteSccs.push({
          id,
          sccId: scc.id,
          kind: splitSharesChange > 0 ? TransactionKind.Split : TransactionKind.Merge,
          decisionDate: null,
          registrationDate: null,
          name,
          splitFactor,
          changedNumberOfShares: splitSharesChange,
          changedNumberOfSharesSplitAdjusted: null,
          pricePerShare: null,
          currency: null,
          numberOfShares: null,
          numberOfSharesSplitAdjusted: null,
          pricePerShareSplitAdjusted: null,
          preMoney: null,
          paidAmount: null,
          postMoney: null,
          foundersShareFraction: parseMaybeFloat(scc.foundersShareFraction),
          shareCapitalBefore: null,
          shareCapitalChanged: null,
          shareCapitalAfter: null,
          sharePriceFactor: parseFloat(scc.amountTotal) / (splitFactor * (parseMaybeInt(scc.sharesTotal) ?? 0)),
          sortOrder: scc.sortOrder,
          caseNumber: null,
          filingYear: scc.filingYear,
          filingNumber: scc.filingNumber,
        });
      }
    }
  }
  return retCompleteSccs;
}
