import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from '@startuptools/angular/auth';

import { host } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

const protectedHosts = [host.clientUrl, host.clientWsUrl];

const urlWitelist = [
  '/api/resource/startupdocs/email',
  '/api/resource/optional-agenda-item',
  '/auth-service/graphql',
  '/assets',
];

/** Pass untouched request through to the next request handler. */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  unauthedDialogOpened = false;

  constructor(
    private router: Router,
    private jwtService: JwtService,
    private snackBar: MatSnackBar,
  ) {}

  // @ts-expect-error TS2366
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const reqHost = new URL(req.url);
    if (!protectedHosts.some(url => url === reqHost.origin)) {
      return next.handle(req);
    }
    if (urlWitelist.some(wl => reqHost.pathname.includes(wl))) {
      return next.handle(req);
    }
    if (this.jwtService.isValid()) {
      return next.handle(
        req.clone({
          headers: req.headers.set('Authorization', `Bearer ${this.jwtService.jwt}`),
        }),
      );
    }
    void this.router.navigate(['/auth']);
    // @ts-expect-error TS2345
    this.snackBar.open($localize`Du har blivit utloggad på grund av inaktivitet`, null, { duration: 10000 });
  }
}
