import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { ShareTransactionGroupsState } from '../reducers/share-transaction-groups.reducers';
import { selectCompanyId } from './companies.base';
import { StgWithIncludes } from '../../models/share-transaction-group.model';
import { selectSnrShareCapitalChangesState } from '../../store/selectors/snr-share-capital-changes.selectors';
import { selectShareIssuesState } from '../../store/selectors/share-issues.base';
import { selectWarrantProgramsState } from '../../store/selectors/warrant-programs.base';

export const selectShareTransactionGroupsState =
  createFeatureSelector<ShareTransactionGroupsState>('shareTransactionGroups');
export const selectShareTransactionGroups = createSelector(
  selectShareTransactionGroupsState,
  selectCompanyId,
  (state, companyId) => {
    return Object.values(state.entities).filter(e => e?.companyId === companyId);
  },
);

export const selectStgsWithIncludes = createSelector(
  selectShareTransactionGroups,
  selectSnrShareCapitalChangesState,
  selectShareIssuesState,
  selectWarrantProgramsState,
  (stGroups, snrSccState, siState, wpState) => {
    return stGroups.map(stg => {
      if (isNil(stg)) {
        throw new Error('stg missing');
      }
      const snrScc = stg.snrShareCapitalChangeId ? snrSccState.entities[stg.snrShareCapitalChangeId] : undefined;
      const shareIssue = stg.shareIssueId ? siState.entities[stg.shareIssueId] : undefined;
      const warrantProgram = stg.warrantProgramId ? wpState.entities[stg.warrantProgramId] : undefined;
      return {
        model: stg,
        snrScc,
        shareIssue,
        warrantProgram,
      } satisfies StgWithIncludes;
    });
  },
);
