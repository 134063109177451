import { Trans, useLingui } from '@lingui/react';
import {
  DateFormat,
  NumberFormat,
  Alert,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  InsertDriveFileIcon,
  ReportProblemIcon,
  Box,
  Chip,
  Modal,
  ModalDialog,
  ModalClose,
} from '@startuptools/ui';
import { DocumentDialog } from './DocumentDialog';
import React from 'react';
import { SigningStatus } from './ScriveStatus';
import { Action, Actions } from './Actions';
import {
  GqlDocGenState,
  GqlDocSystemTagKind,
  GqlDocument,
  GqlDocumentSignatureMethod,
  GqlScriveDocumentStatus,
  GqlSeverity,
  GqlSignAllowedAction,
  GqlValidatorStatus,
} from '../../graphql/base-types.graphql';
import { PDFPreview } from './PDFPreview';
import { DocumentCreateDialog, UploadDocForm } from './DocumentCreateDialog';
import { ValidatorErrorDialog } from '../ValidatorErrorDialog';

export type DisplayDocument = Pick<
  GqlDocument,
  | 'id'
  | 'genState'
  | 'createdAt'
  | 'filesize'
  | 'dirty'
  | 'scriveStatus'
  | 'zignedAgreementStatus'
  | 'signatureMethod'
  | 'filename'
  | 'uploaded'
  | 'signAllowedAction'
  | 'kind'
  | 'deletable'
>;

export const Document = ({
  defaultFileName,
  document,
  generate,
  allowDigitalSignature = true,
  preview,
  validate = () => Promise.resolve({ isValid: true, errors: undefined }),
  mode,
  upload,
  onDelete,
  signaturesSlot,
  tagsSlot,
}: {
  defaultFileName?: string;
  document: DisplayDocument | undefined | null;
  generate?: (signatureMethod: GqlDocumentSignatureMethod) => Promise<unknown>;
  allowDigitalSignature?: boolean;
  preview?: () => Promise<Blob>;
  upload?: (data: UploadDocForm) => Promise<unknown>;
  onDelete?: () => Promise<unknown>;
  /* if you return errors in this function they will be shown inside the Document component, negating the need
  to implement/call the error Dialog yourself. Errors with the level warning will allow the document to be previewed, but
  block them to be generated. Errors with the level error will block both preview and generation.  */
  validate?: () => Promise<{ isValid: boolean; errors: GqlValidatorStatus[] | undefined }>;
  mode: 'edit' | 'read' | 'upload';
  signaturesSlot?: React.ReactNode;
  tagsSlot?: React.ReactNode;
}) => {
  const [validatorDialog, setValidatorDialog] = React.useState<GqlValidatorStatus[] | undefined>(undefined);
  const [showPdfPreview, setShowPdfPreview] = React.useState(false);
  const [showDocumentDialog, setShowDocumentDialog] = React.useState(false);

  const showDocument = () => setShowDocumentDialog(true);

  const [showCreateDocumentDialog, setShowCreateDocumentDialog] = React.useState<false | 'upload' | 'generate'>(false);

  const [localDisabled, setLocalDisabled] = React.useState(false);
  const [localError, setLocalError] = React.useState(false);
  const isGenerating = Boolean(
    localDisabled ||
      (document && document.genState !== GqlDocGenState.Completed && document.genState !== GqlDocGenState.Failed),
  );
  const { i18n } = useLingui();

  const generateWrapped = generate
    ? async (signatureMethod: GqlDocumentSignatureMethod) => {
        setLocalError(false);
        setLocalDisabled(true);
        setShowCreateDocumentDialog(false);
        const { isValid } = await validate();
        if (isValid) {
          try {
            await generate(signatureMethod);
          } catch (error) {
            setLocalError(true);
          }
        }
        setLocalDisabled(false);
      }
    : undefined;

  const isCustomUpload =
    document?.uploaded &&
    ![GqlDocSystemTagKind.MeetingSummonsCoverSheet, GqlDocSystemTagKind.MeetingAppendix].includes(document.kind);

  const showSignatureStatus =
    document?.signatureMethod === GqlDocumentSignatureMethod.Digital &&
    (document.scriveStatus || document.zignedAgreementStatus);

  return (
    <Stack direction="row" gap={2} justifyContent="space-between" width="100%">
      <Stack justifyContent="center">
        <Stack direction="row" spacing={1}>
          {document?.dirty || document?.genState === GqlDocGenState.Failed || localError ? (
            <ReportProblemIcon color="warning" />
          ) : (
            <InsertDriveFileIcon color="primary" />
          )}

          <Typography>{document?.filename ?? defaultFileName}</Typography>
        </Stack>
        <Stack direction="row">
          {document?.genState === GqlDocGenState.Failed || localError ? (
            <Alert color="danger">
              <Trans id="Failed to generate document, contact support" />
            </Alert>
          ) : isGenerating ? (
            <Typography fontSize={13}>
              <Trans id="Generating..." />
            </Typography>
          ) : (
            document && (
              <Stack alignItems="center" direction="row">
                <Typography fontSize={13}>
                  <Trans id="Created" comment="when a document was created" />{' '}
                  <DateFormat locale={i18n.locale} relative readable>
                    {document.createdAt}
                  </DateFormat>
                </Typography>
                {document.filesize && (
                  <>
                    <Divider orientation="vertical" sx={{ mx: 1 }} />
                    <Typography fontSize={13}>
                      <NumberFormat locale={i18n.locale} maximumFractionDigits={0}>
                        {document.filesize / 1000}
                      </NumberFormat>
                      kb
                    </Typography>
                  </>
                )}
                {isCustomUpload && (
                  <Box>
                    <Divider orientation="vertical" sx={{ mx: 1 }} />
                    <Chip variant="soft" color="primary">
                      <Trans id="Custom document" />
                    </Chip>
                  </Box>
                )}
                {document.dirty && mode === 'edit' && (
                  <>
                    <Divider orientation="vertical" sx={{ mx: 1 }} />
                    <Typography color="danger" fontSize={13}>
                      <Trans id="Contains outdated information and should be re-generated" />
                    </Typography>
                  </>
                )}
              </Stack>
            )
          )}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" justifySelf="end" gap={1}>
        {isGenerating && <CircularProgress size="sm" />}
        {showSignatureStatus && (
          <Box onClick={showDocument} sx={{ cursor: 'pointer' }}>
            <SigningStatus status={document.zignedAgreementStatus || document.scriveStatus!} />
          </Box>
        )}

        <Actions disabled={isGenerating}>
          <Action
            show={Boolean(preview) && mode === 'edit' && !document?.uploaded}
            onClick={async () => {
              const { isValid, errors } = await validate();
              if (isValid || (errors && !errors.some(e => e.hasError && e.severity === GqlSeverity.Error))) {
                setShowPdfPreview(true);
              } else {
                setValidatorDialog(errors);
              }
            }}
            visible={!document || document.dirty}
            variant="outlined"
          >
            <Trans id="Preview" />
          </Action>

          <Action
            show={Boolean(upload)}
            onClick={() => {
              setShowCreateDocumentDialog('upload');
            }}
            visible={mode === 'upload'}
          >
            {document?.uploaded ? <Trans id="Replace document" /> : <Trans id="Upload document" />}
          </Action>

          <Action
            show={Boolean(generateWrapped) && mode === 'edit'}
            visible={!document || document.dirty || document.genState !== GqlDocGenState.Completed}
            onClick={async () => {
              if (validate) {
                const { isValid, errors } = await validate();
                if (!isValid) {
                  if (errors && errors.some(e => e.hasError)) {
                    setValidatorDialog(errors);
                  }
                  return;
                }
              }
              if (allowDigitalSignature) {
                setShowCreateDocumentDialog('generate');
                return;
              }
              return generateWrapped!(GqlDocumentSignatureMethod.Analogue);
            }}
          >
            <Trans id="Create Document" />
          </Action>

          <Action visible={false} onClick={showDocument} show={Boolean(document?.dirty) && mode === 'edit'}>
            <Trans id="Signatures" />
          </Action>

          <Action
            onClick={showDocument}
            visible={!document?.dirty}
            show={document?.genState === GqlDocGenState.Completed && mode === 'edit'}
          >
            {document?.signatureMethod === GqlDocumentSignatureMethod.Digital &&
            [GqlSignAllowedAction.Start, GqlSignAllowedAction.Restart].includes(document.signAllowedAction) &&
            !document.dirty ? (
              <Trans id="Start signing process" />
            ) : (
              <Trans id="Show PDF" />
            )}
          </Action>

          <Action onClick={showDocument} visible show={mode === 'read' || (mode === 'upload' && Boolean(document))}>
            <Trans id="Show PDF" />
          </Action>

          {document && onDelete && (
            <Action show={document?.deletable ?? false} visible={false} onClick={onDelete}>
              <Trans id="Delete" />
            </Action>
          )}
        </Actions>
      </Stack>
      {showDocumentDialog && (
        <Modal open onClose={() => setShowDocumentDialog(false)}>
          <ModalDialog sx={{ maxWidth: '95%', maxHeight: '95%', minWidth: 1000, overflowY: 'auto', paddingX: 2 }}>
            <ModalClose />
            {document && (
              <DocumentDialog document={document} moreDetails={tagsSlot}>
                {signaturesSlot}
              </DocumentDialog>
            )}
          </ModalDialog>
        </Modal>
      )}
      {showPdfPreview && (
        <PDFPreview
          onClose={() => {
            setShowPdfPreview(false);
          }}
          mode="generate"
          generatePdf={async () => {
            if (!preview) throw new Error('preview is undefined');
            const blob = await preview();
            return { blob, filename: defaultFileName ?? 'document.pdf' };
          }}
        />
      )}
      {showCreateDocumentDialog && (
        <DocumentCreateDialog
          allowDigitalSignature={mode === 'edit'}
          mode={showCreateDocumentDialog}
          showExistingDocWarning={document?.genState === GqlDocGenState.Completed}
          showSigningWarning={
            document?.signatureMethod === GqlDocumentSignatureMethod.Digital &&
            document?.scriveStatus === GqlScriveDocumentStatus.Pending
          }
          upload={async data => {
            if (showCreateDocumentDialog === 'upload') {
              if (!upload) {
                throw new Error('You must provide the upload function');
              }
              await upload(data);
              setShowCreateDocumentDialog(false);
            } else {
              await generateWrapped!(
                data.signDigitally ? GqlDocumentSignatureMethod.Digital : GqlDocumentSignatureMethod.Analogue,
              );
              setShowCreateDocumentDialog(false);
            }
          }}
          onClose={() => {
            setShowCreateDocumentDialog(false);
          }}
        />
      )}
      {validatorDialog && (
        <ValidatorErrorDialog
          errors={validatorDialog}
          onClose={() => setValidatorDialog(undefined)}
          preview={() => {
            setValidatorDialog(undefined);
            return setShowPdfPreview(true);
          }}
        />
      )}
    </Stack>
  );
};
